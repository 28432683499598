// 404.js
import React from "react";
import { Result, Button } from "antd";

const NotFoundScreen = () => {
  const customIcon = <img src="/HLBubble.svg" alt="Not Found" />;
  return (
    <div className="flex justify-center items-center h-screen">
      <Result
        icon={customIcon}
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" href="/clinics">
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundScreen;
