import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrands } from "utilities/redux/slices/brandsSlice";

import {
  Form,
  Drawer,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Radio,
  DatePicker,
  Select,
  Typography,
  message,
  notification,
} from "antd";
import axiosInstance from "utilities/axios";
import HoursControl from "components/HoursControl";
import AddressControl from "components/AddressControl";
import { useNavigate } from "react-router-dom";

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography;

const AddClinic = ({ visible, setVisible }) => {
  const [regions, setRegions] = useState([]);
  const [clinics, setClinics] = useState([]); // [clinicId, clinicName
  const [satelliteClinic, setSatelliteClinic] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("Open"); // ["Open", "To Open", "Pending Integration", "Closed"
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBrands({}));

    axiosInstance
      .get("/clinics", {
        params: {
          primaryClinic: true,
          status: ["Open", "Closed (Temp)", "To Open", "Pending Integration"],
        },
      })
      .then((response) => {
        setClinics(response.data);
      });
    axiosInstance.get("/regions", {}).then((response) => {
      setRegions(response.data);
    });
  }, [dispatch]);

  const brands = useSelector((state) => state.brands.data);

  const submitForm = (values) => {
    setIsSubmitting(true); // Start loading
    axiosInstance
      .post("/clinics", values)
      .then((response) => {
        notification.success({
          message: "Clinic added successfully",
          description:
            "The clinic has been added successfully. You can view the clinic in the Clinics page.",
        });
        form.resetFields();
        setVisible(false);
        navigate(`/clinics/${values.clinicNo}`);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      })
      .finally(() => {
        setIsSubmitting(false); // Stop loading regardless of the result
      });
  };

  return (
    <Drawer
      title="Add Clinic"
      width={500}
      open={visible}
      onClose={() => {
        setVisible(false);
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        form={form}
        onFinish={submitForm}
        onFinishFailed={(error) => {
          message.error(
            "There is an error in the form. Please correct before submitting."
          );
        }}
        layout="horizontal"
        colon={false}
        initialValues={{
          clinicStatus: "Open",
          satelliteClinic: false,
          wheelchairStatus: true,
          hours: [
            {
              dayOfWeek: 1,
              isOpen: true,
              openTime: "09:00",
              closeTime: "17:00",
            },
            {
              dayOfWeek: 2,
              isOpen: true,
              openTime: "09:00",
              closeTime: "17:00",
            },
            {
              dayOfWeek: 3,
              isOpen: true,
              openTime: "09:00",
              closeTime: "17:00",
            },
            {
              dayOfWeek: 4,
              isOpen: true,
              openTime: "09:00",
              closeTime: "17:00",
            },
            {
              dayOfWeek: 5,
              isOpen: true,
              openTime: "09:00",
              closeTime: "17:00",
            },
            {
              dayOfWeek: 6,
              isOpen: false,
              openTime: "09:00",
              closeTime: "17:00",
            },
            {
              dayOfWeek: 7,
              isOpen: false,
              openTime: "09:00",
              closeTime: "17:00",
            },
          ],
          ownershipStatus: "Fully-Owned",
        }}
      >
        <Title className="mt-0" level={4}>
          Clinic Information
        </Title>
        <Item
          label="Clinic Name"
          name="clinicName"
          rules={[
            { required: true },
            {
              max: 100,
              message: "Clinic Name must be no more than 100 characters long",
            },
          ]}
        >
          <Input />
        </Item>

        <Item
          label="Clinic Number"
          name="clinicNo"
          rules={[
            { required: true, message: "Clinic Number is required" },
            {
              pattern: new RegExp(/^[0-9\-]+$/),
              message: "Clinic Number must only contain numbers and hyphens",
            },
            ({ getFieldValue }) => {
              // Check if satelliteClinic is false
              if (!getFieldValue("satelliteClinic")) {
                return {
                  max: 3,
                  message:
                    "Non-satellite clinics can only have a 3 digit clinic number",
                };
              } else {
                return {
                  max: 6,
                  message:
                    "Clinic Number must be no more than 6 characters long",
                };
              }
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                // Assuming 'clinics' is an array of objects with a property 'clinicNo'
                if (clinics.some((clinic) => clinic.clinic === value)) {
                  return Promise.reject(
                    new Error("Clinic Number must be unique")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          tooltip="Please enter a unique clinic number including leading 0s if applicable. Only use hyphen on satellite clinics."
        >
          <Input maxLength={6} />
        </Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.clinicNo !== currentValues.clinicNo
          }
        >
          {({ getFieldValue, setFieldsValue }) => {
            const clinicNo = getFieldValue("clinicNo");
            const homeClinicNo = getFieldValue("homeClinicNo");

            // If satelliteClinic is unchecked and homeClinicNo is not the current clinicNo, update it.
            if (!satelliteClinic && homeClinicNo !== clinicNo) {
              setFieldsValue({ homeClinicNo: clinicNo });
            }
            return (
              <>
                <Form.Item
                  label="Satellite Clinic"
                  name="satelliteClinic"
                  tooltip="Check the box if this is a satellite clinic. If checked, you must select a home clinic."
                  valuePropName="checked"
                  style={{
                    display: "inline-flex",
                    marginRight: 16,
                    marginBottom: 8,
                  }}
                >
                  <Checkbox
                    onChange={(e) => setSatelliteClinic(e.target.checked)}
                  />
                </Form.Item>
                <Form.Item
                  label="Home Clinic"
                  name="homeClinicNo"
                  rules={[
                    {
                      required: satelliteClinic,
                      message:
                        "Home Clinic No is required when Satellite Clinic is checked",
                    },
                  ]}
                  style={{ display: "inline-flex", flex: 1, marginBottom: 8 }}
                >
                  <Select
                    disabled={!satelliteClinic}
                    placeholder="Select a home clinic"
                    defaultActiveFirstOption
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .join("")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                  >
                    {!satelliteClinic && (
                      <Option value={clinicNo}>{clinicNo || "None"}</Option>
                    )}
                    {clinics.map((clinic) => (
                      <Option key={clinic.clinic} value={clinic.clinic}>
                        {clinic.clinic} - {clinic.clinicName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            );
          }}
        </Form.Item>

        <Item
          tooltip="Select from an available brand. If the brand is not available, add it first on the Brands page."
          label="Brand"
          name="brand"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a brand"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {brands.map((brand) => (
              <Option key={brand.brandId} value={brand.brandId}>
                {brand.brandName}
              </Option>
            ))}
          </Select>
        </Item>

        <Item
          tooltip="Select from an available region. If the region is not available, add it first on the Regions page."
          label="Region"
          name="region"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a region"
            showSearch
            filterOption={(input, option) =>
              option.children
                .join("")
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {regions.map((region) => (
              <Option key={region.regionCode} value={region.regionCode}>
                {region.regionCode} - {region.regionName}
              </Option>
            ))}
          </Select>
        </Item>

        <Item
          label="Clinic Status"
          tooltip="Use Open for currently open clinics. Use To Open for clinics that are not yet open. Use Pending Integration for clinics that have been acquired, even if already migrated. Use Closed for clinics that are closed and will not open."
          name="clinicStatus"
          labelCol={{ span: 24 }}
          rules={[{ required: true }]}
          onChange={(e) => setCurrentStatus(e.target.value)}
        >
          <Radio.Group>
            <Radio.Button value="Open">Open</Radio.Button>
            <Radio.Button value="To Open">To Open</Radio.Button>
            <Radio.Button value="Pending Integration">
              Pending Integration
            </Radio.Button>
            <Radio.Button value="Closed">Closed</Radio.Button>
          </Radio.Group>
        </Item>

        {currentStatus !== "Pending Integration" && (
          <Item
            label="Opening Date"
            name="openingDate"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Item>
        )}
        {currentStatus === "Pending Integration" && (
          <>
            {/* Acquisition Date (DatePicker) */}
            <Item
              tooltip="Date of ownership transfer to HearingLife"
              label="Acquisition Date"
              name="acquisitionDate"
              rules={[{ required: true }]} // Required for Pending Integration
            >
              <DatePicker />
            </Item>

            <Item
              tooltip="Date of data migration from the previous system to Blueprint."
              label="Migration Date"
              name="migrationDate"
            >
              <DatePicker />
            </Item>
          </>
        )}

        <Item
          label="Confidential"
          tooltip="(To be implemented) Confidential clinics will not be shown in the clinic directory or in clinic central to non privleged users. Clinics will also not be included in reporting."
          name="confidential"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Item>

        <Item
          label="Clinic Hours"
          name="hours"
          className="block"
          labelCol={{ span: 24 }}
          rules={[{ required: true }]}
        >
          <HoursControl />
        </Item>
        <Item
          label="Number of Testing Booths"
          name="numTestingBooths"
          initialValue={1}
          rules={[
            {
              type: "number",
              min: 0,
              message: "Cannot have negative number of testing booths",
            },
          ]}
        >
          <InputNumber min={0} max={10} />
        </Item>

        <Item
          tooltip="Select the type of parking available at the clinic."
          label="Parking Type"
          name="parkingType"
        >
          <Radio.Group>
            <Radio.Button value="Free">Free</Radio.Button>
            <Radio.Button value="Paid">Paid</Radio.Button>
            <Radio.Button value="Both">Both</Radio.Button>
            <Radio.Button value="None">None</Radio.Button>
          </Radio.Group>
        </Item>

        <Item
          label="Minimum Testing Age"
          tooltip="Select the minimum age that can be tested at the clinic based on equipment available. (To be implemented) Employee minimum age will be noted when displaying on the Clinic Central page."
          name="minimumTestingAge"
          initialValue={18}
          rules={[
            {
              type: "number",
              min: 0,
              max: 18,
              message: "Age must be between 0 and 18",
            },
          ]}
        >
          <InputNumber min={0} max={18} />
        </Item>

        <Item label="Wheelchair Accessible" name="wheelchairStatus">
          <Checkbox />
        </Item>

        <Title level={4}>Contact</Title>
        <Item
          label="Phone"
          name="phone"
          rules={[
            {
              pattern: new RegExp(/^[0-9]+$/),
              message: "Invalid phone number",
            },
            {
              max: 10,
              message: "Phone number must be no more than 10 characters long",
            },
          ]}
        >
          <Input placeholder="Phone number" />
        </Item>

        <Item
          label="Fax"
          name="fax"
          rules={[
            {
              pattern: new RegExp(/^[0-9]+$/),
              message: "Invalid fax number",
            },

            {
              max: 10,
              message: "Fax number must be no more than 10 characters long",
            },
          ]}
        >
          <Input placeholder="Fax number" />
        </Item>

        <Item
          label="Email"
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              type: "email",

              message: "The input is not a valid email address",
            },
            {
              max: 200,
              message: "Email must be no more than 200 characters long",
            },
          ]}
        >
          <Input placeholder="Email address" />
        </Item>

        <Item
          label="Website"
          name="website"
          labelCol={{ span: 24 }}
          rules={[
            {
              transform: (value) => {
                if (!value.includes("https://")) {
                  return "https://" + value;
                }
              },

              type: "url",
              message: "The input is not a valid URL",
            },
            {
              max: 200,
              message: "Website must be no more than 200 characters long",
            },
          ]}
        >
          <Input
            addonBefore="https://"
            style={{ width: "100%" }}
            placeholder="Website URL"
          />
        </Item>
        <Title level={4}>Address</Title>
        <Item label="" name="addressInfo">
          <AddressControl />
        </Item>

        <Title level={4}>Corporate Info</Title>

        <Item
          tooltip="Ownership status of the clinic."
          label="Ownership Status"
          name="ownershipStatus"
        >
          <Select placeholder="Ownership Status">
            <Option key={1} value="Fully-Owned">
              Fully Owned
            </Option>
            <Option key={2} value="Partially-Owned">
              Partially Owned
            </Option>
          </Select>
        </Item>

        {/* Company (Input) */}
        <Item tooltip="(Tooltip to be added)" label="Company" name="company">
          <Input placeholder="Company name" />
        </Item>
        {/* Optional fields without defaults */}
        <Item
          tooltip="A unique identifier used by finance to identify the clinic."
          label="Code"
          name="code"
          rules={[
            {
              max: 5,
              message: "Code must be no more than 5 characters long",
            },
          ]}
        >
          <Input />
        </Item>

        {/* BP Brand ID (Input) */}
        <Item
          tooltip={
            'The "Location ID" of this clinic in Blueprint. Sometimes known as branch ID.'
          }
          label="BP Location ID"
          name="bpBranchId"
        >
          <Input placeholder="BP Branch ID" />
        </Item>
        <Item
          tooltip="The system ID of what Blueprint system this clinic is in."
          label="Blueprint System"
          name="wdhBuNo"
        >
          <Select placeholder="Blueprint System">
            <Option value="1">1 - Ontario</Option>
            <Option value="53">53 - Costal</Option>
            <Option value="63">63 - Praries</Option>
          </Select>
        </Item>
        {/* Lockbox Code (Input) */}
        <Item
          label="Lockbox Code"
          name="lockboxCode"
          rules={[
            {
              max: 50,
              message: "Lockbox code must be no more than 50 characters long",
            },
          ]}
        >
          <Input placeholder="Lockbox code" />
        </Item>

        {/* Alarm Code (Input) */}
        <Item
          label="Alarm Code"
          name="alarmCode"
          rules={[
            {
              max: 100,
              message: "Alarm code must be no more than 100 characters long",
            },
          ]}
        >
          <Input placeholder="Alarm code" />
        </Item>

        <Title level={4}>Lease Information</Title>

        <Item label="Lease Start Date" name="leaseStartDate">
          <DatePicker />
        </Item>
        <Item label="Lease End Date" name="leaseEndDate">
          <DatePicker />
        </Item>
        <Item
          label="Property Manager Name"
          name="propertyManagerName"
          labelCol={{ span: 24 }}
          rules={[
            {
              max: 200,
              message:
                "Property Manager Name must be no more than 200 characters long",
            },
          ]}
        >
          <Input placeholder="Property Manager Name" />
        </Item>
        <Item
          label="Property Manager Details"
          name="propertyManagerDetails"
          labelCol={{ span: 24 }}
          rules={[
            {
              max: 200,
              message:
                "Property Manager Details must be no more than 200 characters long",
            },
          ]}
        >
          <Input.TextArea placeholder="Property Manager Details" />
        </Item>
        <Title level={4}>Other Notes</Title>
        <Item name="notes">
          <Input.TextArea placeholder="Additional Clinic Notes" />
        </Item>

        <Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting} // Use the isSubmitting state to control the loading state of the button
            disabled={isSubmitting} // Disable the button while submitting
          >
            Submit
          </Button>
        </Item>
      </Form>
    </Drawer>
  );
};

export default AddClinic;
