import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form, Select, notification, message } from "antd";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import useRoleCheck from "utilities/hooks/roleCheck";
import axiosInstance from "utilities/axios";
import FieldsFormList from "./components/FieldsFormList"; // Assuming FieldsFormList is in the same directory

const { Option } = Select;

function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

const MainForm = ({ credential = {}, form, onFinish, defaultCategory }) => {
  const { hasPermission } = useRoleCheck();
  const clinic = useSelector((state) => state.selectedClinic.clinicData);
  const categories = useSelector((state) => state.credentialCategories.data);
  const typeList = useSelector((state) => state.credentialTypes.data);

  const memoizedCredential = useMemo(() => {
    // If credential is an empty object, return a consistent reference, like null
    if (isEmptyObject(credential)) {
      return null;
    }
    return credential;
  }, [credential]);

  const [typeId, setTypeId] = useState(null);
  const filteredCategories = categories.filter((cat) => {
    return (
      (hasPermission(["IT.Write"], [], null, false) &&
        cat.categoryName === "IT") ||
      (hasPermission(["Operations.Write"], [], null, false) &&
        cat.categoryName === "Insurer") ||
      (hasPermission(
        [
          "Marketing.Write",
          "Operations.Write",
          "Audiology.Admin",
          "Finance.Write",
          "Admin.Basic",
        ],
        ["Sales.RM"],
        clinic.clinicNo,
        false
      ) &&
        cat.categoryName === "Other")
    );
  });

  const initialCategoryId =
    defaultCategory || memoizedCredential.category.categoryId || null;

  const categoryExistsInFiltered = filteredCategories.some(
    (cat) => cat.categoryId === initialCategoryId
  );

  const [category, setCategory] = useState(
    categoryExistsInFiltered
      ? initialCategoryId
      : filteredCategories[0]?.categoryId || null
  );

  const selectedCategory = filteredCategories.find(
    (listCategory) => listCategory.categoryId === category
  )?.categoryName;

  const [vendors, setVendors] = useState([]);
  const [types, setTypes] = useState(typeList);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const fetchTypes = useCallback(
    (category, company) => {
      // First filters types by category, (categoryId must be in the list of categoryIds for the type)
      // Then orders the types associated with the company first if there is a company, this comes from the companies state

      let filteredTypes = typeList.filter((type) =>
        type.categories.includes(category)
      );
      //todo: VERIFY THIS WORKS
      //TODO: make sure this gets called on company change as well
      if (company) {
        filteredTypes = filteredTypes.sort((a, b) => {
          if (company.types.includes(a.id)) return -1;
          if (company.types.includes(b.id)) return 1;
          return 0;
        });
      }
      setTypes(filteredTypes);
    },
    [typeList]
  );

  const onPopupScroll = (e) => {
    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      setPage((prevPage) => prevPage + 1);
      e.target.scrollTop = e.target.scrollHeight;
    }
  };

  const handleSearchChange = (newSearch) => {
    setSearch(newSearch);
    setPage(1);
  };

  // Debounced version of the search handler
  const debouncedSearchHandler = useCallback(
    debounce(handleSearchChange, 200),
    []
  );
  const fetchVendors = useCallback(
    (currentCategory = category, currentSearch = search) => {
      if (currentCategory === null) {
        setVendors([]);
        return;
      }
      axiosInstance
        .get(
          `/credentials/vendors?search=${currentSearch}&page=${page}&category=${currentCategory}`
        )
        .then((response) => {
          setVendors((currentVendors) => {
            return page === 1
              ? response.data
              : [...currentVendors, ...response.data];
          });
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.message,
          });
        });
    },
    [page, category, search]
  );

  useEffect(() => {
    if (category) fetchVendors();
  }, [category, search, fetchVendors]);

  useEffect(() => {
    fetchTypes(category);
  }, [category, fetchTypes]);
  useEffect(() => {
    setPage(1);
  }, [search, category, memoizedCredential]);

  useEffect(() => {
    form.setFieldsValue({
      categoryId: category,
      fields: credential.fields || [],
    });
  }, [form, category, credential.fields]);

  useEffect(() => {
    // Initialize the vendor and insurer objects
    if (memoizedCredential) {
      // Set search to the initial vendor or insurer name, if available
      setSearch(
        memoizedCredential.vendor
          ? memoizedCredential.vendor.vendorName
          : memoizedCredential.insurer
          ? memoizedCredential.insurer.insurerName
          : ""
      );

      // Set category
      setCategory(
        defaultCategory || memoizedCredential.category.categoryId || null
      );
      // Initialize form values
      form.setFieldsValue({
        categoryId:
          defaultCategory || memoizedCredential.category.categoryId || null,
        vendorId: memoizedCredential.vendorId,
        typeId: memoizedCredential.type ? memoizedCredential.type.typeId : null,
        ents: memoizedCredential.ents,
        fields: memoizedCredential.fields
          .map((field) => ({
            ...field,
            fieldType: field.isSecret ? "password" : "text",
          }))
          .filter((field) => delete field.isSecret),
      });
    }
  }, [defaultCategory, form, memoizedCredential]);

  return (
    <Form
      labelAlign="right"
      labelCol={{ span: 4 }}
      form={form}
      autoComplete="off"
      onFinish={(values) => {
        if (values.fields.length === 0) {
          message.error("Please include at least one field");
          return;
        }

        values.fields = values.fields
          .map((field) => ({
            ...field,
            isSecret: field.fieldType === "password",
          }))
          .filter((field) => delete field.fieldType);

        onFinish(values);
      }}
      onValuesChange={(changedValues, allValues) => {
        if (changedValues.typeId) {
          setTypeId(changedValues.typeId);
        }
        if ("categoryId" in changedValues) {
          setCategory(changedValues.categoryId);
        }

        allValues.fields?.forEach((field, index) => {
          if (
            field.isPrimary &&
            changedValues.fields &&
            changedValues.fields[index] &&
            changedValues.fields[index].isPrimary
          ) {
            // Set all other fields' `isPrimary` to false
            const updatedFields = allValues.fields.map((f, idx) => ({
              ...f,
              isPrimary: idx === index,
            }));

            form.setFieldsValue({ fields: updatedFields });
          }
        });
      }}
    >
      <Form.Item label="Category" name="categoryId">
        <Select
          options={
            filteredCategories.map((cat) => ({
              label: cat.categoryName,
              value: cat.categoryId,
            })) || []
          }
        />
      </Form.Item>
      <Form.Item
        name="vendorId"
        label={selectedCategory === "Insurer" ? "Insurer" : "Vendor"}
      >
        <Select
          showSearch
          filterOption={false}
          onSearch={debouncedSearchHandler}
          onPopupScroll={onPopupScroll}
          options={vendors.map((company) => ({
            label: company.vendorName, // Displayed text
            value: company.vendorId, // Actual value
          }))}
        />
      </Form.Item>
      <Form.Item label="Type" name="typeId">
        <Select>
          {types.map((type) => (
            <Option key={type.id} value={type.id}>
              {type.typeName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {selectedCategory === "IT" && clinic.ents.length > 0 && (
        <Form.Item label="ENTs" name="ents">
          <Select mode="multiple" placeholder="Select ENTs">
            {clinic.ents.map((ent) => (
              <Option key={ent.id} value={ent.id}>
                {ent.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <FieldsFormList typeId={typeId} />
    </Form>
  );
};

export default MainForm;
