import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Select, Divider, Flex, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinics } from "utilities/redux/slices/clinicsSlice";
import EditClinicEntry from "./EditClinicEntry";

import dayjs from "dayjs";
import axiosInstance from "utilities/axios";

const EditClinics = ({
  employee_clinics,
  initials,
  open,
  setOpen,
  onChange,
}) => {
  const [form] = Form.useForm();

  const employeeClinics = employee_clinics ?? [];
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinics.data);

  const [isSaving, setIsSaving] = useState(false);

  // filtered clinics to exclude the ones already in the employee's clinics
  const filteredClinics = clinics.filter(
    (clinic) =>
      !form
        .getFieldValue("clinics")
        ?.some(
          (employee_clinic) => employee_clinic.clinicNo === clinic.clinic
        ) &&
      !employeeClinics.some(
        (employee_clinic) => employee_clinic.clinicNo === clinic.clinic
      )
  );

  const [selectedClinic, setSelectedClinic] = useState(null);
  const selectedClinicName = clinics.find(
    (clinic) => clinic.clinic === selectedClinic
  )?.clinicName;

  useEffect(() => {
    dispatch(
      fetchClinics({
        status: ["Open", "To Open", "Pending Integration"],
      })
    );
  }, [dispatch]);

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleSave = (values) => {
    if (!values.clinics) {
      notification.error({
        message: "Error",
        description: "Internal Error.",
      });
      return;
    }
    setIsSaving(true);
    axiosInstance
      .put(`/employees/${initials}/clinics`, values.clinics)
      .then((res) => {
        notification.success({
          message: "Clinics updated",
          description: "Clinics have been updated successfully.",
        });
        onChange();
        setOpen(false);
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Error",
          description: "An error occurred while updating the clinics.",
        });
      })
      .finally(() => {
        setIsSaving(false); // Stop loading regardless of result
      });
  };

  return (
    <Modal
      title="Edit Clinics"
      open={open}
      onCancel={handleCancel}
      okText="Save"
      okButtonProps={{ loading: isSaving }}
      cancelText="Cancel"
      width={700}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSave(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        initialValues={{
          clinics: employeeClinics.map((clinic) => ({
            ...clinic,
            startDate: clinic.startDate ? dayjs(clinic.startDate) : null,
            endDate: clinic.endDate ? dayjs(clinic.endDate) : null,
          })),
        }}
      >
        <Form.List name="clinics">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <EditClinicEntry
                    clinic={{
                      clinicName: form.getFieldValue(["clinics", field.name])
                        ?.clinicName,
                      clinicNo: form.getFieldValue(["clinics", field.name])
                        ?.clinicNo,
                    }}
                    field={field}
                    remove={() => {
                      remove(field.name);
                    }}
                  />
                  {index < fields.length - 1 && <Divider />}
                </div>
              ))}
              <Form.Item shouldUpdate={true}>
                <Flex justify="end">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                    style={{ width: "60%" }}
                    value={selectedClinic}
                    onChange={(value) => {
                      setSelectedClinic(value);
                    }}
                    placeholder="Select a clinic"
                    allowClear
                    options={filteredClinics.map((clinic) => ({
                      value: clinic.clinic,
                      label: `${clinic.clinic} - ${clinic.clinicName}`,
                    }))}
                  />

                  <Button
                    onClick={() => {
                      if (selectedClinic) {
                        add({
                          clinicName: selectedClinicName,
                          clinicNo: selectedClinic,
                          endDate: null,
                          startDate: null,
                          isFullTime: true,
                          isPermanent: true,
                          isPrimary: false,
                          hours: null,
                          notes: null,
                        });
                        //log fields
                        setSelectedClinic(null);
                      }
                    }}
                  >
                    Add
                  </Button>
                </Flex>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default EditClinics;
