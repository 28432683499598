import React from "react";
import { Button, Input, Tooltip, Typography, notification } from "antd";
import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const CredentialFieldDisplay = ({ key, field, revealedId, setRevealedId }) => {
  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Value copied to clipboard!" });
  };

  const handleRevealClick = (fieldId) => {
    setRevealedId(revealedId === fieldId ? null : fieldId);
  };

  return (
    <div key={field.fieldId} style={{ marginBottom: 20 }}>
      <Text strong>{field.fieldName}</Text>
      {field.fieldDescription && (
        <div>
          <Text type="secondary">{field.fieldDescription}</Text>
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "8px",
        }}
      >
        <Input
          value={
            revealedId === field.fieldId || !field.isSecret
              ? field.fieldValue
              : "••••••••"
          }
          suffix={
            <CopyOutlined
              onClick={() => handleCopyClick(field.fieldValue)}
              style={{ color: "rgba(0, 0, 0, 0.45)" }}
            />
          }
          style={{ flex: "auto", fontSize: "1.2em" }}
          readOnly
        />
        <div className="w-4 p-1">
          {field.isSecret && (
            <Tooltip title={revealedId === field.fieldId ? "Hide" : "Reveal"}>
              <Button
                shape="circle"
                icon={
                  revealedId === field.fieldId ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={() => handleRevealClick(field.fieldId)}
                style={{ border: "none", marginLeft: "auto" }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {field.isPrimary && (
        <Text italic style={{ display: "block", marginTop: "4px" }}>
          Primary Field
        </Text>
      )}
    </div>
  );
};
export default CredentialFieldDisplay;
