// src/redux/slices/vendorsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching insurer data
export const fetchVendors = createAsyncThunk(
  "vendors/fetchVendors",
  async ({ search, category }) => {
    const params = {};
    if (search) {
      params.search = search;
    }
    if (category) {
      params.category = category;
    }
    const response = await axiosInstance.get("/vendors", { params: params });
    return response.data;
  }
);

const vendorsSlice = createSlice({
  name: "vendors",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // Changed from state.clinics to state.data
      })
      .addCase(fetchVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default vendorsSlice.reducer;
