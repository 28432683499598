// src/redux/slices/clinicsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching clinics data
export const fetchCategories = createAsyncThunk(
  "credentials/fetchCategories",
  async () => {
    const response = await axiosInstance.get("/credentials/categories");
    const data = response.data;

    // Move "other" category to the end of the list
    const otherCategoryIndex = data.findIndex(
      (category) => category.categoryName === "Other"
    );
    if (otherCategoryIndex !== -1) {
      const otherCategory = data.splice(otherCategoryIndex, 1)[0];
      data.push(otherCategory);
    }
    return data;
  }
);

const credentialCategoriesSlice = createSlice({
  name: "credentialCategories",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default credentialCategoriesSlice.reducer;
