import React, { useState } from "react";
import { Button, Input, notification, Flex, Space } from "antd";
import axiosInstance from "utilities/axios";

const ClinicNameChange = ({ clinicInfo, onChange }) => {
  const [clinicName, setClinicName] = useState(clinicInfo.clinicName);

  const handleChangeClinicName = () => {
    axiosInstance
      .patch(`/clinics/${clinicInfo.clinicNo}`, {
        clinicName: clinicName,
      })
      .then((response) => {
        notification.success({
          message: "Clinic Name Updated",
        });
        onChange();
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Clinic Name",
          description: err.response.data.message,
        });
      });
  };

  return (
    <Flex vertical wrap align="center" gap="small">
      <Input
        placeholder="Enter new clinic name"
        value={clinicName}
        onChange={(e) => setClinicName(e.target.value)}
      />
      <Button type="primary" onClick={handleChangeClinicName}>
        Submit
      </Button>
    </Flex>
  );
};

export default ClinicNameChange;
