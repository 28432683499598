import React, { useState, useEffect } from "react";
import { Modal, Skeleton, Typography, List, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axiosInstance from "utilities/axios";
import dayjs from "dayjs";

const { Title, Text, Paragraph } = Typography;

const ReleaseNotesModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (open) {
      // Simulate a data fetch
      setLoading(true);
      axiosInstance
        .get("/release-notes")
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          notification.error({
            message: "Failed to fetch release notes",
            description: error.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  return (
    <Modal
      title={
        <Title level={3} style={{ margin: 0 }}>
          Release Notes
        </Title>
      }
      open={open}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width={600}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <List
            itemLayout="vertical"
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item.version}>
                <Title level={5}>
                  {item.version} - {dayjs(item.date).format("MMMM D, YYYY")}
                </Title>
                <Text>{}</Text>
                <Paragraph
                  style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                >
                  {item.notes}
                </Paragraph>
              </List.Item>
            )}
          />
        </div>
      )}
    </Modal>
  );
};

export default ReleaseNotesModal;
