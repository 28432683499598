import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Flex,
  Slider,
  Checkbox,
} from "antd";
import axiosInstance from "utilities/axios";
const { Option } = Select;
const { Text } = Typography;

const FilterModal = ({ filters, setFilters, open, onClose }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [positionOptions] = useState(["CSA", "HP", "Other", "DSD", "RM"]);

  const [minTestAgeIncluded, setMinTestAgeIncluded] = useState(
    !!filters.minimumTestingAge
  );
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    setStatusOptions(["Active", "On Leave"]);
    axiosInstance.get("/divisions").then((response) => {
      setDivisionOptions(response.data);
    });
  }, []);

  useEffect(() => {
    if (filters.division.length > 0) {
      axiosInstance
        .get("/regions", { params: { division: filters.division } })
        .then((response) => {
          setRegionOptions(response.data);
          setFilters((prevFilters) => ({
            ...prevFilters,
            region: prevFilters.region.filter((value) =>
              response.data.map((region) => region.regionCode).includes(value)
            ),
          }));
        });
    } else {
      axiosInstance.get("/regions").then((response) => {
        setRegionOptions(response.data);
        setFilters((prevFilters) => ({
          ...prevFilters,
          region: prevFilters.region.filter((value) =>
            response.data.map((region) => region.regionCode).includes(value)
          ),
        }));
      });
    }
  }, [setFilters, filters.division]);

  return (
    <Modal
      title="Filter Options"
      open={open}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Row gutter={8} wrap={true}>
        <Col span={12}>
          <Flex vertical>
            <Text>Employee Status</Text>
            <Select
              mode="multiple"
              placeholder="Select Status"
              value={filters.employeeStatus}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, employeeStatus: value }))
              }
            >
              {statusOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <Text>Position</Text>
            <Select
              mode="multiple"
              placeholder="Select Position"
              value={filters.position}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, position: value }))
              }
            >
              {positionOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <Text>Employee Region</Text>
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Region"
              value={filters.region}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, region: value }))
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {regionOptions.map((option) => (
                <Option key={option.regionCode} value={option.regionCode}>
                  {option.regionCode} - {option.regionName}
                </Option>
              ))}
            </Select>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <Text>Employee Division</Text>
            <Select
              mode="multiple"
              placeholder="Select Division"
              value={filters.division}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, division: value }))
              }
            >
              {divisionOptions.map((option) => (
                <Option key={option.divisionCode} value={option.divisionCode}>
                  {option.divisionName}
                </Option>
              ))}
            </Select>
          </Flex>
        </Col>
      </Row>
      {showAdvanced && (
        <Row gutter={8} wrap={true} style={{ marginTop: 16 }}>
          <Col span={8}>
            <Flex vertical>
              <Text>Willing to Relocate</Text>
              <Select
                allowClear
                placeholder="Select Option"
                value={filters.willingToRelocate}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, willingToRelocate: value }))
                }
              >
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Flex>
          </Col>
          <Col span={8}>
            <Flex vertical>
              <Text>Languages Spoken</Text>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Input Languages"
                value={filters.languages}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, language: value }))
                }
              />
            </Flex>
          </Col>
          <Col span={8}>
            <Flex vertical>
              <Text>Industrial Hearing Test Certified</Text>
              <Select
                allowClear
                placeholder="Select Option"
                value={filters.industrialHTCertified}
                onChange={(value) =>
                  setFilters((prev) => ({
                    ...prev,
                    industrialHTCertified: value,
                  }))
                }
              >
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Flex>
          </Col>
          <Col span={8}>
            <Flex vertical>
              <Text>Wax Removal Certified</Text>
              <Select
                allowClear
                placeholder="Select Option"
                value={filters.waxRemovalCertified}
                onChange={(value) =>
                  setFilters((prev) => ({
                    ...prev,
                    waxRemovalCertified: value,
                  }))
                }
              >
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Flex>
          </Col>
          <Col span={8}>
            <Flex vertical>
              <Text>Subject Matter Expert</Text>
              <Select
                allowClear
                placeholder="Select Option"
                value={filters.subjectMatterExpert}
                onChange={(value) =>
                  setFilters((prev) => ({
                    ...prev,
                    subjectMatterExpert: value,
                  }))
                }
              >
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Flex>
          </Col>
          <Col span={24}>
            <Text>Minimum Testing Age</Text>
            <Flex gap={8}>
              <Checkbox
                checked={minTestAgeIncluded}
                onChange={(e) => {
                  setMinTestAgeIncluded(e.target.checked);
                  setFilters((prev) => ({
                    ...prev,
                    minimumTestingAge: e.target.checked
                      ? prev.minimumTestingAge
                      : null,
                  }));
                }}
              ></Checkbox>
              <Slider
                min={0}
                max={18}
                disabled={!minTestAgeIncluded}
                value={filters.minimumTestingAge}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, minimumTestingAge: value }))
                }
                style={{ width: "90%", marginRight: 10 }}
              />
            </Flex>
          </Col>
        </Row>
      )}
      <Button
        onClick={() => setShowAdvanced(!showAdvanced)}
        style={{ marginTop: 16 }}
        type="link"
      >
        {showAdvanced ? "Hide Advanced" : "Show Advanced"}
      </Button>
    </Modal>
  );
};

export default FilterModal;
