import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Input,
  DatePicker,
  Checkbox,
  TimePicker,
  Tabs,
  Form,
  Typography,
  notification,
  Popconfirm,
  List,
  Tag,
  AutoComplete,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { fetchClinicData } from "utilities/redux/slices/clinicSlice";
import axiosInstance from "utilities/axios";
import HoursControl from "components/HoursControl";

const { Title, Text } = Typography;

const EmployeeControl = ({ value = [], onChange }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState(value);
  const [foundENTs, setFoundENTs] = useState([]);

  const [form] = Form.useForm();

  const fetchENTs = async (term) =>
    axiosInstance.get(`ents/employees?search=${term}`).then((res) => {
      return res.data;
    });

  const handleSearch = async (term) => {
    setSearchTerm(term);
    const results = await fetchENTs(term);
    setFoundENTs(results);
  };

  const addEmployee = (employee) => {
    if (!employee.id) {
      employee = { ...employee, id: null }; // For new employee, set id to null
    }
    setEmployees([...employees, employee]);
    onChange && onChange([...employees, employee]);
    setModalVisible(false);
    form.resetFields();
  };

  const removeEmployee = (index) => {
    const updatedEmployees = employees.filter((_, i) => i !== index);
    setEmployees(updatedEmployees);
    onChange && onChange(updatedEmployees);
  };

  return (
    <div>
      <AutoComplete
        //map options to include title if present, first name if present, and last name if present, if not remove the space and null value
        options={foundENTs.map((ent) => ({
          value: `${ent.title ? ent.title + " " : ""}${
            ent.firstName ? ent.firstName + " " : ""
          }${ent.lastName ? ent.lastName : ""}`,
          label: `${ent.title ? ent.title + " " : ""}${
            ent.firstName ? ent.firstName + " " : ""
          }${ent.lastName ? ent.lastName : ""}`,
          employee: ent,
        }))}
        onSearch={handleSearch}
        value={searchTerm}
        onSelect={(value, option) => {
          addEmployee(option.employee, false);
          setSearchTerm("");
          setFoundENTs([]);
        }}
        placeholder="Search for existing ENTs"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setModalVisible(true)}
        >
          Add New ENT Employee
        </Button>
      </div>

      <List
        dataSource={employees}
        renderItem={(item, index) => (
          <List.Item
            key={index}
            actions={[
              <Tag color={item.id ? "blue" : "green"}>
                {item.id ? "Existing" : "New"}
              </Tag>,
              <Button type="link" onClick={() => removeEmployee(index)}>
                Remove
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Text>
                  {item.title && `${item.title} `}
                  {item.firstName && `${item.firstName} `}
                  {item.nickname && `(${item.nickname}) `}
                  {item.lastName && `${item.lastName} `}
                </Text>
              }
              description={item.job}
            />
          </List.Item>
        )}
      />
      <Modal
        title="Add New ENT Employee"
        open={isModalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={form.submit} // Use the new handleOk function
      >
        <Form
          form={form}
          onFinish={(values) => {
            addEmployee(values, true);
          }}
          initialValues={{
            title: null, // Set the initial value to null
            firstName: null,
            lastName: null,
            nickname: null,
            job: null,
          }}
        >
          <Form.Item label="Title" name="title">
            <Select>
              <Select.Option value="Dr.">Dr.</Select.Option>
              <Select.Option value={null}>None</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="First Name" name="firstName">
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName">
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Nickname" name="nickname">
            <Input placeholder="Nickname" />
          </Form.Item>
          <Form.Item label="Job" name="job">
            <AutoComplete
              placeholder="Select or enter a job"
              options={[{ value: "ENT" }, { value: "ENT Assistant" }]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
const compareHours = (a, b) => {
  // Compare each hours of the day of the week and return if they are the same
  //The format should be a list of dictionaries each with a day of week 1-6 and an open and close time
  //also check isOpen to see if they are matching, those days hours does not matter
  //If the hours are the same return true

  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (
      a[i].dayOfWeek !== b[i].dayOfWeek ||
      a[i].isOpen !== b[i].isOpen ||
      a[i].openTime !== b[i].openTime ||
      a[i].closeTime !== b[i].closeTime
    )
      return false;
  }
  return true;
};

const ENTEditModal = ({ visible, closeModal, ent, clinicDetails }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const isFormInitialized = useRef(false);

  const dispatch = useDispatch();
  const onChange = () => {
    dispatch(fetchClinicData());
  };

  const handleCancel = () => {
    if (unsavedChanges) {
      setShowPopconfirm(true); // Show Popconfirm
    } else {
      form.resetFields(); // Reset form
      setShowPopconfirm(false); // Hide Popconfirm
      setUnsavedChanges(false); // Reset unsavedChanges
      setActiveTab("1");
      isFormInitialized.current = false;
      closeModal();
    }
  };
  const handleDelete = () => {
    axiosInstance
      .delete(`ents/${ent.id}`)
      .then((res) => {
        notification.success({
          message: "ENT Removed",
          description: "The ENT has been successfully closed.",
        });
        onChange && onChange();
        closeModal();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description:
            "There was an error deleting the ENT, please try again later.",
        });
      });
  };

  const handleConfirmClose = () => {
    setUnsavedChanges(false); // Reset unsavedChanges
    setShowPopconfirm(false); // Hide Popconfirm
  };

  const handlePopconfirmCancel = () => {
    setShowPopconfirm(false); // Hide Popconfirm only
  };

  const onFinish = (values) => {
    // validate form includes at least one employee
    if (!values.employees) {
      values.employees = ent.employees;
    }
    //add clinicNo to values
    values.clinicNo = ent.clinicNo;
    axiosInstance
      .patch(`ents/${ent.id}`, values)
      .then((res) => {
        notification.success({
          message: "Data Updated",
          description: "The ENT has been successfully updated.",
        });
        setUnsavedChanges(false);
        onChange && onChange();
        isFormInitialized.current = false;
        closeModal();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description:
            "There was an error creating the ENT, please try again later.",
        });
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    setUnsavedChanges(true);
  };
  const handleCheck = (e) => {
    if (!e.target.checked) {
      // reset form values if unchecked
      form.setFieldsValue({
        hours: ent.hours || clinicDetails.clinicHours || [],
      }); // ensure defaultHours is an array or fallback to an empty array
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  useEffect(() => {
    if (!showPopconfirm && !unsavedChanges && isFormInitialized.current) {
      // Only run this block if the form has been initialized
      setActiveTab("1"); // Set to "Basic Information" tab
      setUnsavedChanges(false); // Reset unsavedChanges variable for popconfirm
      setShowPopconfirm(false); // Hide Popconfirm if visible
      form.resetFields();
      isFormInitialized.current = false;
      closeModal();
    }
  }, [showPopconfirm, unsavedChanges, closeModal, form]);

  useEffect(() => {
    if (ent) {
      // Only run this effect if ent is not null
      form.setFieldsValue({
        name: ent.name,
        sameAsClinicHours: compareHours(ent.hours, clinicDetails.clinicHours),
        hours: ent.hours || [], // Fallback to an empty array if ent.hours is undefined
        openDate: dayjs(ent.openDate) || null,
        closeDate: dayjs(ent.closeDate) || null,
        clinicNo: ent.clinicNo,
        employees: ent.employees,
      });
      isFormInitialized.current = true;
    }
  }, [ent, form]);
  if (!ent) return null;

  return (
    <Modal
      title={<Title level={4}>Edit ENT Practice</Title>}
      open={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      footer={[
        <Popconfirm
          title="You have unsaved changes. Are you sure you want to close?"
          onConfirm={handleConfirmClose}
          onCancel={handlePopconfirmCancel}
          open={showPopconfirm}
          cancelText={"No"}
          okText={"Close"}
        >
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
        </Popconfirm>,
        <Popconfirm
          title="Are you sure you want to remove this ENT?"
          onConfirm={handleDelete}
          cancelText={"No"}
          okText={"Remove"}
        >
          <Button key="delete" type="dashed" danger style={{ float: "left" }}>
            Remove ENT
          </Button>
        </Popconfirm>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          style={{ float: "right" }}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          name: ent.name,
          sameAsClinicHours: compareHours(ent.hours, clinicDetails.clinicHours),
          hours: ent.hours || [], // Fallback to an empty array if ent.hours is undefined
          openDate: dayjs(ent.openDate) || null,
          closeDate: dayjs(ent.closeDate) || null,
          clinicNo: ent.clinicNo,
          employees: ent.employees,
        }}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onChange={handleTabChange}
          items={[
            {
              label: "Basic Information",
              key: "1",
              children: (
                <div>
                  <Form.Item
                    label={<Text>Name</Text>}
                    name="name"
                    rules={[
                      { required: true, message: "Please input the name!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label={<Text>Open Date</Text>} name="openDate">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    label={<Text>Hours Same as Clinic</Text>}
                    name="sameAsClinicHours"
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handleCheck} />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      return (
                        prevValues.sameAsClinicHours !==
                        currentValues.sameAsClinicHours
                      );
                    }}
                  >
                    {({ getFieldValue }) => {
                      return !getFieldValue("sameAsClinicHours") ? (
                        <Form.Item name="hours">
                          <HoursControl />
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </div>
              ),
            },
            {
              label: "Employees",
              key: "2",
              children: (
                <Form.Item name="employees">
                  <EmployeeControl />
                </Form.Item>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

export default ENTEditModal;
