import React from "react";
import { Tag, Typography, Popover, Row, Col, Space, Flex } from "antd";
import {
  CheckCircleOutlined,
  FileTextOutlined,
  StarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { formatDateRange, formatHours } from "utilities/formats";

const { Text } = Typography;

const ClinicEntry = ({ clinic, initials }) => {
  return (
    <Row gutter={16} align="middle">
      <Col span={2}>
        {clinic.isPrimary && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StarOutlined style={{ fontSize: "16px" }} />
            <Text type="secondary" style={{ fontSize: "12px" }}>
              Primary
            </Text>
          </div>
        )}
      </Col>
      <Col span={6}>
        <Text strong>{clinic.clinicName}</Text>
      </Col>
      <Col span={3}>
        <Text>{clinic.clinicNo}</Text>
      </Col>
      <Col span={6}>
        <Space direction="vertical">
          <Text>Clinic Assignment:</Text>
          {clinic.isPermanent ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Permanent
            </Tag>
          ) : (
            <Text type="secondary">
              {formatDateRange(clinic.startDate, clinic.endDate)}
            </Text>
          )}
        </Space>
      </Col>
      <Col span={4}>
        <Space direction="vertical">
          <Text>
            <ClockCircleOutlined /> Hours:
          </Text>
          <Popover
            title="Hours"
            autoAdjustOverflow
            overlayInnerStyle={{
              paddingRight: "32px",
              paddingBottom: "32px",
            }}
            content={formatHours(clinic.hours)}
          >
            <Text type="secondary">
              {" "}
              {clinic.isFullTime ? "Clinic Hours" : "Part Time"}
            </Text>
          </Popover>
        </Space>
      </Col>
      <Col span={3} style={{ textAlign: "right" }}>
        {clinic.notes && (
          <Popover content={clinic.notes} title="Notes" trigger="hover">
            <Flex vertical justify="center" align="center" gap="4px">
              <Text>Notes</Text>
              <FileTextOutlined style={{ fontSize: "20px" }} />
            </Flex>
          </Popover>
        )}
      </Col>
    </Row>
  );
};

export default ClinicEntry;
