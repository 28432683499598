import { React, useState, useEffect } from "react";
import { Button, Table, Input, Modal, Select, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utilities/axios";
import Footer from "components/Footer";

const { Option } = Select;
const { Text } = Typography;
const Regions = () => {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [search, setSearch] = useState("");

  const [regionStatus, setStatus] = useState(["Active"]);
  const [statusOptions, setStatusOptions] = useState(["Active", "Inactive"]);
  const [division, setDivision] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFilterButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const columns = [
    {
      title: "Region",
      dataIndex: "regionCode",
      sorter: (a, b) => a.regionCode.localeCompare(b.regionCode),
    },
    {
      title: "Region Name",
      dataIndex: "regionName",
      sorter: (a, b) => a.regionName.localeCompare(b.regionName),
    },
    {
      title: "Regional Manager",
      dataIndex: "rmName",
      sorter: (a, b) => a.rmName.localeCompare(b.rmName),
    },
    {
      title: "Division Code",
      dataIndex: "divisionCode",
      sorter: (a, b) => a.divisionCode.localeCompare(b.divisionCode),
    },
    {
      title: "Division Name",
      dataIndex: "divisionName",
      sorter: (a, b) => a.divisionName.localeCompare(b.divisionName),
    },
    {
      title: "DSD",
      dataIndex: "dsdName",
      sorter: (a, b) => a.dsdName.localeCompare(b.dsdName),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    // {
    //   title: "Detail",
    //   dataIndex: "",
    //   key: "x",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => {
    //         navigate(`/regions/${record.regionCode}`);
    //       }}
    //       disabled
    //     >
    //       Detail
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    axiosInstance.get("/divisions").then((response) => {
      setDivisionOptions(response.data);
    });
  }, []);

  //get page content
  useEffect(() => {
    axiosInstance
      .get("/regions", {
        params: {
          search: search,
          division: division,
          status: regionStatus,
        },
      })
      .then((response) => {
        setRegions(response.data);
      });
  }, [search, division, regionStatus]);

  return (
    <>
      <div className="p-8">
        <div className="flex">
          <Input.Search
            placeholder="Search Regions"
            allowClear
            size="large"
            suffix="" // Remove the search icon
            onChange={handleSearch}
            className="mr-2"
          />
          <Button
            type="primary"
            size="large"
            icon={<FilterOutlined />}
            onClick={handleFilterButtonClick}
          >
            Filter
          </Button>
        </div>

        <div className="mt-4">
          <Table columns={columns} dataSource={regions} rowKey="regionCode" />
          <Footer />
        </div>
      </div>
      <Modal
        title="Filter Options"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <div className="flex flex-col">
          {/* <Text className="mb-0 mt-2">Region Status</Text>
          <Select
            mode="multiple"
            placeholder="Region Status"
            value={regionStatus}
            onChange={setStatus}
          >
            {statusOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select> */}
          <Text className="mb-0 mt-2">Division</Text>
          <Select
            mode="multiple"
            placeholder="Division"
            value={division}
            onChange={setDivision}
          >
            {divisionOptions.map((option) => (
              <Option key={option.divisionCode} value={option.divisionCode}>
                {option.divisionName}
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
};

export default Regions;
