import React from "react";
import { Divider, Space, Typography } from "antd";

const { Title, Text } = Typography;

const ENTDisplay = ({ ents }) => {
  if (!ents || ents.length === 0) return null;
  return (
    <>
      <Divider />
      <Title level={5}>ENT Practices</Title>
      <Space direction="vertical" size="small">
        {ents.map((ent) => (
          <Text key={ent.entId}>
            <Text>{ent.practiceName}</Text>
          </Text>
        ))}
      </Space>
    </>
  );
};
export default ENTDisplay;
