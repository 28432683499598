import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utilities/axios";
import BrandCard from "./ClinicComponents/BrandCard";
import ClinicalInfoCard from "./ClinicComponents/ClinicalInfoCard";
import EmployeesCard from "./ClinicComponents/EmployeesCard";
import Footer from "components/Footer";
import LocationCard from "./ClinicComponents/LocationCard";
import PhotosCard from "./ClinicComponents/PhotosCard";
import PropertyInformationCard from "./ClinicComponents/PropertyInformationCard";
import ClinicHoursCard from "./ClinicComponents/ClinicHoursCard";
import ContactInfoCard from "./ClinicComponents/ContactInfoCard";
import CorporateInformationCard from "./ClinicComponents/CorporateInformationCard";
import RegionCard from "./ClinicComponents/RegionCard";
import DivisionCard from "./ClinicComponents/DivisionCard";
import ENTsCard from "./ClinicComponents/ENTsCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicData } from "utilities/redux/slices/clinicSlice";
import { Spin } from "antd";

const ClinicDetails = () => {
  const dispatch = useDispatch();

  const clinic = useSelector((state) => state.selectedClinic.clinicData);
  const clinicStatus = useSelector((state) => state.selectedClinic.status);
  const brand = useMemo(() => clinic?.brands, [clinic?.brands]);
  const employees = useMemo(() => clinic?.employees, [clinic?.employees]);

  //list variable of employees
  let { clinicNo } = useParams();

  const getClinicDetails = useCallback(() => {
    dispatch(fetchClinicData(clinicNo));
  }, [clinicNo, dispatch]);

  if (!clinic || clinicStatus === "loading") {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen w-full">
      <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-6 2xl:grid-cols-12 gap-4">
          {/* First Column */}
          <div className="col-span-1 md:col-span-3 2xl:col-span-5 order-1 md:order-1 2xl:order-1">
            <div className="mb-4 md:hidden 2xl:hidden">
              <ClinicHoursCard
                clinicHours={clinic.clinicHours}
                hoursNotes={clinic.hoursNotes}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 md:hidden 2xl:hidden">
              <ContactInfoCard
                contactInfo={clinic.contact}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4">
              <LocationCard
                location={clinic.location}
                clinicNo={clinic.clinicNo}
                clinicName={clinic.name}
              />
            </div>
            <div className="mb-4 2xl:hidden">
              <ClinicalInfoCard
                clinicInfo={clinic.clinicalInformation}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 2xl:hidden">
              <BrandCard
                brand={brand}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 md:hidden">
              <EmployeesCard employees={employees} />
            </div>
            <div className="mb-4 md:hidden">
              <RegionCard region={clinic.region} />
            </div>
            <div className="mb-4 md:hidden">
              <DivisionCard division={clinic.division} />
            </div>
            <div className="mb-4 md:hidden">
              <ENTsCard clinicData={clinic} />
            </div>
            <div className="mb-4">
              <PhotosCard clinicNo={clinic.clinicNo} />
            </div>
            <div className="mb-4 2xl:hidden">
              <CorporateInformationCard
                code={clinic.code}
                openingDate={clinic.openingDate}
                reopeningDate={clinic.reopeningDate}
                closingDate={clinic.closingDate}
                acquisitionDate={clinic.acquisitionDate}
                homeClinicNumber={clinic.homeClinicNo}
                clinicNo={clinic.clinicNo}
                bpSystem={clinic.blueprintSystem}
                bpLocationId={clinic.blueprintLocationId}
                onChange={getClinicDetails}
              />
            </div>
          </div>
          {/* Second Column */}
          <div className="col-span-1 md:col-span-3 2xl:col-span-3 order-2 md:order-2 2xl:order-2 md:block hidden">
            <div className="mb-4 md:block 2xl:block">
              <ClinicHoursCard
                clinicHours={clinic.clinicHours}
                hoursNotes={clinic.hoursNotes}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 md:block 2xl:block">
              <ContactInfoCard
                contactInfo={clinic.contact}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 md:hidden 2xl:block">
              <ClinicalInfoCard
                clinicInfo={clinic.clinicalInformation}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 md:hidden 2xl:block">
              <CorporateInformationCard
                code={clinic.code}
                openingDate={clinic.openingDate}
                reopeningDate={clinic.reopeningDate}
                closingDate={clinic.closingDate}
                acquisitionDate={clinic.acquisitionDate}
                homeClinicNumber={clinic.homeClinicNo}
                clinicNo={clinic.clinicNo}
                bpSystem={clinic.blueprintSystem}
                bpLocationId={clinic.blueprintLocationId}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 md:hidden 2xl:block">
              <PropertyInformationCard
                propertyInfo={clinic.propertyInformation}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4 2xl:hidden">
              <EmployeesCard employees={employees} />
            </div>
            <div className="mb-4 2xl:hidden">
              <RegionCard region={clinic.region} />
            </div>
            <div className="mb-4 2xl:hidden">
              <DivisionCard division={clinic.division} />
            </div>
            <div className="mb-4 2xl:hidden">
              <ENTsCard clinicData={clinic} />
            </div>
            <div className="mb-4 md:block 2xl:hidden">
              <PropertyInformationCard
                propertyInfo={clinic.propertyInformation}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
          </div>
          {/* Third Column */}
          <div className="col-span-1 2xl:col-span-4 order-3 md:order-3 2xl:order-3 2xl:block hidden">
            <div className="mb-4">
              <BrandCard
                brand={brand}
                clinicNo={clinic.clinicNo}
                onChange={getClinicDetails}
              />
            </div>
            <div className="mb-4">
              <EmployeesCard employees={employees} />
            </div>
            <div className="mb-4">
              <RegionCard region={clinic.region} />
            </div>
            <div className="mb-4">
              <DivisionCard division={clinic.division} />
            </div>
            <div className="mb-4">
              <ENTsCard clinicData={clinic} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicDetails;
