import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";
import formatDate from "utilities/formats/formatDate";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Table,
  Tooltip,
  Checkbox,
  Select,
  Modal,
  Row,
  Col,
  Card,
  Descriptions,
  Typography,
} from "antd";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";

import AddEquipmentModal from "./components/AddEquipmentModal";
import ViewEquipmentModal from "./components/ViewEquipmentModal";

const { Text, Title } = Typography;
function ClinicEquipment() {
  const [equipmentData, setEquipmentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [requestData, setRequestData] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [detailModalData, setDetailModalData] = useState(null); // This will hold the selected equipment's data for details modal

  const { clinic_no } = useParams();
  const { hasPermission } = useRoleCheck();

  const fetchEquipmentData = () => {
    axiosInstance
      .get(`/equipment?clinic_no=${clinic_no}`)
      .then((result) => {
        const filteredResults = result.data.filter(
          (item) => item.condition === "Working" && item.status === "In Use"
        );
        setEquipmentData(result.data);
        setFilteredData(filteredResults);
      })
      .catch((err) => {
        console.error("Error fetching equipment data:", err);
      });
  };

  const fetchSummaryData = () => {
    // Assuming an endpoint for the summary data
    axiosInstance
      .get(`/equipment/summary?clinic_no=${clinic_no}`)
      .then((result) => {
        setSummaryData(result.data);
      })
      .catch((err) => {
        console.error("Error fetching summary data:", err);
      });
  };

  const updateEquipmentList = () => {
    fetchEquipmentData();
    fetchSummaryData();
  };

  useEffect(() => {
    const fetchRequestData = async () => {
      try {
        const result = await axiosInstance.get(
          `/equipment/requests?clinic_no=${clinic_no}`
        );
        setRequestData(result.data);
      } catch (error) {
        console.error("Error fetching equipment request data:", error);
      }
    };

    fetchEquipmentData();
    fetchSummaryData();
    fetchRequestData();
  }, [clinic_no]);

  const filterData = (working, inUse, category) => {
    let newFilteredData = [...equipmentData];

    if (working) {
      newFilteredData = newFilteredData.filter(
        (e) => e.condition === "Working"
      );
    }
    if (inUse) {
      newFilteredData = newFilteredData.filter((e) => e.status === "In Use");
    }
    if (category) {
      newFilteredData = newFilteredData.filter((e) => e.category === category);
    }

    setFilteredData(newFilteredData);
  };
  const handleCheckboxChange = (checkedValues) => {
    const working = checkedValues.includes("Working");
    const inUse = checkedValues.includes("In Use");
    filterData(working, inUse);
  };

  const handleCategoryChange = (selectedCategories) => {
    if (!selectedCategories.length) {
      // If no categories are selected, show all items
      setFilteredData([...equipmentData]);
      return;
    }

    const newFilteredData = equipmentData.filter((item) =>
      selectedCategories.includes(item.category)
    );
    setFilteredData(newFilteredData);
  };
  const columns = [
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "Model", dataIndex: "model", key: "model" },
    { title: "Category", dataIndex: "category", key: "category" },
    {
      title: "Last Modified",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      render: (text) => formatDate(text, "short"),
      responsive: ["lg"],
    },
    { title: "Condition", dataIndex: "condition", key: "condition" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <Button type="primary" onClick={() => setDetailModalData(record)}>
          Details
        </Button>
      ),
    },
  ];
  const requestColumns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Requested By", dataIndex: "requestedBy", key: "requestedBy" },
    {
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text) => formatDate(text, "short"),
    },
    { title: "Equipment ID", dataIndex: "equipmentId", key: "equipmentId" },
    {
      title: "Equipment Item ID",
      dataIndex: "equipmentItemId",
      key: "equipmentItemId",
    },
    { title: "Request Type", dataIndex: "requestType", key: "requestType" },
    {
      title: "Estimated Price",
      dataIndex: "estimatedPrice",
      key: "estimatedPrice",
    },
    { title: "Comments", dataIndex: "comments", key: "comments" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Approved By", dataIndex: "approvedBy", key: "approvedBy" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "Model", dataIndex: "model", key: "model" },
  ];

  const uniqueCategories = Array.from(
    new Set(equipmentData.map((item) => item.category))
  );
  return (
    <div>
      <Row gutter={24}>
        {" "}
        {/* This gives a gap between the columns */}
        <Col span={18}>
          <div className="mb-4 flex justify-between items-center">
            {hasPermission(
              [],
              ["Sales.RM", "Sales.Staff"],
              clinic_no,
              false
            ) && (
              <Tooltip title="Add Items">
                <Button
                  icon={<PlusOutlined />}
                  className="mr-2"
                  onClick={() => setShowAddModal(true)}
                >
                  Add
                </Button>
              </Tooltip>
            )}

            <div className="flex items-center">
              <Checkbox.Group
                style={{ marginRight: "20px" }}
                onChange={handleCheckboxChange}
                defaultValue={["Working", "In Use"]}
              >
                <Checkbox value="Working">Working</Checkbox>
                <Checkbox value="In Use">In Use</Checkbox>
              </Checkbox.Group>

              <Select
                placeholder="Select Category"
                onChange={handleCategoryChange}
                mode="multiple"
                className="w-48"
                allowClear
              >
                {uniqueCategories.map((cat) => (
                  <Select.Option key={cat} value={cat}>
                    {cat}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          {/* Equipment Table */}
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 10 }}
            filterData
            sticky={{ offsetHeader: -20 }}
            size={"small"}
          />
        </Col>
        <Col span={6}>
          {/* Equipment Summary */}
          <Card
            title={
              <div className="flex justify-between items-center">
                <span>Equipment Summary</span>
                <Tooltip title="This includes only equipment that's 'In Use' and 'Working'">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            bordered={false}
            bodyStyle={{ padding: 0 }}
          >
            <Descriptions column={1} bordered className="rounded-t-none">
              {Object.entries(summaryData).map(([category, count]) => (
                <Descriptions.Item key={category} label={category}>
                  {count}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </Col>
      </Row>
      {/* Equipment Requests */}
      {hasPermission(
        ["Sales.RM", "Sales.Exec"],
        ["Sales.Staff"],
        clinic_no
      ) && (
        <>
          <Title level={4}>Equipment Requests</Title>
          <Table
            columns={requestColumns}
            dataSource={requestData}
            pagination={{ pageSize: 10 }}
            sticky={true}
            size={"small"}
          />
          {/* Add Modal */}
          <AddEquipmentModal
            isVisible={showAddModal}
            onAdd={updateEquipmentList}
            onClose={() => setShowAddModal(false)}
          />
        </>
      )}

      {/* Request Modal */}
      <Modal
        title="Request Equipment"
        open={showRequestModal}
        onCancel={() => setShowRequestModal(false)}
        footer={null}
      >
        {/* Content for requesting equipment (you can fill this in later) */}
      </Modal>
      {/* Detail Modal */}
      {detailModalData && (
        <ViewEquipmentModal
          equipment={detailModalData}
          onClose={() => setDetailModalData(null)}
          updateEquipment={updateEquipmentList}
          clinicNo={clinic_no}
        />
      )}
    </div>
  );
}

export default ClinicEquipment;
