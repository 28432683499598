import { React, useState, useEffect } from "react";
import { Button, Table, Input, Segmented, Spin, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { WarningOutlined } from "@ant-design/icons";
import { fetchBrands } from "utilities/redux/slices/brandsSlice";
import useRoleCheck from "utilities/hooks/roleCheck";
import BrandDisplayModal from "./components/BrandDisplayModal";
import BrandAddModal from "./components/BrandAddModal";
import ForbiddenScreen from "../../pages/403";
import Footer from "components/Footer";

const Brands = () => {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const brands = useSelector((state) => state.brands.data);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("active");

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [openDisplayModal, setOpenDisplayModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const pageView = hasPermission(["Marketing"]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "brandName",
      width: 450,
      sorter: (a, b) => a.brandName.localeCompare(b.brandName),
    },
    {
      title: "Description",
      dataIndex: "brandDescription",
    },
    {
      title: "Clinics",
      dataIndex: "clinicCount",
      sorter: (a, b) => a.clinicCount - b.clinicCount,
      width: 50,
    },
    {
      title: "Detail",
      dataIndex: "",
      width: 100,
      key: "x",
      render: (text, record) => (
        <Button
          onClick={() => {
            setSelectedBrand(record.brandId);
            setOpenDisplayModal(true);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  //get page content
  useEffect(() => {
    dispatch(fetchBrands({ search, status: status }));
  }, [dispatch, search, status]);
  if (!pageView) {
    return <ForbiddenScreen />;
  }

  return (
    <>
      <div className="p-8">
        <div className="flex">
          <Input.Search
            placeholder="Search Brands"
            allowClear
            size="large"
            suffix="" // Remove the search icon
            onChange={handleSearch}
            className="mr-2"
          />
          {hasPermission(["Marketing.Write"], [], null, false) && (
            <Button
              type="primary"
              size="large"
              onClick={() => setOpenAddModal(true)}
            >
              Add Brand
            </Button>
          )}
          <Segmented
            defaultValue="active"
            size="large"
            className="mx-2"
            onChange={(value) => {
              if (value === "all") {
                setStatus(null);
              } else {
                setStatus(value);
              }
            }}
            options={[
              { label: "Active", value: "active" },
              { label: "All", value: "all" },
            ]}
          />
        </div>

        <div className="mt-4">
          <Table columns={columns} dataSource={brands} rowKey="brandId" />
          <Footer />
        </div>
      </div>
      <BrandDisplayModal
        brandId={selectedBrand}
        open={openDisplayModal}
        onClose={() => setOpenDisplayModal(false)}
      />
      <BrandAddModal
        open={openAddModal}
        onClose={(brand_id) => {
          setOpenAddModal(false);
          dispatch(fetchBrands({ search, status: status }));
          if (brand_id) {
            setSelectedBrand(brand_id);
            setOpenDisplayModal(true);
          }
        }}
      />
    </>
  );
};

export default Brands;
