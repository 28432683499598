import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, notification, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { fetchBrands } from "utilities/redux/slices/brandsSlice";
import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";

import BrandDisplay from "./BrandDisplayContent";
import BrandEdit from "./BrandEdit";

const BrandDisplayModal = ({ brandId, open, onClose }) => {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();
  const [brand, setBrand] = useState(null);
  const brands = useSelector((state) => state.brands.data);
  const [editMode, setEditMode] = useState(false);

  const fetchBrand = useCallback(() => {
    if (!brandId) {
      return;
    }
    axiosInstance
      .get(`/brands/${brandId}`)
      .then((response) => {
        setBrand({ ...response.data, url: undefined });
        form.setFieldsValue(response.data);

        // Fetch logo URL separately
        axiosInstance.get(`/brands/${brandId}/logo`).then((logo) => {
          // Update the brand state with the logo URL
          setBrand((prevBrand) => ({ ...prevBrand, url: logo.data.url }));
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  }, [form, brandId]);

  useEffect(() => {
    fetchBrand();
  }, [fetchBrand]);

  const editBrand = (values) => {
    const formData = new FormData();
    if (values.brandName != null) {
      formData.append("brandName", values.brandName);
    }

    if (values.brandDescription != null) {
      formData.append("brandDescription", values.brandDescription);
    }

    // Assuming brandLogo is a file, check if it's not null and is a File object
    if (values.brandLogo && values.brandLogo instanceof File) {
      formData.append("brandLogo", values.brandLogo);
    }
    axiosInstance
      .patch(`/brands/${brandId}`, formData)
      .then((response) => {
        dispatch(fetchBrands({}));
        form.resetFields();
        fetchBrand();
        setEditMode(false);
        notification.success({
          message: "Success",
          description: "Brand updated successfully",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
    if (!values.brandLogo && brand.url) {
      //delete logo
      axiosInstance
        .delete(`/brands/${brandId}/logo`)
        .then((response) => {
          fetchBrand();
          notification.success({
            message: "Success",
            description: "Logo deleted successfully",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.message,
          });
        });
    }
  };
  if (!brand) {
    return null;
  }
  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        form.resetFields();
        setEditMode(false);
      }}
      size="large"
      footer={
        <>
          {!editMode ? (
            <>
              <Button
                onClick={() => {
                  onClose();
                  form.resetFields();
                  setEditMode(false);
                }}
              >
                Close
              </Button>
              {hasPermission(["Marketing.Write"], [], null, false) && (
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  form.resetFields();
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={form.submit}>Save</Button>
            </>
          )}
        </>
      }
    >
      {!editMode && <BrandDisplay brand={brand} />}
      {editMode && <BrandEdit brand={brand} form={form} onSubmit={editBrand} />}
    </Modal>
  );
};

export default BrandDisplayModal;
