import React, { useState } from "react";
import {
  Modal,
  Descriptions,
  Button,
  Checkbox,
  notification,
  Row,
  Col,
  Upload,
  DatePicker,
  Form,
} from "antd";
import {
  SwapOutlined,
  CalendarOutlined,
  ToolOutlined,
  ProfileOutlined,
  BarcodeOutlined,
  DownloadOutlined,
  TagOutlined,
  NumberOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";
import formatDate from "utilities/formats/formatDate";

function ViewEquipmentModal({ equipment, onClose, updateEquipment, clinicNo }) {
  const { hasPermission } = useRoleCheck();
  const [condition, setCondition] = useState(equipment.condition === "Working");
  const [status, setStatus] = useState(equipment.status === "In Use");
  const [prevStatus, setPrevStatus] = useState(status); // Store the previous status to restore later

  const [calibrationModalVisible, setCalibrationModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [calibrationDate, setCalibrationDate] = useState(null);
  const [calibrationSavedLoading, setCalibrationSavedLoading] = useState(false);

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleDelete = () => {
    // Actual delete logic here
    axiosInstance
      .delete(`/equipment/${equipment.id}`)
      .then((response) => {
        notification.success({
          message: "Information",
          description: "Equipment item deleted",
        });
        updateEquipment();
        setDeleteModalVisible(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to delete equipment item",
        });
      });
    setDeleteModalVisible(false);
    updateEquipment();
    onClose();
  };

  const handleCalibrationSubmit = () => {
    setCalibrationSavedLoading(true);
    const formData = new FormData();

    formData.append("certificate", fileList[0]);
    formData.append("date", calibrationDate.format("YYYY-MM-DD"));

    axiosInstance
      .post(`/equipment/${equipment.id}/certificate`, formData)
      .then((response) => {
        notification.success({
          message: "Success",
          description: "Calibration certificate uploaded successfully!",
        });
        updateEquipment();
        setCalibrationModalVisible(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to upload calibration certificate!",
        });
      })
      .finally(() => {
        setCalibrationSavedLoading(false);
      });
  };
  const handleConditionChange = (checked) => {
    setCondition(checked);
    if (!checked) {
      setPrevStatus(status); // remember the previous status
      setStatus(false); // turn off "in use"
    } else {
      setStatus(prevStatus); // restore previous status
    }

    // SendaxiosInstance request here...
    axiosInstance
      .patch(`/equipment/${equipment.id}`, {
        condition: checked ? "Working" : "Not Working",
        status: checked ? (prevStatus ? "In Use" : "Not In Use") : "Not In Use",
      })
      .then((response) => {
        // Success
        updateEquipment();
        notification.success({
          message: "Success",
          description: "Equipment updated successfully!",
        });
      })
      .catch((error) => {
        // Handle error
        notification.error({
          message: "Error",
          description: "Failed to update equipment!",
        });
      });
  };

  const handleStatusChange = (checked) => {
    setStatus(checked);

    // SendaxiosInstance request here...
    axiosInstance
      .patch(`/equipment/${equipment.id}`, {
        status: checked ? "In Use" : "Not In Use",
      })
      .then((response) => {
        // Success
        updateEquipment();
        notification.success({
          message: "Success",
          description: "Equipment status updated successfully!",
        });
      })
      .catch((error) => {
        // Handle error
        notification.error({
          message: "Error",
          description: "Failed to update equipment status!",
        });
      });
  };

  return (
    <Modal
      title="Equipment Details"
      open={Boolean(equipment)}
      onCancel={onClose}
      width={800} // Increased width
      footer={[
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col>
            <Checkbox
              checked={condition}
              onChange={(e) => handleConditionChange(e.target.checked)}
              disabled={
                !hasPermission([], ["Sales.Staff", "Sales.RM"], clinicNo, false)
              }
            >
              Working
            </Checkbox>
            <Checkbox
              checked={status}
              onChange={(e) => handleStatusChange(e.target.checked)}
              disabled={
                !condition ||
                !hasPermission([], ["Sales.Staff", "Sales.RM"], clinicNo, false)
              }
            >
              In Use
            </Checkbox>
            <Checkbox
              checked={status}
              onChange={(e) => handleStatusChange(e.target.checked)}
              disabled={
                !condition ||
                !hasPermission([], ["Sales.Staff", "Sales.RM"], clinicNo, false)
              }
            >
              In Use
            </Checkbox>
          </Col>
          <Col>
            {!condition && (
              <Button
                key="requestRepair"
                type="dashed"
                ghost
                danger
                icon={<ToolOutlined />}
                onClick={() => {
                  /* Handle repair request logic */
                }}
                disabled
              >
                Request Repair
              </Button>
            )}
            {equipment.category === "Audiometer" &&
              hasPermission(
                [],
                ["Sales.Staff", "Sales.RM"],
                clinicNo,
                false
              ) && (
                <Button
                  key="addCalibration"
                  icon={<UploadOutlined />}
                  onClick={() => setCalibrationModalVisible(true)}
                  disabled
                >
                  Add Calibration
                </Button>
              )}
            {hasPermission(["Sales.RM"], [], null, false) && (
              <Button
                key="transfer"
                icon={<SwapOutlined />}
                onClick={() => {
                  /* Handle transfer logic */
                }}
                disabled
              >
                Transfer
              </Button>
            )}
            {hasPermission([], ["Sales.RM"], clinicNo, false) && (
              <Button
                key="delete"
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => setDeleteModalVisible(true)}
              />
            )}
          </Col>
        </Row>,
      ]}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item
          label={
            <>
              <TagOutlined /> Brand
            </>
          }
        >
          {equipment.brand}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <BarcodeOutlined /> Model
            </>
          }
        >
          {equipment.model}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <ProfileOutlined /> Description
            </>
          }
        >
          {equipment.description}
        </Descriptions.Item>
        <Descriptions.Item label={<>Category</>}>
          {equipment.category}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions bordered column={2}>
        <Descriptions.Item
          span={2}
          label={
            <>
              <NumberOutlined /> Serial No
            </>
          }
        >
          {equipment.serialNo}
        </Descriptions.Item>
        {equipment.category === "Audiometer" && (
          <>
            <Descriptions.Item
              label={
                <>
                  <CalendarOutlined /> Calibration Date
                </>
              }
            >
              {formatDate(equipment.calibrationDate)}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <>
                  <DownloadOutlined /> Certificate
                </>
              }
            >
              <a
                href={equipment.link_to_certificate}
                target="_blank"
                rel="noopener noreferrer"
                disabled
              >
                Download Certificate
              </a>
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label="Last Modified">
          {formatDate(equipment.modifiedDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Modified By">
          {equipment.modifiedByName} ({equipment.modifiedByInitials})
        </Descriptions.Item>

        <Descriptions.Item label="Purchase Date">
          {formatDate(equipment.purchaseDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Added Date">
          {formatDate(equipment.dateAdded)}
        </Descriptions.Item>

        <Descriptions.Item label="Notes" span={2}>
          {equipment.notes}
        </Descriptions.Item>
      </Descriptions>
      <Modal
        title="Add Calibration"
        open={calibrationModalVisible}
        onCancel={() => setCalibrationModalVisible(false)}
        okText="Save"
        //override the loading of the ok button
        okButtonProps={{ loading: calibrationSavedLoading }}
        onOk={handleCalibrationSubmit}
      >
        <Form layout="vertical">
          <Form.Item label="Upload Certificate">
            <Upload.Dragger
              name="certificate"
              fileList={fileList}
              beforeUpload={(file) => {
                setFileList([file]);
                return false;
              }}
              onRemove={() => setFileList([])}
              multiple={false}
            >
              <p className="ant-upload-text">
                Drag & drop certificate or click to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item label="Calibration Date">
            <DatePicker onChange={setCalibrationDate} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
        okText="Delete"
        okButtonProps={{ danger: true, type: "primary" }}
        cancelText="Cancel"
      >
        <p>
          <strong>Warning:</strong> This action cannot be undone. Are you sure
          you want to delete this item?
        </p>
      </Modal>
    </Modal>
  );
}

export default ViewEquipmentModal;
