import React, { useState } from "react";
import { Form, Input, Button, Card, Typography, Select, Checkbox } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;
const { Item } = Form;
const FieldItem = ({ field, remove }) => {
  const [isSecret, setIsSecret] = useState(false);

  return (
    <Card className="mb-2 p-1" bodyStyle={{ padding: "0.5rem" }}>
      <Text type="secondary">Field Name</Text>
      <Item
        className="mb-1"
        name={[field.name, "fieldName"]}
        rules={[
          {
            required: true,
            message: "Please enter field name",
          },
        ]}
      >
        <Input placeholder="Field Name" />
      </Item>
      <Typography.Text type="secondary">Field Description</Typography.Text>
      <Item className="mb-1" name={[field.name, "fieldDescription"]}>
        <Input placeholder="Field Description" />
      </Item>
      <Text type="secondary">Field Value</Text>
      <Item
        className="mb-1"
        name={[field.name, "fieldValue"]}
        rules={[{ required: true, message: "Please enter field value" }]}
      >
        {isSecret ? (
          <Input.Password placeholder="Field Value" />
        ) : (
          <Input placeholder="Field Value" />
        )}
      </Item>
      <div className="float-right flex space-x-2">
        <Text type="secondary">Type </Text>
        <Item className="mb-1" name={[field.name, "fieldType"]}>
          <Select
            defaultValue="text"
            style={{ width: 120 }}
            onChange={(value) => setIsSecret(value === "password")}
          >
            <Option value="text">Text</Option>
            <Option value="password">Password</Option>
          </Select>
        </Item>
      </div>
      <Item
        className="mb-1"
        name={[field.name, "isPrimary"]}
        valuePropName="checked"
      >
        <Checkbox>Is Primary</Checkbox>
      </Item>

      <Button
        onClick={() => remove(field.name)}
        icon={<MinusCircleOutlined />}
        type="danger"
      >
        Remove
      </Button>
    </Card>
  );
};

export default FieldItem;
