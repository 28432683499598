import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, notification, Button } from "antd";
import { useDispatch } from "react-redux";

import { fetchVendors } from "utilities/redux/slices/vendorsSlice";
import axiosInstance from "utilities/axios";

import VendorEdit from "./VendorEdit";

const VendorAddModal = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const addVendor = (values) => {
    axiosInstance
      .post(`/vendors`, values)
      .then((response) => {
        dispatch(fetchVendors());
        form.resetFields();
        notification.success({
          message: "Success",
          description: "Vendor created successfully",
        });
        onClose();
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  };
  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      size="large"
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
              form.resetFields();
            }}
          >
            Cancel
          </Button>

          <Button onClick={form.submit}>Create</Button>
        </>
      }
    >
      <VendorEdit form={form} onSubmit={addVendor} />
    </Modal>
  );
};

export default VendorAddModal;
