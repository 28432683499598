import React from "react";
import { Space, Typography, Divider } from "antd";
import {
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  PrinterOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import formatPhoneNumber from "utilities/formats/formatPhone";
import buildAddress from "utilities/formats/buildAddress";

const { Text, Link } = Typography;

const VendorDisplay = ({ vendor }) => {
  const address = buildAddress(vendor);

  //if there is no address, phone, fax, email, or portalUrl, don't display anything
  if (
    !address &&
    !vendor.phone &&
    !vendor.fax &&
    !vendor.email &&
    !vendor.portalUrl
  )
    return null;
  return (
    <>
      <Space direction="vertical" size="small">
        {address && (
          <Text>
            <HomeOutlined /> <strong>Address:</strong> {address}
          </Text>
        )}
        {vendor.phone && (
          <Text>
            <PhoneOutlined /> <strong>Phone:</strong>{" "}
            {formatPhoneNumber(vendor.phone)}
          </Text>
        )}
        {vendor.fax && (
          <Text>
            <PrinterOutlined /> <strong>Fax:</strong>{" "}
            {formatPhoneNumber(vendor.fax)}
          </Text>
        )}
        {vendor.email && (
          <Text>
            <MailOutlined /> <strong>Email:</strong> {vendor.email}
          </Text>
        )}
        {vendor.portalUrl && (
          <Link href={vendor.portalUrl} target="_blank">
            <LinkOutlined /> Portal URL
          </Link>
        )}
      </Space>
      <Divider />
    </>
  );
};

export default VendorDisplay;
