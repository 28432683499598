// src/redux/slices/brandsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching insurer data
export const fetchBrands = createAsyncThunk(
  "brands/fetchbrands",
  async ({ search, status }) => {
    const params = {};
    if (search) {
      params.search = search;
    }
    if (status) {
      params.status = status;
    }
    const response = await axiosInstance.get("/brands", { params: params });
    return response.data;
  }
);

const brandsSlice = createSlice({
  name: "brands",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // Changed from state.clinics to state.data
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default brandsSlice.reducer;
