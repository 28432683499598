// useTheme.js
import { useState } from "react";

export const useTheme = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // You can add any logic here to initialize the theme based on user preferences or system settings

  return { isDarkMode, setIsDarkMode };
};
