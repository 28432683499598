import React, { useEffect, useState } from "react";
import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utilities/axios";

function RegionCard({ region }) {
  const { Meta } = Card;
  const [rmPhotoUrl, setRMPhotoUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (region && region.rm) {
      axiosInstance
        .get(`employees/${region.rm.initials}/photo`, { responseType: "blob" })
        .then((photoResponse) => {
          if (photoResponse.status !== 204) {
            const photoUrl = URL.createObjectURL(photoResponse.data);
            setRMPhotoUrl(photoUrl);
          }
        });
    }
  }, [region]);
  return (
    <>
      {region && (
        <Card
          className="mb-2 shadow-lg"
          size={"small"}
          style={{
            minWidth: 350,
            width: "100%",
          }}
          bodyStyle={{ padding: 0 }}
          title={
            <div
            // onClick={() => navigate(`/regions/${region.code}`)}
            >
              <small>Region</small>
              <h2 className="my-1">{region.description}</h2>
            </div>
          }
        >
          <Card
            onClick={() => navigate(`/employees/${region.rm.initials}`)}
            bordered={false}
            size={"small"}
            className="rounded-t-none"
            bodyStyle={{
              width: "300px",
              padding: 0,
            }}
          >
            <Meta
              avatar={
                <Avatar
                  size={72}
                  src={rmPhotoUrl}
                  icon={!rmPhotoUrl && <UserOutlined />}
                  alt={region.rm.name}
                />
              }
              title={region.rm.name}
              //stying to remove the spacing between the title and description
              style={{
                margin: "0px",
                padding: "0px",
                lineHeight: "1",
                borderRadius: 0,
              }}
              description={
                //Title on one line then email on the next
                <div className="flex flex-col space-y-1">
                  <span>{"Regional Manager"}</span>
                  <small>{region.rm.email}</small>
                </div>
              }
            ></Meta>
          </Card>
        </Card>
      )}
    </>
  );
}
export default RegionCard;
