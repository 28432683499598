import React from "react";
import { Space, Typography } from "antd";
import {
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  PrinterOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import { buildAddress, formatPhoneNumber } from "utilities/formats";

const { Text, Link } = Typography;

const InsurerDisplay = ({ insurer }) => {
  return (
    <>
      <Space direction="vertical" size="small">
        {insurer.insurerType && (
          <Text>
            <Text strong>Type:</Text> {insurer.insurerType}
          </Text>
        )}
        {insurer.insurerGroup && (
          <Text>
            <Text strong>Group:</Text> {insurer.insurerGroup}
          </Text>
        )}
        {insurer.insurerSimpleType && (
          <Text>
            <Text strong>Simple Type:</Text> {insurer.insurerSimpleType}
          </Text>
        )}
      </Space>
    </>
  );
};
const VendorDisplay = ({ vendor }) => {
  const address = buildAddress(vendor.address);
  return (
    <>
      <Space direction="vertical" size="small">
        {address && (
          <Text>
            <HomeOutlined /> <strong>Address:</strong> {address}
          </Text>
        )}
        {vendor.phone && (
          <Text>
            <PhoneOutlined /> <strong>Phone:</strong>{" "}
            {formatPhoneNumber(vendor.phone)}
          </Text>
        )}
        {vendor.fax && (
          <Text>
            <PrinterOutlined /> <strong>Fax:</strong>{" "}
            {formatPhoneNumber(vendor.fax)}
          </Text>
        )}
        {vendor.email && (
          <Text>
            <MailOutlined /> <strong>Email:</strong> {vendor.email}
          </Text>
        )}
        {vendor.portalUrl && (
          <Link href={vendor.portalUrl} target="_blank">
            <LinkOutlined /> Portal URL
          </Link>
        )}
        {vendor.contact && (
          <Text>
            <strong>Contact:</strong> {vendor.contact}
          </Text>
        )}
        {vendor.insurerDetails && (
          <InsurerDisplay insurer={vendor.insurerDetails} />
        )}
      </Space>
    </>
  );
};

export default VendorDisplay;
