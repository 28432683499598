import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, notification, Button, Skeleton } from "antd";
import { useDispatch } from "react-redux";

import { fetchVendors } from "utilities/redux/slices/vendorsSlice";
import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";
import VendorDisplay from "./VendorDisplayContent";
import VendorEdit from "./VendorEdit";

const VendorDisplayModal = ({ vendorId, open, onClose }) => {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();
  const [vendor, setVendor] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchVendor = useCallback(() => {
    if (!vendorId) {
      return;
    }
    setIsLoading(true);
    setVendor(null);
    axiosInstance
      .get(`/vendors/${vendorId}`)
      .then((response) => {
        setVendor(response.data);
        form.setFieldsValue(response.data);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [form, vendorId]);

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor]);

  const editVendor = (values) => {
    axiosInstance
      .patch(`/vendors/${vendorId}`, values)
      .then((response) => {
        dispatch(fetchVendors());
        form.resetFields();
        fetchVendor();
        setEditMode(false);
        notification.success({
          message: "Success",
          description: "Vendor updated successfully",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  };
  if (!vendor) {
    return null;
  }
  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        form.resetFields();
        setEditMode(false);
      }}
      title={vendor?.vendorName}
      size="large"
      footer={
        <>
          {!isLoading && (
            <>
              {!editMode ? (
                <>
                  <Button
                    onClick={() => {
                      onClose();
                      form.resetFields();
                      setEditMode(false);
                    }}
                  >
                    Close
                  </Button>
                  {((hasPermission(["IT.Write"], [], null, false) &&
                    vendor.categoryName === "IT") ||
                    (hasPermission(["Operations.Write"], [], null, false) &&
                      vendor.categoryName === "Insurer") ||
                    (hasPermission(
                      [
                        "Marketing.Write",
                        "Operations.Write",
                        "Sales.RM",
                        "Admin.Basic",
                      ],
                      [],
                      null,
                      false
                    ) &&
                      vendor.categoryName === "Other")) && (
                    <Button onClick={() => setEditMode(true)}>Edit</Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      form.resetFields();
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={form.submit}>Save</Button>
                </>
              )}
            </>
          )}
        </>
      }
    >
      {isLoading || !vendor ? (
        <Skeleton active />
      ) : (
        <>
          {!editMode ? (
            <VendorDisplay vendor={vendor} />
          ) : (
            <VendorEdit vendor={vendor} form={form} onSubmit={editVendor} />
          )}
        </>
      )}
    </Modal>
  );
};

export default VendorDisplayModal;
