import axios from "axios";
import qs from "qs";

// Create a custom instance of axios
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  withCredentials: true,

  paramsSerializer: (params) => {
    const queryString = qs.stringify(params, {
      arrayFormat: "repeat",
      skipNulls: true,
    });

    return queryString;
  },
});

//debug interceptor to view request with url
axiosInstance.interceptors.request.use(
  function (config) {
    //remove trailing slash if params are present
    //view entire request
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    //log headers and response
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function (error) {
    if (error.response) {
      const { status, data } = error.response;

      // If the response status is 401 (Unauthorized), redirect to the login page
      if (status === 401) {
        window.location = "/login";
      }

      // Update error.message to include status code and message from the API
      if (data && data.message) {
        error.message = `Error ${status}: ${data.message}`;
      } else {
        error.message = `Error ${status}: An error occurred`;
      }
    } else {
      // If there is no response, set a generic error message
      error.message = "Error: Network error or no response received";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
