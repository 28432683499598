import React, { useEffect } from "react";
import { Button, Typography } from "antd";

const { Title } = Typography;

const LoginPage = () => {
  useEffect(() => {
    document.title = "Login";
  }, []);

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/v1/login`;
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-no-repeat bg-cover bg-center"
      style={{
        background: `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,0)), url('/dots.svg')`, // Adjust colors as needed
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="bg-white text-center">
        <Title level={1}>Clinic Central</Title>
        <Button
          onClick={handleLogin}
          type="primary"
          size="large"
          className="text-xl w-11/12"
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
