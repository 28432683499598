import { React, useState, useEffect } from "react";
import { Button, Table, Input, Modal, Select, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosInstance from "utilities/axios";
import Footer from "components/Footer";
import EmployeeDetails from "../EmployeeDetails";
import FilterModal from "./components/FilterModal";

const { Option } = Select;
const { Text } = Typography;

const Employees = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState("");

  const [filters, setFilters] = useState({
    employeeStatus: ["Active"],
    region: [],
    division: [],
  });

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

  const location = useLocation();
  const { user_id } = useParams();
  const handleFilterButtonClick = () => {
    setIsFilterModalVisible(true);
  };

  const handleModalClose = () => {
    setIsFilterModalVisible(false);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const columns = [
    {
      title: "Name",

      //combine first and last name columns
      render: (_, record) => {
        let name = record.firstName;
        if (record.lastName) name += " " + record.lastName;
        return name;
      },
      //sort by last name
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: "Initials",
      dataIndex: "initials",
      responsive: ["md"],
      sorter: (a, b) => a.initials.localeCompare(b.initials),
    },
    {
      title: "Email",
      dataIndex: "email",
      responsive: ["xl"],
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["xl"],
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Manager",
      dataIndex: "managerName",
      responsive: ["lg"],
      sorter: (a, b) => {
        const managerNameA = a.managerName || "";
        const managerNameB = b.managerName || "";
        return managerNameA.localeCompare(managerNameB);
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      responsive: ["md"],
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: "Location",
      dataIndex: "location",
      responsive: ["sm"],
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: "Detail",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Button
          onClick={() => {
            navigate(`/employees/${record.initials}`);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  //get page content
  useEffect(() => {
    axiosInstance
      .get("/employees", {
        params: {
          search: search,
          ...filters,
        },
      })
      .then((response) => {
        setEmployees(response.data);
      });
  }, [search, filters]);

  return (
    <>
      <div className="p-8">
        <div className="flex">
          <Input.Search
            placeholder="Search Employees"
            allowClear
            size="large"
            suffix="" // Remove the search icon
            onChange={handleSearch}
            className="mr-2"
          />
          <Button
            type="primary"
            size="large"
            icon={<FilterOutlined />}
            onClick={handleFilterButtonClick}
          >
            Filter
          </Button>
        </div>

        <div className="mt-4">
          <Table columns={columns} dataSource={employees} rowKey="initials" />
        </div>
      </div>
      <FilterModal
        open={isFilterModalVisible}
        onClose={handleModalClose}
        filters={filters}
        setFilters={setFilters}
      />
      <EmployeeDetails
        visible={location.pathname === `/employees/${user_id}`}
        onClose={() => {
          navigate("/employees");
        }}
        user_id={user_id}
      />
      <Footer />
    </>
  );
};

export default Employees;
