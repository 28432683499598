// src/redux/slices/selectedClinicSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching clinic data
export const fetchClinicData = createAsyncThunk(
  "selectedClinic/fetchClinicData",
  (clinic_no, thunkAPI) => {
    if (!clinic_no) {
      const state = thunkAPI.getState();
      clinic_no = state.selectedClinic.clinicData?.clinicNo;
      if (!clinic_no) {
        return thunkAPI.rejectWithValue(
          "No clinic number provided and none found in state."
        );
      }
    }

    return axiosInstance
      .get(`/clinics/${clinic_no}`)
      .then((response) => {
        for (let i = 0; i < response.data.employees.length; i++) {
          const user_id = response.data.employees[i].initials;
          // fetch employee photo
          const profileUrl = `/employees/${user_id}`;
          // add photo and profile url to employee

          response.data.employees[i].profileUrl = profileUrl;
        }

        //remove reopening date if it is the same as opening date
        if (response.data.reopeningDate === response.data.openingDate) {
          response.data.reopeningDate = null;
        }
        //remove Home Clinic Number if it is the same as clinic number
        if (response.data.homeClinicNo === response.data.clinicNo) {
          response.data.homeClinicNo = null;
        }
        return response.data;
      })
      .catch((error) => {
        // Construct an error object with message and code
        let errorMessage = "An error occurred";
        let errorCode = "Unknown";

        if (error.response) {
          // Axios error response structure
          errorMessage = error.response.data.message || error.message;
          errorCode = error.response.status;
        } else if (error.request) {
          // Request was made but no response received
          errorMessage = "No response received";
        } else {
          // Error setting up the request
          errorMessage = error.message;
        }

        return thunkAPI.rejectWithValue({
          message: errorMessage,
          code: errorCode,
        });
      });
  }
);

const selectedClinicSlice = createSlice({
  name: "selectedClinic",
  initialState: {
    clinicData: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClinicData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchClinicData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clinicData = action.payload;
        state.error = null;
      })
      .addCase(fetchClinicData.rejected, (state, action) => {
        state.status = "failed";

        state.error = {
          message:
            action.payload?.message ||
            action.error.message ||
            "An error occurred",
          code: action.payload?.code || "Unknown", // Use status code from response or a default
        };
        console.log(state.error);
      });
  },
});

export default selectedClinicSlice.reducer;
