import React, { useEffect, useState, useCallback, useMemo } from "react";

import { Outlet, useParams } from "react-router-dom";
import { Layout, Menu, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillInfoCircle, AiOutlineEdit } from "react-icons/ai";
import ClinicUpdateModal from "../pages/ClinicDetails/ClinicComponents/UpdateDetailModal";
import { useDispatch, useSelector } from "react-redux";
import useRoleCheck from "utilities/hooks/roleCheck";
import { fetchClinicData } from "utilities/redux/slices/clinicSlice";
import Footer from "components/Footer";

const { Content } = Layout;

function ClinicLayout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermission } = useRoleCheck();

  const { Title } = Typography;
  const [clinicUpdateVisible, setClinicUpdateVisible] = useState(false);
  const pathArr = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  // If clinic is none, query for clinic
  const clinicData = useSelector((state) => state.selectedClinic.clinicData);
  const clinicError = useSelector((state) => state.selectedClinic.error);
  const { clinic_no } = useParams();

  const memoizedClinicNo = useMemo(() => clinic_no, [clinic_no]);

  useEffect(() => {
    dispatch(fetchClinicData(memoizedClinicNo));
  }, [dispatch, memoizedClinicNo]);

  useEffect(() => {
    // Check if the error code is 404 and navigate
    if (clinicError && clinicError.code === 404) {
      navigate("/404");
    }
  }, [clinicError, navigate]);

  let selectedKey;
  if (pathArr.length === 2) {
    selectedKey = "details";
  } else if (pathArr.length > 2 && pathArr[2] === "accounts") {
    selectedKey = "/accounts";
  } else if (pathArr.length > 2 && pathArr[2] === "equipment") {
    selectedKey = "/equipment";
  }

  const menuItems = [
    {
      key: "details",
      label: "Details",
      onClick: () => navigate(`/${pathArr[0]}/${pathArr[1]}`),
    },
    ...(hasPermission(
      ["Operations", "IT", "Marketing", "Finance", "Audiology", "Admin.Basic"],
      ["Sales.Staff", "Sales.RM"],
      clinic_no
    )
      ? [
          {
            key: "/accounts",
            label: "Accounts",
            onClick: () => navigate(`/${pathArr[0]}/${pathArr[1]}/accounts`),
          },
        ]
      : []),
    // {
    //   key: "/equipment",
    //   label: "Equipment",
    //   onClick: () => navigate(`/${pathArr[0]}/${pathArr[1]}/equipment`),
    // },
  ];

  return (
    <>
      <Layout className="h-full flex-grow flex flex-col">
        {clinicData && (
          <div className="flex justify-between items-center h-16 p-4">
            <Title level={3}>
              {clinicData.clinicNo} {clinicData.clinicName}
            </Title>
            <div className="flex items-center">
              <AiFillInfoCircle />
              <p>{clinicData.clinicStatus}</p>

              {pathArr.length === 2 &&
                hasPermission(
                  ["Admin.Basic"],
                  ["Sales.RM"],
                  clinicData.clinicNo,
                  false
                ) && (
                  <AiOutlineEdit
                    className="text-2xl ml-2"
                    onClick={() => {
                      setClinicUpdateVisible(true);
                    }}
                  />
                )}
            </div>
            {/* if the current path array is just the default path, then include an edit icon */}
          </div>
        )}
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          className="flex-none"
          items={menuItems}
        />
        <Content className="h-content flex-grow overflow-y-auto p-4">
          <Outlet />
          <Footer />
        </Content>
      </Layout>

      <ClinicUpdateModal
        clinicInfo={clinicData}
        visible={clinicUpdateVisible}
        setVisible={setClinicUpdateVisible}
      />
    </>
  );
}

export default ClinicLayout;
