import React, { useState, useEffect } from "react";
import { Card, Avatar, Typography } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";

import axiosInstance from "utilities/axios";
import { formatHours } from "utilities/formats";
import CopyableIcon from "components/copyable";

const { Title, Link, Text } = Typography;

const EmployeeCard = ({ employee }) => {
  const [photoUrl, setPhotoUrl] = useState(null);

  useEffect(() => {
    const fetchPhoto = async () => {
      const user_id = employee.initials;
      const photoResponse = await axiosInstance.get(
        `employees/${user_id}/photo`,
        {
          responseType: "blob",
        }
      );
      if (photoResponse.status !== 204) {
        const photoUrl = URL.createObjectURL(photoResponse.data);
        setPhotoUrl(photoUrl);
      }
    };

    fetchPhoto();
  }, [employee]);

  useEffect(() => {
    console.log(employee);
  }, [employee]);

  const [expanded, setExpanded] = useState(false);

  const onCardClick = () => setExpanded(!expanded);
  return (
    <Card
      onClick={onCardClick}
      hoverable
      style={{
        width: "110%",
        minWidth: 380,
        cursor: "pointer",
        height: expanded ? "auto" : "100px",
      }}
      bodyStyle={{ padding: 2 }}
      className="flex flex-col items-start my-1 justify-start overflow-hidden shadow-lg"
      loading={!employee && !photoUrl}
    >
      <div className="flex flex-row w-120 ">
        <Avatar
          size={96}
          src={photoUrl}
          icon={!photoUrl && <UserOutlined />}
          shape="circle"
        />
        <div className="ml-6 mt-2">
          <Title level={4} style={{ marginBottom: 0, marginTop: 0 }}>
            {employee.name}
          </Title>
          <Text>{employee.jobTitle}</Text>
          <br />
          <Text>{employee.fullTime ? "Full Time" : "Part Time"}</Text>
        </div>
      </div>
      {expanded && (
        <div className="ml-10 mt-0 mb-4 h-fit">
          <Text
            type="secondary"
            copyable={{
              icon: <CopyableIcon textTheme="secondary" />,
              text: employee.email,
            }}
          >
            <MailOutlined /> Email: {employee.email}
          </Text>
          {employee.languages && employee.languages.length > 0 && (
            <Text type="secondary">
              Languages:{" "}
              {employee.languages.map((lang) => lang.language).join(", ")}
            </Text>
          )}

          {employee.hours && employee.hours.length > 0 && (
            <>
              <Title level={5} style={{ marginBottom: 0, marginTop: 0 }}>
                Hours
              </Title>
              <div className="p-0 m-0 h-fit mb-2">
                {formatHours(employee.hours)}
              </div>
            </>
          )}
          <Link href={employee.profileUrl}>Full Profile</Link>
        </div>
      )}
    </Card>
  );
};

export default EmployeeCard;
