import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utilities/axios";
import { Table, Button, Radio, Tag } from "antd";

import ForbiddenScreen from "../../../pages/403";
import CredentialDetailComponent from "./CredentialDetailComponent";
import CredentialAddComponent from "./CreateCredentialComponent";
import useRoleCheck from "utilities/hooks/roleCheck";
import { fetchCategories } from "utilities/redux/slices/credentialCategorySlice";
import { fetchTypes } from "utilities/redux/slices/credentialTypeSlice";
import { useDispatch } from "react-redux";

const ClinicAccounts = () => {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  let { clinic_no: clinicNo } = useParams();
  const [credentialType, setCredentialType] = useState(
    hasPermission(["Operations"], ["Sales.Staff", "Sales.RM"], clinicNo) ? 2 : 1
  );
  const [dataSource, setDataSource] = useState([]);
  const [totalCredentials, setTotalCredentials] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isAddVisible, setIsAddVisible] = useState(false);

  const [credentialDetailVisible, setCredentialDetailVisible] = useState(false);
  const [credential, setCredential] = useState(null);
  const pageView = hasPermission(
    ["Operations", "IT", "Marketing", "Audiology", "Finance", "Admin.Basic"],
    ["Sales.Staff", "Sales.RM"],
    clinicNo
  );

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchTypes());
  }, [dispatch]);

  const fetchRecords = useCallback(
    (page, pageSize) => {
      setLoading(true);
      axiosInstance
        .get("/credentials", {
          params: {
            clinicNo: clinicNo,
            page,
            pageSize,
            category: credentialType,
          },
        })
        .then((response) => {
          setDataSource(response.data);
          return axiosInstance.get("/credentials", {
            params: {
              total: true,
              clinicNo: clinicNo,
              category: credentialType,
            },
          });
        })
        .then((response) => {
          setTotalCredentials(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          setLoading(false);
        });
    },
    [clinicNo, credentialType]
  );

  useEffect(() => {
    fetchRecords(1, 20);
  }, [clinicNo, fetchRecords, credentialType]);

  const columns = [
    {
      title: "Company",
      width: 300,
      dataIndex: "company",
      key: "company",
      render: (text, record) => <span>{record.vendor.vendorName}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 150,
      key: "type",
      render: (text, record) => <span>{record.type.typeName}</span>,
    },
    {
      title: "Account",
      dataIndex: "primaryField",
      key: "primaryField",
      render: (text, record) => (
        <div className="flex itmes-center justify-between">
          {record.primaryField && (
            <div>
              {record.primaryField.fieldValue}
              <div style={{ color: "grey" }}>
                {record.primaryField.fieldDescription &&
                record.primaryField.fieldDescription !== ""
                  ? record.primaryField.fieldDescription
                  : record.primaryField.fieldName}
              </div>
            </div>
          )}
          <div className="ml-4">
            {record.ents.map((ent, index) => (
              <Tag key={index}>{ent.practiceName}</Tag>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "", // empty title for the "Show More" button
      key: "action",
      width: 130,
      render: (text, record) => (
        <Button
          type="text"
          onClick={() => {
            setCredentialDetailVisible(true);
            setCredential(record.id);
          }}
        >
          Show More
        </Button>
      ),
    },
  ];
  if (!pageView) {
    return <ForbiddenScreen />;
  }
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        {hasPermission(
          [
            "Marketing.Write",
            "IT.Write",
            "Audiology.Admin",
            "Finance.Write",
            "Operations.Write",
            "Admin.Basic",
          ],
          ["Sales.RM"],
          clinicNo,
          false
        ) && (
          <Button type="primary" onClick={() => setIsAddVisible(true)}>
            Add Credential
          </Button>
        )}
        <Radio.Group
          value={credentialType}
          onChange={(e) => setCredentialType(e.target.value)}
        >
          {/* TODO: either update backend to accept names instead of numbers or map to real values */}

          {hasPermission(
            ["Operations"],
            ["Sales.Staff", "Sales.RM"],
            clinicNo
          ) && (
            <Radio.Button value={2} style={{ width: 100, textAlign: "center" }}>
              Insurers
            </Radio.Button>
          )}
          <Radio.Button value={1} style={{ width: 100, textAlign: "center" }}>
            Vendors
          </Radio.Button>

          {hasPermission(["IT"]) && (
            <Radio.Button value={3} style={{ width: 100, textAlign: "center" }}>
              IT
            </Radio.Button>
          )}
        </Radio.Group>
      </div>
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          total: totalCredentials,
          onChange: fetchRecords,
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
        bordered
        sticky={{ offsetHeader: -20 }}
        size={"small"}
      />
      <CredentialDetailComponent
        credential_id={credential}
        visible={credentialDetailVisible}
        onFinish={() => setCredentialDetailVisible(false)}
        onChange={() => fetchRecords(1, 20)}
      />
      <CredentialAddComponent
        visible={isAddVisible}
        setVisible={setIsAddVisible}
        clinicNo={clinicNo}
        onCreate={() => fetchRecords(1, 20)}
        defaultCategory={credentialType}
      />
    </>
  );
};

export default ClinicAccounts;
