import { useSelector } from "react-redux";

const useRoleCheck = () => {
  const roles = useSelector((state) => state.roles.data.roles);
  const clinics = useSelector((state) => state.roles.data.clinics);

  const hasRole = (rolePrefix) => {
    return roles.some((role) => role.startsWith(rolePrefix));
  };

  const hasPermission = (
    requiredRoles,
    conditionalRoles = [],
    clinic_no,
    isReadComponent = true
  ) => {
    // Check for Admin or App.ReadAll roles
    if (
      roles.includes("Admin.Manage") ||
      (isReadComponent && roles.includes("App.ReadAll"))
    ) {
      return true;
    }

    // Check if any of the required roles are present
    const hasRequiredRole = requiredRoles.some((role) => hasRole(role));
    if (hasRequiredRole) {
      return true;
    }

    // Check conditional roles (require specific clinic)
    return conditionalRoles.some((role) => {
      return hasRole(role) && clinics.includes(clinic_no);
    });
  };

  return { hasPermission };
};

export default useRoleCheck;
