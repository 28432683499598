import { React, useState, useEffect, useCallback } from "react";
import { Button, Table, Input, Modal, Select, message, Typography } from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "utilities/axios";
import { useDispatch, useSelector } from "react-redux";

import useRoleCheck from "utilities/hooks/roleCheck";
import { fetchClinics } from "utilities/redux/slices/clinicsSlice";
import AddClinic from "./components/AddClinic";
import PrintModal from "./components/PrintPopup";
import { debounce } from "lodash";
import Footer from "components/Footer";

const { Text, Title } = Typography;
const { Option } = Select;
const Clinics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasPermission } = useRoleCheck();

  const clinics = useSelector((state) => state.clinics.data);
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const [clinicStatus, setStatus] = useState(
    searchParams.getAll("status").length > 0
      ? searchParams.getAll("status")
      : ["Open"]
  );
  const [statusOptions, setStatusOptions] = useState([]);
  const [region, setRegion] = useState(searchParams.getAll("region"));
  const [regionOptions, setRegionOptions] = useState([]);
  const [division, setDivision] = useState(searchParams.getAll("division"));
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddClinicVisible, setIsAddClinicVisible] = useState(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const debouncedFetchClinics = useCallback(
    debounce((filters) => {
      dispatch(fetchClinics(filters));
      var tempStatus = filters.status.length ? { status: filters.status } : {};
      var tempSearch = filters.search.trim() ? { search: filters.search } : {};
      var tempRegion = filters.region.length ? { region: filters.region } : {};
      var tempDivision = filters.division.length
        ? { division: filters.division }
        : {};

      setSearchParams(
        { ...tempStatus, ...tempSearch, ...tempRegion, ...tempDivision },
        { replace: true }
      );
    }, 200),
    [dispatch, setSearchParams]
  );

  useEffect(() => {
    const filters = {
      search: search,
      region: region,
      division: division,
      status: clinicStatus,
    };
    debouncedFetchClinics(filters);
  }, [search, region, division, clinicStatus, debouncedFetchClinics]);

  const columns = [
    {
      title: "Clinic",
      dataIndex: "clinic",
      key: "clinic",
      responsive: ["sm"],
      sorter: (a, b) => a.clinic.localeCompare(b.clinic),
    },
    {
      title: "Clinic Name",
      dataIndex: "clinicName",
      key: "clinicName",
      sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      responsive: ["xl"],

      render: (_, record) => {
        let address = record.Street_No;
        if (record.Street_Name) address += " " + record.Street_Name;
        if (record.Unit_No) address += ", " + record.Unit_No;
        return address;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      responsive: ["md"],
      sorter: (a, b) => (a.city || "").localeCompare(b.city || ""),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      responsive: ["xxl"],
      sorter: (a, b) => (a.code || "").localeCompare(b.code || ""),
    },
    {
      title: "Regional Manager",
      dataIndex: "regionalManager",
      key: "regionalManager",
      responsive: ["xxl"],
      sorter: (a, b) => a.regionalManager.localeCompare(b.regionalManager),
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      responsive: ["xl"],
      sorter: (a, b) => a.region.localeCompare(b.region),
    },
    {
      title: "Division",
      dataIndex: "division",
      key: "division",
      responsive: ["xxl"],
      sorter: (a, b) => a.division.localeCompare(b.division),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["md"],
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Phone",
      key: "phone",
      width: 150,
      dataIndex: "phone",
      responsive: ["md"],
      render: (text) => {
        if (!text) {
          return "";
        }
        const formattedPhoneNumber = text.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
        return formattedPhoneNumber;
      },
    },
    {
      title: "Detail",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Button
          onClick={() => {
            navigate(`/clinics/${record.clinic}`);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  useEffect(() => {
    axiosInstance.get("/clinics/statuses").then((response) => {
      setStatusOptions(response.data);
    });
    axiosInstance.get("/divisions").then((response) => {
      setDivisionOptions(response.data);
    });
  }, []);
  // For region options based on selected divisions
  useEffect(() => {
    if (division.length > 0) {
      axiosInstance
        .get("/regions", { params: { division: division } })
        .then((response) => {
          setRegionOptions(response.data);
          setRegion((prevRegion) =>
            prevRegion.filter((value) => response.data.includes(value))
          );
        });
    } else {
      axiosInstance.get("/regions").then((response) => {
        setRegionOptions(response.data);
        setRegion((prevRegion) =>
          prevRegion.filter((value) => response.data.includes(value))
        );
      });
    }
  }, [division]);

  return (
    <>
      <div className="p-8">
        <div className="flex">
          <Input.Search
            placeholder="Search clinics"
            allowClear
            size="large"
            suffix="" // Remove the search icon
            onChange={(event) => setSearch(event.target.value)}
            value={search}
            className="mr-2"
          />
          <Button
            type="primary"
            size="large"
            icon={<FilterOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Filter
          </Button>
          <Button
            type="primary"
            size="large"
            className="ml-2"
            icon={<PrinterOutlined />}
            onClick={() => setIsPrintModalVisible(true)}
          >
            Print
          </Button>
          {hasPermission(["Admin.Basic"]) && (
            <Button
              type="primary"
              size="large"
              className="ml-2"
              icon={<PlusOutlined />}
              onClick={() => setIsAddClinicVisible(true)}
            >
              Add Clinic
            </Button>
          )}
        </div>

        <div className="mt-4">
          <Table columns={columns} dataSource={clinics} rowKey="clinic" />
          <Footer />
        </div>
      </div>
      <Modal
        title="Filter Options"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <div className="flex flex-col">
          <Text className="mb-0 mt-2">Clinic Status</Text>
          <Select
            mode="multiple"
            placeholder="Clinic Status"
            value={clinicStatus}
            onChange={setStatus}
          >
            {statusOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
          <Text className="mb-0 mt-2">Region</Text>
          <Select
            mode="multiple"
            showSearch
            placeholder="Region"
            value={region}
            onChange={setRegion}
            filterOption={(input, option) => {
              const optionData = regionOptions.find(
                (opt) => opt.regionCode === option.value
              );
              if (!optionData) return false;
              return (
                optionData.regionCode
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                optionData.regionName
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {regionOptions.map((option) => (
              <Option key={option.regionCode} value={option.regionCode}>
                {option.regionCode} - {option.regionName}
              </Option>
            ))}
          </Select>
          <Text className="mb-0 mt-2">Division</Text>
          <Select
            mode="multiple"
            placeholder="Division"
            value={division}
            onChange={setDivision}
          >
            {divisionOptions.map((option) => (
              <Option key={option.divisionCode} value={option.divisionCode}>
                {option.divisionName}
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
      <AddClinic
        visible={isAddClinicVisible}
        setVisible={setIsAddClinicVisible}
      />
      <PrintModal
        isVisible={isPrintModalVisible}
        onClose={() => setIsPrintModalVisible(false)}
        searchParams={{
          search,
          region,
          division,
          status: clinicStatus,
        }}
      />
    </>
  );
};

export default Clinics;
