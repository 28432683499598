import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  DatePicker,
  Checkbox,
  TimePicker,
  Tabs,
  Form,
  Typography,
  notification,
  Popconfirm,
  List,
  Tag,
  AutoComplete,
  Select,
} from "antd";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { fetchClinicData } from "utilities/redux/slices/clinicSlice";
import axiosInstance from "utilities/axios";
import HoursControl from "components/HoursControl";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const EmployeeControl = ({ value = [], onChange }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState(value);
  const [newEmployees, setNewEmployees] = useState([]);
  const [foundENTs, setFoundENTs] = useState([]);

  const [form] = Form.useForm();

  // Mock fetching ENTs
  const fetchENTs = async (term) =>
    axiosInstance.get(`ents/employees?search=${term}`).then((res) => {
      return res.data;
    });

  // Add more mock data here

  const handleSearch = async (term) => {
    setSearchTerm(term);
    const results = await fetchENTs(term);
    setFoundENTs(results);
  };

  const addEmployee = (employee) => {
    if (!employee.id) {
      employee = { ...employee, id: null }; // For new employee, set id to null
    }
    setEmployees([...employees, employee]);
    onChange && onChange([...employees, employee]);
    setModalVisible(false);
    form.resetFields();
  };

  const removeEmployee = (index) => {
    const updatedEmployees = employees.filter((_, i) => i !== index);
    setEmployees(updatedEmployees);
    onChange && onChange(updatedEmployees);
  };

  return (
    <div>
      <AutoComplete
        //map options to include title if present, first name if present, and last name if present, if not remove the space and null value
        options={foundENTs.map((ent) => ({
          value: `${ent.title ? ent.title + " " : ""}${
            ent.firstName ? ent.firstName + " " : ""
          }${ent.lastName ? ent.lastName : ""}`,
          label: `${ent.title ? ent.title + " " : ""}${
            ent.firstName ? ent.firstName + " " : ""
          }${ent.lastName ? ent.lastName : ""}`,
          employee: ent,
        }))}
        onSearch={handleSearch}
        value={searchTerm}
        onSelect={(value, option) => {
          addEmployee(option.employee, false);
          setSearchTerm("");
          setFoundENTs([]);
        }}
        placeholder="Search for existing ENTs"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setModalVisible(true)}
        >
          Add New ENT Employee
        </Button>
      </div>

      <List
        dataSource={employees}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Tag color={item.id ? "blue" : "green"}>
                {item.id ? "Existing" : "New"}
              </Tag>,
              <Button type="link" onClick={() => removeEmployee(index)}>
                Remove
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Text>
                  {item.title && `${item.title} `}
                  {item.firstName && `${item.firstName} `}
                  {item.nickname && `(${item.nickname}) `}
                  {item.lastName && `${item.lastName} `}
                </Text>
              }
              description={item.job}
            />
          </List.Item>
        )}
      />
      <Modal
        title="Add New ENT Employee"
        open={isModalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={form.submit} // Use the new handleOk function
      >
        <Form
          form={form}
          onFinish={(values) => {
            addEmployee(values, true);
          }}
          initialValues={{
            title: null, // Set the initial value to null
            firstName: null,
            lastName: null,
            nickname: null,
            job: null,
          }}
        >
          <Form.Item label="Title" name="title">
            <Select>
              <Select.Option value="Dr.">Dr.</Select.Option>
              <Select.Option value={null}>None</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="First Name" name="firstName">
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName">
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Nickname" name="nickname">
            <Input placeholder="Nickname" />
          </Form.Item>
          <Form.Item label="Job" name="job">
            <AutoComplete
              placeholder="Select or enter a job"
              options={[{ value: "ENT" }, { value: "ENT Assistant" }]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const ENTAddModal = ({ clinicData, visible, closeModal }) => {
  const dispatch = useDispatch();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(true);
  const [activeTab, setActiveTab] = useState("1");

  const onChange = () => {
    dispatch(fetchClinicData());
  };
  const handleCancel = () => {
    if (unsavedChanges) {
      setShowPopconfirm(true); // Show Popconfirm
    } else {
      form.resetFields(); // Reset form
      setShowPopconfirm(false); // Hide Popconfirm
      setUnsavedChanges(false); // Reset unsavedChanges
      setChecked(true);
      setActiveTab("1");
      closeModal();
    }
  };
  const handleConfirmClose = () => {
    setUnsavedChanges(false); // Reset unsavedChanges
    setShowPopconfirm(false); // Hide Popconfirm
  };

  const handlePopconfirmCancel = () => {
    setShowPopconfirm(false); // Hide Popconfirm only
  };

  const onFinish = (values) => {
    // validate form includes at least one employee
    if (values.employees.length === 0) {
      notification.error({
        message: "Error",
        description: "Please add at least one employee.",
      });
      return;
    }
    //check if clinicNo is present if not add it
    if (!values.clinicNo) {
      values.clinicNo = clinicData.clinicNo;
    }
    axiosInstance
      .post("ents", values)
      .then((res) => {
        notification.success({
          message: "Data Updated",
          description: "Your data has been successfully updated.",
        });
        setUnsavedChanges(false);
        onChange && onChange();
        closeModal();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description:
            "There was an error creating the ENT, please try again later.",
        });
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    setUnsavedChanges(true);
  };
  const handleCheck = (e) => {
    setChecked(e.target.checked);
    if (!e.target.checked) {
      // reset form values if unchecked

      form.setFieldsValue({ hours: clinicData.clinicHours || [] }); // ensure defaultHours is an array or fallback to an empty array
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  useEffect(() => {
    if (!showPopconfirm && !unsavedChanges) {
      setActiveTab("1"); // Set to "Basic Information" tab
      setChecked(true); // Set checkbox to checked hiding the hours
      setUnsavedChanges(false); // Reset unsavedChanges variable for popconfirm
      setShowPopconfirm(false); // Hide Popconfirm if visible
      form.resetFields();
      closeModal();
    }
  }, [showPopconfirm, unsavedChanges]);

  useEffect(() => {
    form.setFieldsValue({
      name: `ENT Practice at ${clinicData.clinicNo}`,
      checked: true,
      hours: clinicData.clinicHours,
      clinicNo: clinicData.clinicNo,
    });
  }, [clinicData, form]);

  return (
    <Modal
      title={<Title level={4}>New ENT Practice</Title>}
      open={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      footer={[
        <Popconfirm
          title="You have unsaved changes. Are you sure you want to close?"
          onConfirm={handleConfirmClose}
          onCancel={handlePopconfirmCancel}
          open={showPopconfirm}
          cancelText={"No"}
          okText={"Close"}
        >
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
        </Popconfirm>,
        <Button key="submit" type="primary" onClick={form.submit}>
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          name: `ENT Practice at ${clinicData.clinicNo}`,
          sameAsClinicHours: true,
          hours: clinicData.hours,
          employees: [],
          clinicNo: clinicData.clinicNo,
        }}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onChange={handleTabChange}
        >
          <TabPane tab="Basic Information" key="1">
            <div>
              <Form.Item
                label={<Text>Name</Text>}
                name="name"
                rules={[{ required: true, message: "Please input the name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label={<Text>Open Date</Text>} name="openDate">
                <DatePicker />
              </Form.Item>
              <Form.Item
                label={<Text>Hours Same as Clinic</Text>}
                name="sameAsClinicHours"
                valuePropName="checked"
              >
                <Checkbox onChange={handleCheck} />
              </Form.Item>
              {!checked && (
                <Form.Item name="hours">
                  <HoursControl />
                </Form.Item>
              )}
            </div>
          </TabPane>
          <TabPane tab="Employees" key="2">
            <Form.Item name="employees">
              <EmployeeControl />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default ENTAddModal;
