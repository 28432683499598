import dayjs from "dayjs";
const formatDateRange = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (start.isSame(end)) {
    return start.format("DD MMM YYYY");
  }

  if (start.month() === end.month() && start.year() === end.year()) {
    return `${start.format("DD")}-${end.format("DD MMM YYYY")}`;
  }

  if (start.year() === end.year()) {
    return `${start.format("DD MMM")} - ${end.format("DD MMM YYYY")}`;
  }

  return `${start.format("DD MMM YYYY")} - ${end.format("DD MMM YYYY")}`;
};

export default formatDateRange;
