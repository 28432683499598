import React from "react";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { theme } from "antd";

const { useToken } = theme;
//matches the icon theme
const CopyableIcon = ({ textTheme }) => {
  const { token } = useToken();

  const colourName = `colorText${textTheme
    .charAt(0)
    .toUpperCase()}${textTheme.slice(1)}`;
  return [
    <CopyOutlined
      key="copy-icon"
      style={{
        color: token[colourName],
      }}
    />,
    // <CheckOutlined
    //   key="copied-icon"
    //   style={{
    //     color: token[colourName],
    //   }}
    // />,
  ];
};

export default CopyableIcon;
