import React, { useState, useEffect } from "react";
import { Modal, Button, Steps, Select, notification } from "antd";
import axiosInstance from "utilities/axios";

const { Step } = Steps;
const { Option } = Select;

const ClinicsSection = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [clinicAction, setClinicAction] = useState(null);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clinicsData, setClinicsData] = useState([]);

  const [employeeClinics, setEmployeeClinics] = useState([]);

  useEffect(() => {
    // Assuming 'id' is set somewhere earlier in the component or from a prop
    const id = "thhk";

    axiosInstance
      .get(`/employees/${id}/clinics`)
      .then((response) => {
        setEmployeeClinics(response.data);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to fetch employee clinics.",
        });
      });
  }, []);

  const handleConfirmAction = () => {
    let requestPayload;
    switch (clinicAction) {
      case "add":
        requestPayload = {
          action: "add",
          clinics: selectedClinics,
        };
        break;
      case "remove":
        requestPayload = {
          action: "remove",
          clinics: selectedClinics,
        };
        break;
      case "changePrimary":
        if (selectedClinics.length === 1) {
          requestPayload = {
            action: "changePrimary",
            clinic: selectedClinics[0],
          };
        }
        break;
      default:
        break;
    }

    axiosInstance
      .post("/clinics/requests", requestPayload)
      .then((response) => {
        notification.success({
          message: "Success",
          description: "Clinics modified successfully.",
        });
        handleModalClose();
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to modify clinics.",
        });
      });
  };
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentStep(0);
    setClinicAction(null);
  };

  const handleClinicActionChange = (value) => {
    setClinicAction(value);
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl font-bold mb-4">Clinics</h2>

      {/* List of Clinics will go here... */}

      <Button onClick={handleModalOpen} className="mt-4">
        Modify Clinics
      </Button>

      <Modal
        title="Clinic Requests"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={600}
      >
        <Steps current={currentStep} className="mb-4">
          <Step title="Action" />
          <Step title="Select Clinics" />
          <Step title="Confirm" />
        </Steps>

        {currentStep === 0 && (
          <div className="flex flex-col gap-4">
            <Button onClick={() => handleClinicActionChange("add")}>
              Add Clinic
            </Button>
            <Button onClick={() => handleClinicActionChange("remove")}>
              Remove Clinic
            </Button>
            <Button onClick={() => handleClinicActionChange("changePrimary")}>
              Change Primary Clinic
            </Button>
          </div>
        )}

        {currentStep === 1 && (
          <div className="mb-4">
            {clinicAction === "add" && (
              <Select
                mode="multiple"
                placeholder="Select Clinics"
                className="w-full"
                value={selectedClinics}
                onChange={setSelectedClinics}
              >
                {clinicsData.map((clinic) => (
                  <Option key={clinic.clinicId} value={clinic.clinicId}>
                    {clinic.name}
                  </Option>
                ))}
              </Select>
            )}

            {clinicAction === "remove" && (
              <Select
                mode="multiple"
                placeholder="Select Clinics to Remove"
                className="w-full"
                value={selectedClinics}
                onChange={setSelectedClinics}
              >
                {employeeClinics.map((clinic) => (
                  <Option key={clinic.clinicId} value={clinic.clinicId}>
                    {clinic.name}
                  </Option>
                ))}
              </Select>
            )}

            {clinicAction === "changePrimary" && (
              <Select
                placeholder="Select New Primary Clinic"
                className="w-full"
                value={selectedClinics}
                onChange={(value) => setSelectedClinics([value])}
              >
                {employeeClinics.map((clinic) => (
                  <Option key={clinic.clinicId} value={clinic.clinicId}>
                    {clinic.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        )}

        {currentStep === 2 && (
          <div className="flex justify-center">
            <Button
              type="primary"
              onClick={handleConfirmAction}
              className="mt-4"
            >
              Confirm
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ClinicsSection;
