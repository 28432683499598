import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Menu, Modal, Button } from "antd";
import useRoleCheck from "utilities/hooks/roleCheck";
import { fetchClinicData } from "utilities/redux/slices/clinicSlice";

import TempCloseManage from "./components/TempCloseManage";
import PermanentOpenClose from "./components/PermanentOpenClose";
import ClinicNameChange from "./components/ClinicNameChange";

const ClinicUpdateModal = ({ clinicInfo, visible, setVisible }) => {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const [selectedKey, setSelectedKey] = useState(null);
  const [step, setStep] = useState(1);

  const resetModal = () => {
    setSelectedKey(null);
    setStep(1);
    setVisible(false);
  };
  const onChange = () => {
    dispatch(fetchClinicData(clinicInfo.clinicNo));
  };
  //if no clinic info return null
  if (!clinicInfo) {
    return null;
  }

  const menu = (
    <Menu
      items={[
        hasPermission(["Admin.Basic"], [], null, false) && {
          key: "1",
          label: "Change Clinic Name",
          onClick: () => {
            setSelectedKey("changeName");
            setStep(2);
          },
        },
        {
          key: "2",
          label: "Manage Temporary Closures",
          onClick: () => {
            setSelectedKey("tempClose");
            setStep(2);
          },
        },
        hasPermission(["Admin.Basic"], [], null, false) && {
          key: "3",
          label: "Update Clinic Status",
          onClick: () => {
            setSelectedKey("permanentOpenClose");
            setStep(2);
          },
        },
      ]}
    />
  );

  return (
    <Modal
      title="Manage Clinic"
      open={visible}
      onCancel={resetModal}
      footer={null}
    >
      {step === 1 && menu}

      {step === 2 && (
        <>
          {selectedKey === "changeName" && (
            <ClinicNameChange
              clinicInfo={clinicInfo}
              onChange={() => {
                setVisible(false);
                resetModal();
                onChange();
              }}
            />
          )}

          {selectedKey === "tempClose" && (
            <TempCloseManage clinicInfo={clinicInfo} onChange={onChange} />
          )}

          {selectedKey === "permanentOpenClose" && (
            <PermanentOpenClose
              clinicInfo={clinicInfo}
              onChange={() => {
                setVisible(false);
                resetModal();
                onChange();
              }}
            />
          )}

          <div style={{ marginTop: 20 }}>
            <Button onClick={() => setStep(1)}>Back</Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ClinicUpdateModal;
