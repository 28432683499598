import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Skeleton,
  Input,
  Button,
  Modal,
  notification,
  Form,
  Typography,
  Flex,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import axiosInstance from "utilities/axios";
import { fetchClinicData } from "utilities/redux/slices/clinicSlice";
import useRoleCheck from "utilities/hooks/roleCheck";
import { buildAddress } from "utilities/formats";
import AddressControl from "components/AddressControl";
import StoreMap from "./map";

const { Text } = Typography;
const LocationCard = ({ location, clinicNo, clinicName }) => {
  const { longitude, latitude } = location.coordinates;
  //set temp location
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ address: location });
  }, [location, form]);

  const updateAddress = ({ address }) => {
    console.log(address);
    axiosInstance
      .patch(`/clinics/${clinicNo}`, {
        location: address,
      })

      .then(() => {
        notification.success({
          message: "Address Updated",
          description: "Clinic address has been successfully updated.",
        });
        dispatch(fetchClinicData(clinicNo));
        setIsModalVisible(false);
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "There was an error updating the clinic address.",
        });
      });
  };

  // This effect sets loading to true every time coordinates change
  if (!location || !location.coordinates) return null;
  return (
    <>
      <Card
        title="Location"
        style={{ minWidth: 300, width: "100%", height: "fit-content" }}
        bodyStyle={{ margin: 4 }}
        extra={
          hasPermission(["Admin.Basic"], ["Sales.RM"], clinicNo, false) && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          )
        }
      >
        <StoreMap lat={latitude} long={longitude} name={location.name} />
        <Flex vertical gap="medium">
          <Text copyable>{buildAddress(location)}</Text>
          <Text type="secondary" className="mt-2">
            {location.notes}
          </Text>
        </Flex>
      </Card>
      <Modal
        title="Edit Clinic Address"
        open={isModalVisible}
        width={400}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          setIsModalVisible(false);
        }}
        okText="Save"
      >
        <Form
          form={form}
          onFinish={updateAddress}
          initialValues={{ address: location }}
        >
          <Form.Item name="address">
            <AddressControl />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LocationCard;
