import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import LoginPage from "./pages/login";
import Clinics from "./pages/Clinics";
import ClinicDetails from "./pages/ClinicDetails/ClinicDetails";
import ClinicAccounts from "./pages/ClinicDetails/ClinicAccounts/ClinicAccounts";
import ClinicEquipment from "./pages/ClinicDetails/ClinicEquipment/ClinicEquipment";
import ClinicDetailsLayout from "./layouts/ClinicDetailsLayout";
import Employees from "./pages/Employees";
import EmployeeDetails from "./pages/EmployeeDetails";
import Regions from "./pages/Regions";
import RegionDetails from "./pages/RegionDetails";
import Divisions from "./pages/Divisions";
import DivisionDetails from "./pages/DivisionDetails";
import Settings from "./pages/UserSettings";
import Profile from "./pages/UserProfile";
import Vendors from "pages/VendorPage/Vendors";
import Brands from "pages/BrandsPage/Brands";
import DefaultLayout from "./layouts/DefaultLayout";
import ForbiddenScreen from "pages/403";
import NotFoundScreen from "pages/404";
import TestingAppDenied from "pages/TestingAppDenied";
import { useTheme } from "./useTheme";

const { defaultAlgorithm, darkAlgorithm } = theme;
function App() {
  const { isDarkMode, setIsDarkMode } = useTheme();
  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: "#005DA9",
          colorInfo: "#0F2B54",
          colorSuccess: "#ACE8B1",
          colorError: "#D93B30",
          colorTextBase: isDarkMode ? "#F1F1F1" : "#49443D",
          colorBgBase: isDarkMode ? "#272520" : "#FFFFFF",
          // colorPrimaryText: isDarkMode ? "#F1F1F1" : "#49443D",
          colorTextHeading: isDarkMode ? "#F1F1F1" : "#49443D",
          fontSize: 16,
          fontSizeHeading1: 64,
          fontSizeHeading2: 48,
          fontSizeHeading3: 36,
          fontWeightStrong: 700,
          fontFamily: "'Proxima Nova', 'Segoe UI', 'Helvatica', sans-serif",
          colorBgLayout: isDarkMode ? "#272520" : "#F1F1F1", // Black / Light Grey
          colorText: isDarkMode ? "#F1F1F1" : "#272520", // Light Grey / Black
          colorLink: isDarkMode ? "#005DA9" : "#0F2B54", // Audika Blue / Dark Blue
          colorPrimaryText: isDarkMode ? "#005DA9" : "#005DA9", // Audika Blue
          colorErrorText: isDarkMode ? "#D93B30" : "#D93B30", // Attention Red
          colorSuccessText: isDarkMode ? "#ACE8B1" : "#ACE8B1", // Fresh Green
          colorBgElevated: isDarkMode ? "#1F1B18" : "#F5F5F5",
          colorBorder: isDarkMode ? "#3A3532" : "#d9d9d9",
          colorWarningText: isDarkMode ? "#FFC53D" : "#faad14",
          colorTextSecondary: isDarkMode ? "#B0B0B0" : "rgba(0, 0, 0, 0.65)",
          colorTextTertiary: isDarkMode ? "#8A8A8A" : "rgba(0, 0, 0, 0.45)",
        },
      }}
    >
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/clinics" />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<DefaultLayout />}>
            <Route path="*" element={<NotFoundScreen />} />
            <Route path="404" element={<NotFoundScreen />} />
            <Route path="profile" element={<Profile />} />
            {/* <Route path="settings" element={<Settings />} /> */}
            <Route path="clinics" element={<Clinics />} />
            <Route path="clinics/:clinic_no" element={<ClinicDetailsLayout />}>
              <Route path="" element={<ClinicDetails />} />{" "}
              <Route path="accounts" element={<ClinicAccounts />} />
              <Route path="equipment" element={<ClinicEquipment />} />
            </Route>
            <Route path="employees" element={<Employees />}>
              <Route path=":user_id" element={<EmployeeDetails />} />
            </Route>
            <Route path="regions" element={<Regions />}>
              <Route path=":region_code" element={<RegionDetails />} />
            </Route>
            <Route path="divisions" element={<Divisions />}>
              <Route path=":division_code" element={<DivisionDetails />} />
            </Route>
            <Route path="vendors" element={<Vendors />} />
            <Route path="brands" element={<Brands />} />
          </Route>
          <Route
            path="403"
            element={
              ["Staging", "Dev"].includes(process.env.REACT_APP_ENV) ? (
                <TestingAppDenied />
              ) : (
                <ForbiddenScreen />
              )
            }
          />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
