import React, { useState } from "react";
import { Typography, Space, Flex } from "antd";
import useRoleCheck from "utilities/hooks/roleCheck";
import ReleaseNotesModal from "./ReleaseNotesModal";

const { Text, Link } = Typography;
function Footer() {
  const { hasPermission } = useRoleCheck();
  const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);
  return (
    <>
      <Flex justify="center">
        <Space
          split={<span style={{ fontSize: "larger" }}>&middot;</span>}
          wrap
          align="center"
        >
          <Text type="secondary">2024 Clinic Central</Text>
          <Link
            type="secondary"
            underline
            onClick={() => setReleaseNotesOpen(true)}
          >
            Release Notes
          </Link>
          <Link
            type="secondary"
            underline
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=qMf4mwjgp0meQ6t2l2xL-CJI35D1ToFMs0W_a8B-MpVUQ0I4SEMzTEw1V0ZHNkRRMkxROTRLUThPUS4u"
          >
            Report Missing/Incorrect Information
          </Link>
          {hasPermission(["Admin.Basic", "Sales.Exec", "Sales.RM", "HR"]) && (
            <>
              <Link
                type="secondary"
                underline
                href="https://demant.sharepoint.com/sites/HLCanada/Home/rms/Shared%20Documents/Human%20Resources%20and%20Training/Employee%20Change%20Form.pdf"
              >
                Employee Change Request Form
              </Link>
            </>
          )}
          <Link
            type="secondary"
            underline
            href="https://form.asana.com/?k=BTnSOTb-T0O5_gasmSklWg&d=206418375832538"
          >
            Provide Feedback on Clinic Central
          </Link>
        </Space>
      </Flex>
      <ReleaseNotesModal
        open={releaseNotesOpen}
        onClose={() => setReleaseNotesOpen(false)}
      />
    </>
  );
}

export default Footer;
