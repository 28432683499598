import React, { useState, useEffect } from "react";
import { Card, List, Typography, Collapse, Button } from "antd";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import { formatClinicHours } from "utilities/formats";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const ENTCard = ({ ent, onEdit, editable }) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {}, [ent]);
  if (!ent) {
    return null;
  }
  const onCardClick = () => setExpanded(!expanded);
  return (
    <>
      <Card
        onClick={onCardClick}
        hoverable
        style={{
          width: "110%",
          minWidth: 380,
          cursor: "pointer",
          height: "auto",
        }}
        bodyStyle={{ padding: 0 }}
        className="shadow-lg my-1"
        title={
          <div className="mb-2">
            <Title className="mt-2" level={4}>
              {ent.name}
            </Title>
            <Text type={"secondary"}>ENT</Text>
          </div>
        }
        extra={
          editable && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => onEdit(ent)}
            />
          )
        }
      >
        <Collapse
          accordion={true}
          style={{
            width: "100%",
            margin: "0px",
          }}
          bordered={false}
        >
          <Panel
            header="Employees"
            key="1"
            style={{
              width: "100%",
            }}
          >
            <List
              dataSource={ent.employees}
              renderItem={(employee, index) => (
                <List.Item key={`employee-${index}`}>
                  <List.Item.Meta
                    title={
                      <Text>
                        {employee.title && `${employee.title} `}
                        {employee.firstName && `${employee.firstName} `}
                        {employee.nickName && `"${employee.nickName}" `}
                        {employee.lastName && `${employee.lastName}`}
                      </Text>
                    }
                    description={employee.job}
                  />
                </List.Item>
              )}
            />
          </Panel>
          <Panel header="Hours" className="mb-0" key="2">
            <div className="flex flex-col items-center justify-start h-full -mt-6">
              {formatClinicHours(ent.hours)}
            </div>
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};

export default ENTCard;
