// ClinicHoursCard.js
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  notification,
  Form,
  Input,
  Typography,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";
import HoursControl from "components/HoursControl";
import { formatClinicHours } from "utilities/formats";

dayjs.extend(weekday);
dayjs.extend(customParseFormat);

const { Text } = Typography;

const ClinicHoursCard = ({ clinicHours, hoursNotes, clinicNo, onChange }) => {
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ hours: clinicHours, notes: hoursNotes });
  }, [clinicHours, hoursNotes, form]);

  const updateHours = ({ hours, notes }) => {
    hours.forEach((hour) => {
      if (!hour.isOpen) {
        hour.openTime = null;
        hour.closeTime = null;
      }
    });

    axiosInstance
      .patch(`/clinics/${clinicNo}`, {
        hours: {
          hours: hours, // Use the updated copy
          hoursNotes: notes,
        },
      })
      .then(() => {
        notification.success({
          message: "Hours Updated",
          description: "Clinic hours have been successfully updated.",
        });
        onChange();
        setIsModalVisible(false);
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "Failed to update clinic hours.",
        });
      });
  };

  if (!clinicHours) return null;
  return (
    <>
      <Card
        title="Clinic Hours"
        style={{
          minWidth: 280,
          width: "100%",
          cursor: "pointer",
          // height: !hoursNotes ? "260px" : 300,
        }}
        bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
        extra={
          hasPermission(["Admin.Basic"], ["Sales.RM"], clinicNo, false) && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          )
        }
      >
        <div className="m-0">
          {formatClinicHours(clinicHours)}
          {hoursNotes && (
            <div className="flex h-6 mt-4">
              <Text type="secondary" className="w-28 pr-4 text-right">
                Notes:
              </Text>
              <Text type="secondary">{hoursNotes}</Text>
            </div>
          )}
        </div>
      </Card>
      <Modal
        title="Edit Clinic Hours"
        open={isModalVisible}
        onOk={() => form.submit()}
        okText="Save"
        onCancel={() => {
          form.resetFields();
          setIsModalVisible(false);
        }}
      >
        <Form
          form={form}
          onFinish={updateHours}
          initialValues={{ hours: clinicHours, notes: hoursNotes }}
        >
          <Form.Item name="hours">
            <HoursControl />
          </Form.Item>
          <Form.Item name="notes">
            <Input.TextArea
              placeholder="Hours Notes"
              autoSize
              maxLength={250}
              showCount
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ClinicHoursCard;
