import React, { useState } from "react";
import {
  Button,
  DatePicker,
  notification,
  Tooltip,
  Checkbox,
  Typography,
  Flex,
  Space,
} from "antd";
import axiosInstance from "utilities/axios";
import dayjs from "dayjs";

const { Text } = Typography;
const PermanentOpenClose = ({ clinicInfo, onChange }) => {
  const today = dayjs();
  const { clinicStatus, closingDate, soldDate, openingDate, migrationDate } =
    clinicInfo;

  const closingDateDayjs = closingDate ? dayjs(closingDate) : null;
  const soldDateDayjs = soldDate ? dayjs(soldDate) : null;

  const getInitialDate = () => {
    if (clinicStatus === "Open") {
      return closingDate || soldDate || today;
    } else if (clinicStatus === "To Open") {
      return openingDate || today;
    } else if (clinicStatus === "Pending Integration") {
      return migrationDate || today;
    }
    return today;
  };

  const [selectedDate, setSelectedDate] = useState(dayjs(getInitialDate()));
  const [isSold, setIsSold] = useState(!!soldDate);

  const isPastOrToday = (date) => dayjs(date).isSameOrBefore(today, "day");
  const shouldShowCheckbox = clinicStatus === "Open";
  const shouldShowDatePicker = !(
    (clinicStatus === "Closed" && isPastOrToday(closingDate)) ||
    (clinicStatus === "Sold" && isPastOrToday(soldDate))
  );
  const datePickerLabel =
    clinicStatus === "Pending Integration"
      ? "Migration Date"
      : clinicStatus === "To Open"
      ? "Opening Date"
      : isSold
      ? "Sale Closing Date"
      : "Closing Date";

  const buttonLabel = selectedDate?.isSameOrBefore(today)
    ? clinicStatus === "Open"
      ? isSold
        ? "Record Sale"
        : "Close Clinic"
      : clinicStatus === "To Open" ||
        clinicStatus === "Closed" ||
        clinicStatus === "Sold"
      ? "Open Clinic"
      : "Record Migration"
    : clinicStatus === "Open"
    ? isSold
      ? "Schedule Sale"
      : "Schedule Clinic Closure"
    : clinicStatus === "To Open"
    ? "Update Clinic Opening"
    : "Schedule Migration";

  const buttonNewStatus =
    clinicStatus === "To Open" || clinicStatus === "Pending Integration"
      ? "Open"
      : isSold
      ? "Sold"
      : "Closed";

  const isButtonDisabled =
    (closingDateDayjs?.isSame(selectedDate, "day") ||
      soldDateDayjs?.isSame(selectedDate, "day")) &&
    ((!!soldDate && isSold) || (!!closingDate && !isSold));

  const handleUpdate = (currentStatus, newStatus, date) => {
    let statusInfo = {};
    const isDateInFuture = date && dayjs(date).isAfter(dayjs(), "day");

    if (date === null && newStatus === "Open") {
      statusInfo = { status: "Open", closingDate: null, soldDate: null };
    } else if (newStatus === "Open") {
      if (currentStatus === "To Open") {
        statusInfo.openingDate = date;
      } else if (currentStatus === "Pending Integration") {
        statusInfo.migrationDate = date;
      }
    } else if (newStatus === "Closed") {
      statusInfo = { closingDate: date, soldDate: null };
    } else if (newStatus === "Sold") {
      statusInfo = { soldDate: date, closingDate: null };
    }

    if (!isDateInFuture) {
      statusInfo.status = newStatus;
    }

    axiosInstance
      .patch(`/clinics/${clinicInfo.clinicNo}`, statusInfo)
      .then((response) => {
        let successMessage = "Clinic Status Updated";
        if (newStatus === "Closed") {
          successMessage = "Clinic Closure Recorded";
        } else if (newStatus === "Sold") {
          successMessage = "Clinic Sale Recorded";
        }

        notification.success({
          message: successMessage,
        });

        onChange();
        setSelectedDate(dayjs(getInitialDate()));
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Clinic Status",
          description: err.response.data.message,
        });
      });
  };

  return (
    <Flex wrap vertical align="center" gap="middle">
      <Flex
        // Adjust layout with flex
        justify="space-around"
      >
        {/* Adjust layout with flex */}
        {shouldShowCheckbox && (
          <Space align="center" direction="horizontal">
            <Text>Sold</Text>
            <Checkbox
              checked={isSold}
              onChange={(e) => setIsSold(e.target.checked)}
              className="mr-2" // Add margin to the right
            />
          </Space>
        )}
        {shouldShowDatePicker && (
          <Space size="small" align="center" direction="horizontal">
            <Text>{datePickerLabel}</Text>
            <DatePicker
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </Space>
        )}
      </Flex>

      {/* Button on new line */}
      {!(clinicStatus === "Closed" || clinicStatus === "Sold") && (
        <Tooltip title="Update Status">
          <Button
            onClick={() => {
              handleUpdate(
                clinicInfo.clinicStatus,
                buttonNewStatus,
                selectedDate
              );
            }}
            type={clinicStatus === "Open" ? "primary" : undefined} // Conditionally set type
            danger={clinicStatus === "Open"}
            disabled={isButtonDisabled}
          >
            {buttonLabel}
          </Button>
        </Tooltip>
      )}

      {((clinicStatus === "Open" && (closingDate || soldDate)) ||
        clinicStatus === "Closed" ||
        clinicStatus === "Sold") && (
        <Button
          danger
          onClick={() => handleUpdate(clinicInfo.clinicStatus, "Open", null)}
        >
          Cancel Clinic Closure
        </Button>
      )}
    </Flex>
  );
};
export default PermanentOpenClose;
