import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  notification,
  Typography,
  Divider,
  Popconfirm,
  Skeleton,
  Space,
} from "antd";
//import edit button from antd
import { EditOutlined } from "@ant-design/icons";

import useRoleCheck from "utilities/hooks/roleCheck";
import axiosInstance from "utilities/axios";
import CredentialEditComponent from "../EditCredForm";
import VendorDisplay from "./components/VendorDisplay";
import InsurerDisplay from "./components/InsurerDisplay";
import ENTDisplay from "./components/ENTDisplay";
import CredentialFieldDisplay from "./components/CredentialFieldDisplay";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const CredentialDetailComponent = ({
  credential_id,
  visible,
  onFinish,
  onChange,
}) => {
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [credential, setCredential] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [revealedId, setRevealedId] = useState(null);

  const clinicNo = useSelector(
    (state) => state.selectedClinic.clinicData?.clinicNo
  );

  const fetchCredential = (id) => {
    setIsLoading(true);
    setCredential(null);
    axiosInstance
      .get(`/credentials/${id}`)
      .then((response) => {
        //take the vendorId and fetch the vendor
        axiosInstance
          .get(`/vendors/${response.data.vendorId}`)
          .then((vendor) => {
            //if there is insurerDetails in the response set insurer on credentials to that plus add the vendorName to insurerDetails
            if (vendor.data.insurerDetails) {
              vendor.data.insurerDetails.insurerName = vendor.data.vendorName;
              setCredential({
                ...response.data,
                insurer: vendor.data.insurerDetails,
                vendor: vendor.data,
              });
            } else {
              //else set the vendor to the vendorDetails
              setCredential({ ...response.data, vendor: vendor.data });
            }
          });
      })
      .catch((error) => {
        console.error("Error fetching credential:", error);
        notification.error({ message: "Error fetching credential." });
        onFinish();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateCredential = (values) => {
    axiosInstance
      .patch(`/credentials/${credential_id}`, values)
      .then((response) => {
        onChange();
        fetchCredential(credential_id);
        setEditMode(false);
        form.resetFields();
        notification.success({
          message: "Credential updated successfully!",
        });
      })
      .catch((error) => {
        notification.error({ message: "Error updating credential." });
      });
  };

  useEffect(() => {
    if (credential_id) {
      fetchCredential(credential_id);
    }
  }, [credential_id]);

  return (
    <Modal
      title={editMode ? <Title level={3}>Edit Credential</Title> : null}
      open={visible}
      centered
      style={{ maxHeight: "95vh" }}
      onCancel={() => {
        onFinish();
        setEditMode(false);
        form.resetFields();
      }}
      footer={
        !isLoading &&
        credential && (
          <>
            {editMode ? (
              <>
                <Popconfirm
                  title="Are you sure to cancel editing?"
                  onConfirm={() => setEditMode(false)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger type="dashed">
                    Cancel Edit
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="text"
                  className="float-left"
                  icon={
                    ((hasPermission(["IT.Write"], [], null, false) &&
                      credential.category.categoryName === "IT") ||
                      (hasPermission(["Operations.Write"], [], null, false) &&
                        credential.category.categoryName === "Insurer") ||
                      (hasPermission(
                        [
                          "Marketing.Write",
                          "Operations.Write",
                          "Audiology.Admin",
                          "Finance.Write",
                          "Admin.Basic",
                        ],
                        ["Sales.RM"],
                        clinicNo,
                        false
                      ) &&
                        credential.category.categoryName === "Other")) && (
                      <EditOutlined />
                    )
                  }
                  onClick={() => {
                    setEditMode(true);
                  }}
                />
                <Popconfirm
                  title="Are you sure to delete this credential?"
                  onConfirm={() => {
                    axiosInstance
                      .delete(`/credentials/${credential_id}`)
                      .then((response) => {
                        notification.success({
                          message: "Credential deleted successfully!",
                        });
                        onFinish();
                        onChange();
                      })
                      .catch((error) => {
                        console.error("Error deleting credential:", error);
                        notification.error({
                          message: "Error deleting credential.",
                        });
                      });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="Cancel"
                >
                  {((hasPermission(["IT.Write"], [], null, false) &&
                    credential.category.categoryName === "IT") ||
                    (hasPermission(["Operations.Write"], [], null, false) &&
                      credential.category.categoryName === "Insurer") ||
                    (hasPermission(
                      ["Marketing.Write", "Operations.Write"],
                      ["Sales.RM"],
                      clinicNo,
                      false
                    ) &&
                      credential.category.categoryName === "Other")) && (
                    <Button type="primary" danger>
                      Delete
                    </Button>
                  )}
                </Popconfirm>
                <Button type="primary" onClick={onFinish}>
                  Close
                </Button>
              </>
            )}
          </>
        )
      }
    >
      {isLoading || !credential ? (
        <Skeleton active />
      ) : (
        <>
          {!editMode ? (
            <>
              <Title
                level={3}
                style={{ marginTop: "0px", marginBottom: "4px" }}
              >
                {credential
                  ? credential.vendor
                    ? credential.vendor.vendorName
                    : credential.insurer.insurerName
                  : "View Credential"}
              </Title>
              <Text
                type="secondary"
                style={{ marginTop: "4px", marginBottom: "0px" }}
              >
                {credential.category.categoryName === "Other"
                  ? "Vendor"
                  : credential.category.categoryName}
              </Text>
              <Divider />
              <Space direction="vertical" size="small">
                {credential.insurer && (
                  <InsurerDisplay insurer={credential.insurer} />
                )}
                {credential.vendor && (
                  <VendorDisplay vendor={credential.vendor} />
                )}
              </Space>

              <Title level={4} style={{ marginTop: 0, paddingTop: 0 }}>
                {credential.type.typeName}
              </Title>
              <div>
                {credential.fields.map((field) => (
                  <CredentialFieldDisplay
                    key={field.fieldId}
                    field={field}
                    revealedId={revealedId}
                    setRevealedId={setRevealedId}
                  />
                ))}
                <ENTDisplay ents={credential.ents} />
              </div>
            </>
          ) : (
            <CredentialEditComponent
              credential={credential}
              form={form}
              onFinish={updateCredential}
            />
          )}
        </>
      )}
    </Modal>
  );
};
export default CredentialDetailComponent;
