// src/redux/slices/clinicsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching clinics data
export const fetchRoles = createAsyncThunk(
  "permissions/fetchRoles",
  async () => {
    const response = await axiosInstance.get("/roles");
    return response.data;
  }
);

export const resetRoles = () => (dispatch) => {
  dispatch({ type: "roles/reset" });
};

const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    data: {
      roles: [],
      clinics: [],
    },
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase("roles/reset", () => {
        return {
          data: {
            roles: [],
            clinics: [],
          },
          status: "idle",
          error: null,
        };
      });
  },
});

export default rolesSlice.reducer;
