import { React, useState, useEffect } from "react";
import { Button, Table, Input, Segmented, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { fetchVendors } from "utilities/redux/slices/vendorsSlice";
import { fetchCategories } from "utilities/redux/slices/credentialCategorySlice";
import useRoleCheck from "utilities/hooks/roleCheck";

import ForbiddenScreen from "../403";
import Footer from "components/Footer";
import VendorDisplayModal from "./components/VendorDisplayModal";
import VendorAddModal from "./components/VendorAddModal";

const Vendors = () => {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const vendors = useSelector((state) => state.vendors.data);
  const categoryOptions = useSelector((state) => state.credentialCategories);
  const filteredCategories = categoryOptions.data.filter((cat) => {
    return (
      (hasPermission(["IT"]) && cat.categoryName === "IT") ||
      (hasPermission(["Operations"]) && cat.categoryName === "Insurer") ||
      (hasPermission([
        "Marketing",
        "Operations",
        "Sales.RM",
        "Sales.Exec",
        "IT",
        "Admin.Basic",
      ]) &&
        cat.categoryName === "Other")
    );
  });

  const filteredVendors = vendors.filter((vendor) => {
    return (
      (hasPermission(["IT"]) && vendor.categoryName === "IT") ||
      (hasPermission(["Operations"]) && vendor.categoryName === "Insurer") ||
      (hasPermission([
        "Marketing",
        "Operations",
        "Sales.RM",
        "Sales.Exec",
        "IT",
        "Admin.Basic",
      ]) &&
        vendor.categoryName === "Other")
    );
  });
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState();

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [openDisplayModal, setOpenDisplayModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const pageView = hasPermission([
    "Operations",
    "IT",
    "Marketing",
    "Sales.RM",
    "Admin.Basic",
  ]);
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleCategory = (value) => {
    if (value === "all") {
      setCategory(null);
    } else {
      setCategory(value);
    }
  };
  const columns = [
    {
      title: "Category",
      dataIndex: "categoryName",
      width: 300,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
    },
    {
      title: "Vendor/Insurer",
      dataIndex: "vendorName",
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
    },
    {
      title: "Detail",
      dataIndex: "",
      width: 100,
      key: "x",
      render: (text, record) => (
        <Button
          onClick={() => {
            setSelectedVendor(record.vendorId);
            setOpenDisplayModal(true);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
  //get page content
  useEffect(() => {
    dispatch(fetchVendors({ search, category }));
  }, [dispatch, search, category]);

  if (!pageView) {
    return <ForbiddenScreen />;
  }
  if (categoryOptions.status === "loading") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin />
      </div>
    );
  }
  return (
    <>
      <div className="p-8">
        <div className="flex">
          <Input.Search
            placeholder="Search Vendors/Insurers"
            allowClear
            size="large"
            suffix="" // Remove the search icon
            onChange={handleSearch}
            className="mr-2"
          />
          {hasPermission(
            [
              "Marketing.Write",
              "IT.Write",
              "Operations.Write",
              "Sales.RM",
              "Admin.Basic",
            ],
            [],
            null,
            false
          ) && (
            <Button
              type="primary"
              size="large"
              onClick={() => setOpenAddModal(true)}
            >
              Add Vendor/Insurer
            </Button>
          )}

          <Segmented
            defaultValue="all"
            size="large"
            className="mx-2"
            onChange={handleCategory}
            options={[
              { label: "All", value: "all" },
              ...filteredCategories.map((category) => ({
                label: category.categoryName,
                value: category.categoryId,
              })),
            ]}
          />
        </div>

        <div className="mt-4">
          <Table
            columns={columns}
            dataSource={filteredVendors}
            rowKey="vendorId"
          />
          <Footer />
        </div>
      </div>
      <VendorDisplayModal
        vendorId={selectedVendor}
        open={openDisplayModal}
        onClose={() => setOpenDisplayModal(false)}
      />
      <VendorAddModal
        open={openAddModal}
        onClose={(vendor_id) => {
          setOpenAddModal(false);
          if (vendor_id) {
            setSelectedVendor(vendor_id);
            setOpenDisplayModal(true);
          }
        }}
      />
    </>
  );
};

export default Vendors;
