const buildAddress = ({
  streetNo,
  streetName,
  unitNo,
  city,
  province,
  postalCode,
}) => {
  // Create an array with the address parts
  const addressParts = [];
  if (streetNo || streetName) {
    addressParts.push(`${streetNo || ""} ${streetName || ""}`.trim());
  }
  if (unitNo) {
    addressParts.push(unitNo);
  }
  if (city) {
    addressParts.push(city);
  }
  if (streetNo || streetName) {
    addressParts.push(`${province || ""} ${postalCode || ""}`.trim());
  }

  // Join the non-empty parts with a comma
  return addressParts.join(", ");
};

export default buildAddress;
