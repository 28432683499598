import React, { useState, useCallback, useEffect } from "react";
import {
  Tabs,
  Form,
  Input,
  Button,
  DatePicker,
  Checkbox,
  Divider,
  notification,
  Popconfirm,
  List,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axiosInstance from "utilities/axios";
import formatDateRange from "utilities/formats/formatDateRange";
import dayjs from "dayjs";

var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const { TabPane } = Tabs;

const PresentClosure = ({ closure, onDelete }) => {
  // FUTURE:component should have button to change closure end date change closure end date (use tooltip to specify it is the final day of the closure) and change reason
  // for now just have a delete button
  return (
    <div className="flex justify-between items-center py-2 border-b border-gray-200">
      <div className="flex flex-col">
        <div className="mb-1">
          {formatDateRange(closure.startDate, closure.endDate)}
        </div>
        <div>{closure.reason}</div>
      </div>
      <Button
        type="primary"
        danger
        onClick={() => onDelete(closure.id)}
        className="bg-red-500 hover:bg-red-600 text-white"
      >
        <DeleteOutlined />
      </Button>
    </div>
  );
};
const FutureClosure = ({ closure, onDelete }) => {
  // FUTURE: component should have button to delete closure, change closure end date, start date and change reason
  // for now just copy the present closure component
  return PresentClosure({ closure, onDelete });
};

const TempCloseManage = ({ clinicInfo, onChange }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tempClosures, setTempClosures] = useState([]);
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  const [showDatePickers, setShowDatePickers] = useState(true);

  const addTempClosure = (closureInfo) => {
    setLoading(true);
    if (closureInfo.todayOnly) {
      closureInfo.startDate = dayjs().format("YYYY-MM-DD");
      closureInfo.endDate = dayjs().format("YYYY-MM-DD");
    } else {
      closureInfo.startDate = closureInfo.closureDates[0].format("YYYY-MM-DD");
      closureInfo.endDate = closureInfo.closureDates[1].format("YYYY-MM-DD");
    }

    //format date to YYYY-MM-DD

    delete closureInfo.todayOnly;
    delete closureInfo.closureDates;

    axiosInstance
      .post(`/clinics/${clinicInfo.clinicNo}/closures`, closureInfo)
      .then((response) => {
        notification.success({
          message: "Clinic Closure Added",
        });
        getTempClosures();
        form.resetFields();
        setShowDatePickers(false);
        onChange();
      })
      .catch((err) => {
        notification.error({
          message: "Error Temporarily Closing Clinic",
          description: err.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteTempClosure = (closureId) => {
    axiosInstance
      .delete(`/clinics/${clinicInfo.clinicNo}/closures/${closureId}`)
      .then((response) => {
        notification.success({
          message: "Clinic Closure Deleted",
        });
        getTempClosures();
        onChange();
      })
      .catch((err) => {
        notification.error({
          message: "Error Deleting Clinic Closure",
          description: err.response.data.message,
        });
      });
  };

  const updateTempClosure = (closureId, closureInfo) => {
    axiosInstance
      .patch(
        `/clinics/${clinicInfo.clinicNo}/closures/${closureId}`,
        closureInfo
      )
      .then((response) => {
        notification.success({
          message: "Clinic Closure Updated",
        });
        onChange();
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Clinic Closure",
          description: err.response.data.message,
        });
      });
  };

  const getTempClosures = useCallback(() => {
    axiosInstance
      .get(`/clinics/${clinicInfo.clinicNo}/closures`)
      .then((response) => {
        setTempClosures(response.data);
      })
      .catch((err) => {
        notification.error({
          message: "Error Getting Clinic Closures",
          description: err.response.data.message,
        });
      });
  }, [clinicInfo.clinicNo]);
  useEffect(() => {
    getTempClosures();
  }, [getTempClosures]);

  const onTodayOnlyChange = useCallback(
    (e) => {
      setShowDatePickers(!e.target.checked);
      if (e.target.checked) {
        form.setFieldsValue({
          startDate: dayjs(),
          endDate: dayjs(),
        });
      }
    },
    [form]
  );

  useEffect(() => {
    form.setFieldsValue({ todayOnly: true });
    onTodayOnlyChange({ target: { checked: true } });
  }, [form, onTodayOnlyChange]);

  const isPastClosure = (closure) =>
    dayjs().startOf("date").isAfter(closure.endDate);
  const isPresentClosure = (closure) =>
    dayjs().startOf("date").isSameOrBefore(closure.endDate);

  const handleAddClosure = () => {
    form
      .validateFields()
      .then((values) => {
        const closureInfo = {
          ...values,
          startDate: values.closureDates
            ? values.closureDates[0].format("YYYY-MM-DD")
            : undefined,
          endDate: values.closureDates
            ? values.closureDates[1].format("YYYY-MM-DD")
            : undefined,
        };

        if (isPastClosure(closureInfo)) {
          setShowPopconfirm(true);
        } else {
          addTempClosure(closureInfo);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error Temporarily Closing Clinic",
          description: "Please fill in all required fields.",
        });
      });
  };

  return (
    <Tabs defaultActiveKey="add">
      <TabPane tab="Add Closure" key="add">
        <Form
          form={form}
          layout="horizontal"
          colon={false}
          onFinish={addTempClosure}
          initialValues={{ todayOnly: true }}
          style={{ padding: "10px" }} // Reduced padding for less spacing
        >
          {/*     TODO: add check to ensure no subset of overlap, also add a warning if there is overlap */}
          <Form.Item name="todayOnly" valuePropName="checked">
            <Checkbox onChange={onTodayOnlyChange}>Today Only</Checkbox>
          </Form.Item>

          {showDatePickers && (
            <>
              <Form.Item
                label="Closure Dates"
                name="closureDates"
                rules={[
                  { required: true, message: "Please input a start date!" },
                ]}
              >
                <DatePicker.RangePicker
                  defaultPickerValue={[dayjs(), dayjs()]}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Closure Reason"
            name="reason"
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Popconfirm
              title="This is a past closure. Are you sure you want to add it?"
              visible={showPopconfirm}
              onConfirm={() => {
                form.submit(); // Trigger form submission if confirmed
                setShowPopconfirm(false); // Hide Popconfirm after confirmation
              }}
              onCancel={() => setShowPopconfirm(false)}
            >
              <Button
                type="primary"
                onClick={handleAddClosure}
                style={{ display: "block", margin: "0 auto" }}
                loading={loading}
              >
                Add Closure
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </TabPane>
      <TabPane tab="Manage Closures" key="manage">
        {tempClosures
          .filter((closure) => !isPastClosure(closure))
          .sort((a, b) => dayjs(a.endDate).diff(dayjs(b.endDate)))
          .map((closure, index, array) => (
            <React.Fragment key={closure.id}>
              {isPresentClosure(closure) ? (
                <PresentClosure
                  closure={closure}
                  onDelete={deleteTempClosure}
                />
              ) : (
                <FutureClosure closure={closure} onDelete={deleteTempClosure} />
              )}
              {index !== array.length - 1 && <Divider />}
            </React.Fragment>
          ))}
      </TabPane>

      <TabPane tab="Past Closures" key="past">
        <List
          dataSource={tempClosures
            .filter(isPastClosure)
            .sort((a, b) => dayjs(b.endDate).diff(dayjs(a.endDate)))}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={item.reason}
                description={formatDateRange(item.startDate, item.endDate)}
              />
            </List.Item>
          )}
        />
      </TabPane>
    </Tabs>
  );
};

export default TempCloseManage;
