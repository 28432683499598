import React, { useState } from "react";
import { Card, Divider, Button, Empty, Skeleton } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useRoleCheck from "utilities/hooks/roleCheck";
import ClinicEntry from "./ClinicEntry";
import EditClinics from "./EditClinics";

const ClinicList = ({ clinics, initials, onChange }) => {
  const navigate = useNavigate();
  const { hasPermission } = useRoleCheck();

  const [editOpen, setEditOpen] = useState(false);

  return (
    <>
      <Card
        title={
          clinics ? (clinics.length > 1 ? "Clinics" : "Clinic") : "Clinics"
        }
        className="mt-2"
        extra={
          hasPermission(
            ["HR.Write", "Sales.Exec", "Admin.Basic"],
            ["Sales.RM"],
            null,
            false
          ) && (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditOpen(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        {!clinics ? (
          <Empty description="No clinics" />
        ) : (
          <>
            {clinics.map((clinic, index) => (
              <div
                key={index}
                onClick={() => navigate(`/clinics/${clinic.clinicNo}`)}
              >
                <ClinicEntry clinic={clinic} initials={initials} />
                {index < clinics.length - 1 && <Divider />}
              </div>
            ))}
          </>
        )}
      </Card>
      <EditClinics
        employee_clinics={clinics}
        initials={initials}
        open={editOpen}
        setOpen={setEditOpen}
        onChange={onChange}
      />
    </>
  );
};

export default ClinicList;
