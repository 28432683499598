import React, { useState } from "react";
import { Input, Typography, Row, Col, Select } from "antd";
import {
  HomeOutlined,
  MailOutlined,
  EnvironmentOutlined,
  PushpinOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { FaCity } from "react-icons/fa";

const { Text } = Typography;
const { Option } = Select;

const AddressControl = ({ value = {}, onChange }) => {
  const [address, setAddress] = useState(value);

  const handleChange = (value, field) => {
    const newAddress = { ...address, [field]: value };
    setAddress(newAddress);
    if (onChange) {
      onChange(newAddress);
    }
  };
  const canadianProvinces = [
    { name: "Alberta", acronym: "AB" },
    { name: "British Columbia", acronym: "BC" },
    { name: "Manitoba", acronym: "MB" },
    { name: "New Brunswick", acronym: "NB" },
    { name: "Newfoundland and Labrador", acronym: "NL" },
    { name: "Nova Scotia", acronym: "NS" },
    { name: "Ontario", acronym: "ON" },
    { name: "Prince Edward Island", acronym: "PE" },
    { name: "Quebec", acronym: "QC" },
    { name: "Saskatchewan", acronym: "SK" },
    { name: "Northwest Territories", acronym: "NT" },
    { name: "Nunavut", acronym: "NU" },
    { name: "Yukon", acronym: "YT" },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Text>Street Number</Text>
          <Input
            prefix={<HomeOutlined />}
            value={address.streetNo}
            onChange={(e) => handleChange(e.target.value, "streetNo")}
            placeholder="Street Number"
          />
        </Col>
        <Col span={12}>
          <Text>Street Name</Text>
          <Input
            prefix={<MailOutlined />}
            value={address.streetName}
            onChange={(e) => handleChange(e.target.value, "streetName")}
            placeholder="Street Name"
          />
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col span={12}>
          <Text>Unit Number</Text>
          <Input
            prefix={<EnvironmentOutlined />}
            value={address.unitNo}
            onChange={(e) => handleChange(e.target.value, "unitNo")}
            placeholder="Unit Number"
          />
        </Col>
        <Col span={12}>
          <Text>City</Text>
          <Input
            prefix={<FaCity />}
            value={address.city}
            onChange={(e) => handleChange(e.target.value, "city")}
            placeholder="City"
          />
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col span={12}>
          <Text>Postal Code</Text>
          <Input
            prefix={<PushpinOutlined />}
            value={address.postalCode}
            onChange={(e) => handleChange(e.target.value, "postalCode")}
            placeholder="Postal Code"
          />
        </Col>
        <Col span={12}>
          <Text>Province</Text>
          <Select
            className="min-w-full"
            suffixIcon={<PushpinOutlined />}
            placeholder="Province"
            value={address.province}
            onChange={(value) => handleChange(value, "province")}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {canadianProvinces.map((province) => (
              <Option key={province.acronym} value={province.acronym}>
                {province.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col span={24}>
          <Text>Notes</Text>
          <Input.TextArea
            prefix={<InfoCircleOutlined />}
            value={address.notes}
            onChange={(e) => handleChange(e.target.value, "notes")}
            placeholder="Additional Notes"
          />
        </Col>
      </Row>
    </div>
  );
};

export default AddressControl;
