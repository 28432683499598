import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Select,
  Descriptions,
  notification,
  Space,
  Row,
  Col,
} from "antd";
import { useParams } from "react-router-dom";
import {
  ShopOutlined,
  CalendarOutlined,
  NumberOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  TagOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import axiosInstance from "utilities/axios";

const { Option } = Select;
const { TextArea } = Input;

function AddEquipmentModal({ isVisible, onClose, onAdd }) {
  const { clinic_no } = useParams();
  const [equipmentTypes, setEquipmentTypes] = useState([]);

  //these states are redundant values but are nessecary to keep responsive design as opposed to just getting the value from the form which is synchronous
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const [workingStatus, setWorkingStatus] = useState(true);
  const [inUseStatus, setInUseStatus] = useState(true);

  const [form] = Form.useForm();

  // const handleValuesChange = (changedValues, allValues) => {
  //     if ('working' in changedValues) {
  //         console.log(changedValues.working)
  //         if (!changedValues.working) {
  //             form.setFieldsValue({ inUse: false });
  //         }
  //     }
  // };

  const currentTypeData = equipmentTypes.find(
    (e) =>
      e.model === form.getFieldValue("model") &&
      e.brand === form.getFieldValue("brand") &&
      e.category === form.getFieldValue("category")
  );
  useEffect(() => {
    function fetchEquipmentTypes() {
      axiosInstance.get("/equipment/types").then((result) => {
        setEquipmentTypes(result.data);
      });
    }
    fetchEquipmentTypes();
  }, []);

  const resetFormAndClose = () => {
    form.resetFields();
    setSelectedBrand(null);
    setSelectedCategory(null);
    setSelectedModel(null);
    if (onClose) {
      // Ensure onClose is provided before calling it
      onClose();
    }
  };

  const handleSubmit = (values) => {
    const { brand, category, model, ...rest } = values; // Destructure out the unnecessary values

    const payload = {
      clinicNo: clinic_no,
      typeId: currentTypeData.id,
      ...rest, // Spread in the rest of the values excluding brand, category, and model
    };

    axiosInstance
      .post("/equipment", payload)
      .then(() => {
        notification.success({
          message: "Equipment Added Successfully",
          description: "The equipment has been added to the database.",
        });
        onAdd();
        onClose();
      })
      .catch((error) => {
        const errorCode = error.response ? error.response.status : 500;
        if (errorCode === 400) {
          notification.error({
            message: "Bad Request",
            description: "Please ensure all fields are filled correctly.",
          });
        } else {
          notification.error({
            message: "Error",
            description: "An error occurred. Please try again later.",
          });
        }
      });
  };

  return (
    <Modal
      title="Add Equipment"
      open={isVisible}
      onCancel={resetFormAndClose}
      onOk={form.submit}
      okText="Add Equipment"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ marginBottom: "20px" }}
        initialValues={{
          condition: true,
          status: true,
        }}
        // onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="category"
          label={
            <Space>
              <TagOutlined /> Category
            </Space>
          }
        >
          <Select
            autoFocus
            onChange={(value) => {
              setSelectedCategory(value);
              form.setFieldsValue({
                category: value,
                brand: null,
                model: null,
              });
            }}
            placeholder="Select a category"
            defaultActiveFirstOption={false}
          >
            {Array.from(new Set(equipmentTypes.map((e) => e.category))).map(
              (category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              )
            )}
          </Select>
        </Form.Item>

        {selectedCategory && (
          <Form.Item
            name="brand"
            label={
              <Space>
                <ShopOutlined /> Brand
              </Space>
            }
          >
            <Select
              visible={form.getFieldValue("category")}
              onChange={(value) => {
                setSelectedBrand(value);
                form.setFieldsValue({ brand: value, model: null });
              }}
              placeholder="Select a brand"
              defaultActiveFirstOption={false}
            >
              {Array.from(
                new Set(
                  equipmentTypes
                    .filter(
                      (e) => e.category === form.getFieldValue("category")
                    )
                    .map((e) => e.brand)
                )
              ).map((brand) => (
                <Option key={brand} value={brand}>
                  {brand}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {selectedBrand && (
          <Form.Item
            name="model"
            label={
              <Space>
                <BarcodeOutlined /> Model
              </Space>
            }
          >
            <Select
              onChange={(value) => {
                setSelectedModel(value);
              }}
              placeholder="Select a model"
              defaultActiveFirstOption={false}
              showSearch={true}
            >
              {Array.from(
                new Set(
                  equipmentTypes
                    .filter(
                      (e) =>
                        e.brand === form.getFieldValue("brand") &&
                        e.category === form.getFieldValue("category")
                    )
                    .map((e) => e.model)
                )
              ).map((model) => (
                <Option key={model} value={model}>
                  {model}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {selectedModel && (
          <Descriptions bordered style={{ marginBottom: "20px" }}>
            <Descriptions.Item label="Description">
              {currentTypeData.description}
            </Descriptions.Item>
            <Descriptions.Item label="Price">
              {currentTypeData.price}
            </Descriptions.Item>
          </Descriptions>
        )}

        <Form.Item
          name="serialNo"
          label={
            <>
              <NumberOutlined /> Serial Number
            </>
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="purchaseDate"
          label={
            <>
              <CalendarOutlined /> Purchase Date
            </>
          }
        >
          <DatePicker />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="condition" valuePropName="checked">
              <Checkbox
                checked={form.getFieldValue("condition")}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setWorkingStatus(isChecked);
                  // If "Working" is unchecked, "In Use" should also be unchecked
                  if (!isChecked) {
                    setInUseStatus(false);
                    form.setFieldsValue({ status: false });
                  }
                }}
              >
                Working
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="status" valuePropName="checked">
              <Checkbox
                checked={form.getFieldValue("status") && workingStatus}
                disabled={!workingStatus}
                onChange={(e) => {
                  setInUseStatus(e.target.checked);
                }}
              >
                In Use
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="notes"
          label={
            <Space>
              <FileTextOutlined /> Notes
            </Space>
          }
        >
          <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddEquipmentModal;
