import React, { useState } from "react";
import { Checkbox, TimePicker, Typography } from "antd";
import dayjs from "dayjs";

const { Text } = Typography;

const HoursControl = ({ value, onChange }) => {
  const [hours, setHours] = useState(value || []);

  const handleCheckboxChange = (index) => {
    const newHours = [...hours];
    newHours[index].isOpen = !newHours[index].isOpen;
    setHours(newHours);
    onChange && onChange(newHours);
  };

  const handleTimeChange = (times, index) => {
    const newHours = [...hours];
    newHours[index].openTime = times[0].format("HH:mm");
    newHours[index].closeTime = times[1].format("HH:mm");
    setHours(newHours);
    onChange && onChange(newHours);
  };

  return (
    <div>
      {hours.map((hour, index) => (
        <div key={index} className="flex mb-4">
          <Text className="w-28 pr-4 text-right">
            {dayjs().weekday(hour.dayOfWeek).format("dddd")}
          </Text>
          <Checkbox
            className="w-16"
            checked={hour.isOpen}
            onChange={() => handleCheckboxChange(index)}
          />
          {hour.isOpen ? (
            <TimePicker.RangePicker
              format="h:mm a"
              value={[
                hour.openTime ? dayjs(hour.openTime, "HH:mm") : null,
                hour.closeTime ? dayjs(hour.closeTime, "HH:mm") : null,
              ]}
              allowClear={false}
              showNow={false}
              minuteStep={15}
              use12Hours={true}
              placeholder={["Open Time", "Close Time"]}
              onChange={(times) => handleTimeChange(times, index)}
              order={true}
              className="flex-grow"
            />
          ) : (
            <span className="flex-grow text-center">Closed</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default HoursControl;
