import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchRoles } from "utilities/redux/slices/rolesSlice"; // Import your fetchRoles action
import { Result, Button } from "antd";

const ForbiddenScreen = () => {
  const navigate = useNavigate(); // Hook for navigation
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.roles.data); // Get roles data from Redux store
  useEffect(() => {
    dispatch(fetchRoles()); // Fetch roles on component mount
  }, [dispatch]); // Empty dependency array ensures this runs only on mount

  useEffect(() => {
    if (rolesData.roles.includes("Test")) {
      navigate("/clinics");
    }
  }, [rolesData, navigate]); // Reload page if roles data changes

  const customIcon = <img src="/HLBubble.svg" alt="Forbidden" />;

  return (
    <div className="flex justify-center items-center h-screen">
      <Result
        icon={customIcon}
        title="No Testing Access"
        subTitle="Sorry, you are not authorized to access the testing version of Clinic Central. If you should have access, please contact data@hearinglife.ca"
      />
    </div>
  );
};

export default ForbiddenScreen;
