import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Typography, Flex } from "antd";
dayjs.extend(weekday);
dayjs.extend(customParseFormat);

const { Text } = Typography;

const formatClinicHours = (hours) => {
  if (!hours) {
    return null;
  }
  return (
    <Flex vertical>
      {hours.map((hour, index) => (
        <div key={index} className="flex h-6">
          <Text type="secondary" className="w-28 pr-4 text-right">
            {dayjs().weekday(hour.dayOfWeek).format("dddd")}:
          </Text>
          <Text type="secondary" className="m-0">
            {hour.isOpen
              ? `${dayjs(hour.openTime, "H:mm").format("h:mm A")} - ${dayjs(
                  hour.closeTime,
                  "H:mm"
                ).format("h:mm A")}`
              : "Closed"}
          </Text>
        </div>
      ))}
    </Flex>
  );
};

export default formatClinicHours;
