import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  DatePicker,
  Input,
  Select,
  Typography,
  Flex,
  notification,
} from "antd";
import {
  EditOutlined,
  CodeOutlined,
  HomeOutlined,
  CalendarOutlined,
  ShoppingCartOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

import useRoleCheck from "utilities/hooks/roleCheck";
import axiosInstance from "utilities/axios";
import formatDate from "utilities/formats/formatDate";

let timeout;
let currentValue;

const { Text } = Typography;

const fetchClinics = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  const fetchActualData = () => {
    axiosInstance
      .get("/clinics", {
        params: {
          search: value,
          status: "Open", // or 'Closed (Temp)'
          primaryClinic: true,
        },
      })
      .then((response) => {
        if (currentValue === value) {
          const data = response.data.map((clinic) => ({
            clinic: clinic.clinic,
            clinic_name: `${clinic.clinic} - ${clinic.clinicName}`,
          }));
          callback(data);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error fetching clinics",
          description: error.message,
        });
      });
  };

  if (value) {
    timeout = setTimeout(fetchActualData, 300);
  } else {
    callback([]);
  }
};

const CorporateInformationCard = ({
  code,
  openingDate,
  closingDate,
  acquisitionDate,
  homeClinicNumber,
  clinicNo,
  bpSystem,
  bpLocationId,
  onChange,
}) => {
  const { hasPermission } = useRoleCheck();

  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    code,
    openingDate,
    closingDate,
    acquisitionDate,
    homeClinicNumber,
    bpSystem,
    bpLocationId,
  });

  useEffect(() => {
    setModalData({
      code,
      openingDate,
      closingDate,
      acquisitionDate,
      homeClinicNumber,
      bpSystem,
      bpLocationId,
    });
  }, [
    code,
    openingDate,
    closingDate,
    acquisitionDate,
    homeClinicNumber,
    bpSystem,
    bpLocationId,
  ]);

  const bpSystems = [
    { value: 1, label: "1 - Ontario" },
    { value: 53, label: "53 - Costal" },
    { value: 63, label: "63 - Praries" },
  ];
  const editPermission = hasPermission(
    ["Admin.Basic", "BP.Admin", "Finance.Write"],
    ["Sales.RM"],
    clinicNo,
    false
  );
  const handleSearch = (newValue) => {
    fetchClinics(newValue, setData);
  };

  const updateCorporateInfo = () => {
    // Update logic here
    axiosInstance
      .patch(`/clinics/${clinicNo}`, {
        ...(modalData.code && { code: modalData.code }),
        ...(modalData.openingDate && {
          openingDate: dayjs(modalData.openingDate).format("YYYY-MM-DD"),
        }),
        ...(modalData.acquisitionDate && {
          acquisitionDate: dayjs(modalData.acquisitionDate).format(
            "YYYY-MM-DD"
          ),
        }),
        ...(modalData.homeClinicNumber && {
          homeClinicNo: modalData.homeClinicNumber,
        }),
        ...(modalData.bpSystem && { blueprintSystem: modalData.bpSystem }),
        ...(modalData.bpLocationId && {
          blueprintLocationId: modalData.bpLocationId,
        }),
      })
      .then((response) => {
        notification.success({
          message: "Corporate Information Updated",
        });
        onChange();
        setIsModalVisible(false);
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Corporate Information",
          description: err.response.data.message,
        });
      });

    setIsModalVisible(false);
  };

  return (
    <div>
      <Card
        title="Corporate Information"
        style={{
          minWidth: 280,
          width: "100%",
          minHeight: 150,
          height: "fit-content",
        }}
        bodyStyle={{ marginLeft: 20, padding: 20 }}
        extra={
          editPermission && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          )
        }
        className="mt-4"
      >
        <Flex vertical gap="small">
          {hasPermission(["BP", "Admin.Basic"]) && (
            <>
              {bpLocationId && (
                <Text type="secondary">
                  <CodeOutlined /> Blueprint Location ID: {bpLocationId}
                </Text>
              )}
            </>
          )}
          {hasPermission(["BP", "Admin.Basic"]) && bpSystem && (
            <Text type="secondary">
              <CodeOutlined /> Blueprint System:{" "}
              {bpSystems.find((system) => system.value === bpSystem).label}
            </Text>
          )}
          {code &&
            hasPermission(
              ["Finance", "IT", "Sales.Exec"],
              ["Sales.RM", "Sales.Staff"],
              clinicNo
            ) && (
              <Text type="secondary">
                <CodeOutlined /> Code: {code}
              </Text>
            )}
          {homeClinicNumber && (
            <Text type="secondary">
              <HomeOutlined /> Home Clinic Number: {homeClinicNumber}
            </Text>
          )}
          {openingDate && (
            <Text type="secondary">
              <CalendarOutlined /> Open Date: {formatDate(openingDate)}
            </Text>
          )}
          {acquisitionDate && (
            <Text type="secondary">
              <ShoppingCartOutlined /> Acquisition Date:{" "}
              {formatDate(acquisitionDate)}
            </Text>
          )}
          {closingDate && (
            <Text type="secondary">
              <CloseCircleOutlined style={{ color: "red" }} /> Close Date:{" "}
              {formatDate(closingDate)}
            </Text>
          )}
        </Flex>
      </Card>

      <Modal
        title="Edit Corporate Information"
        open={isModalVisible}
        onOk={updateCorporateInfo}
        okText="Save"
        onCancel={() => {
          setIsModalVisible(false);
          setModalData({
            code,
            openingDate,
            closingDate,
            acquisitionDate,
            homeClinicNumber,
            bpSystem,
            bpLocationId,
          });
        }}
      >
        {hasPermission(["Finance.Write"], ["Sales.RM"], clinicNo, false) && (
          <div className="flex flex-col">
            <label className="text-sm text-gray-500">Code</label>
            <Input
              className="w-full"
              value={modalData.code}
              onChange={(e) => {
                const inputValue = e.target.value.slice(0, 5);
                setModalData({ ...modalData, code: inputValue });
              }}
            />
          </div>
        )}
        {hasPermission(["Admin.Basic", "BP.Admin"]) && (
          <div className="flex flex-col mt-4">
            <label className="text-sm text-gray-500">
              Blueprint Location ID
            </label>
            <Input
              className="w-full"
              value={modalData.bpLocationId}
              onChange={(e) => {
                setModalData({ ...modalData, bpLocationId: e.target.value });
              }}
            />
          </div>
        )}
        {hasPermission(["Admin.Basic", "BP.Admin"]) && (
          <div className="flex flex-col mt-4">
            <label className="text-sm text-gray-500">Blueprint System</label>
            {/* either system 1 - Ontairo, 53- Costal, or 63 Praries */}
            <Select
              value={modalData.bpSystem}
              placeholder="Select a system"
              defaultActiveFirstOption={false}
              onChange={(e) => {
                setModalData({ ...modalData, bpSystem: e });
              }}
              style={{ width: "100%" }}
              options={bpSystems}
            />
          </div>
        )}

        {hasPermission(["Admin.Basic"], ["Sales.RM"], clinicNo, false) && (
          <>
            <div className="flex flex-col mt-4">
              <label className="text-sm text-gray-500">
                Home Clinic Number
              </label>
              <Select
                showSearch
                value={modalData.homeClinicNumber}
                placeholder="Search for a clinic"
                defaultActiveFirstOption={false}
                notFoundContent={null}
                filterOption={false}
                onSearch={handleSearch}
                allowClear={true}
                onChange={(e) => {
                  setModalData({
                    ...modalData,
                    homeClinicNumber: e === undefined ? null : e,
                  });
                }}
                style={{ width: "100%" }}
                options={(data || []).map((d) => ({
                  value: d.clinic,
                  label: d.clinic_name,
                }))}
              />
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-sm text-gray-500">Opening Date</label>
              <DatePicker
                className="w-full"
                value={modalData.openingDate && dayjs(modalData.openingDate)}
                onChange={(date) =>
                  setModalData({ ...modalData, openingDate: date })
                }
              />
            </div>

            <div className="flex flex-col mt-4">
              <label className="text-sm text-gray-500">Acquisition Date</label>
              <DatePicker
                className="w-full"
                value={
                  modalData.acquisitionDate && dayjs(modalData.acquisitionDate)
                }
                onChange={(date) =>
                  setModalData({ ...modalData, acquisitionDate: date })
                }
              />
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CorporateInformationCard;
