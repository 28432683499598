// 403.js
import React from "react";
import { Result, Button } from "antd";

const ForbiddenScreen = () => {
  const customIcon = <img src="/HLBubble.svg" alt="Forbidden" />;
  return (
    <div className="flex justify-center items-center h-screen">
      <Result
        icon={customIcon}
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" href="/clinics">
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default ForbiddenScreen;
