import { React, useState, useEffect } from "react";
import { Button, Table, Input, Modal, Select, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utilities/axios";
import Footer from "components/Footer";

const { Option } = Select;
const { Text } = Typography;
const Regions = () => {
  const navigate = useNavigate();
  const [divisions, setDivisions] = useState([]);
  const [search, setSearch] = useState("");

  const [divisionStatus, setStatus] = useState(["Active"]);
  const [statusOptions, setStatusOptions] = useState(["Active", "Inactive"]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFilterButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const columns = [
    {
      title: "Division Code",
      dataIndex: "divisionCode",
      sorter: (a, b) => a.divisionCode.localeCompare(b.divisionCode),
    },
    {
      title: "Division Name",
      dataIndex: "divisionName",
      sorter: (a, b) => a.divisionName.localeCompare(b.divisionName),
    },
    {
      title: "DSD",
      dataIndex: "dsdName",
      sorter: (a, b) => a.dsdName.localeCompare(b.dsdName),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    // {
    //   title: "Detail",
    //   dataIndex: "",
    //   key: "x",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => {
    //         navigate(`/divisions/${record.divisionCode}`);
    //       }}
    //       disabled
    //     >
    //       Detail
    //     </Button>
    //   ),
    // },
  ];

  //get page content
  useEffect(() => {
    axiosInstance
      .get("/divisions", {
        params: {
          search: search,
          status: divisionStatus,
        },
      })
      .then((response) => {
        setDivisions(response.data);
      });
  }, [search, divisionStatus]);

  return (
    <>
      <div className="p-8">
        <div className="flex">
          <Input.Search
            placeholder="Search Divisions"
            allowClear
            size="large"
            suffix="" // Remove the search icon
            onChange={handleSearch}
            className="mr-2"
          />
          {/* <Button
            type="primary"
            size="large"
            icon={<FilterOutlined />}
            onClick={handleFilterButtonClick}
          >
            Filter
          </Button> */}
        </div>

        <div className="mt-4">
          <Table
            columns={columns}
            dataSource={divisions}
            rowKey="divisionCode"
          />
          <Footer />
        </div>
      </div>
      <Modal
        title="Filter Options"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <div className="flex flex-col space-y-4">
          <Text className="mb-0 mt-2">Division Status</Text>
          <Select
            mode="multiple"
            placeholder="Division Status"
            value={divisionStatus}
            onChange={setStatus}
          >
            {statusOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
};

export default Regions;
