import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utilities/axios";
import Footer from "components/Footer";
import { Spin } from "antd";

const RegionDetails = () => {
  const [region, setRegion] = useState(null);
  //list variable of employees
  let { region_no } = useParams();

  useEffect(() => {
    const getClinicDetails = async () => {
      axiosInstance.get(`/regions/${region_no}`).then(async (response) => {
        //for each employee in response add photo and profile url
        for (let i = 0; i < response.data.employees.length; i++) {
          const user_id = response.data.employees[i].initials;
          // fetch employee photo
          const profileUrl = `/employees/${user_id}`;
          // add photo and profile url to employee

          response.data.employees[i].profileUrl = profileUrl;
        }

        setRegion(response.data); // Assuming data is an array of objects.
      });
    };

    getClinicDetails();
  }, [region_no]);

  if (!region) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Footer />
    </div>
  );
};

export default RegionDetails;
