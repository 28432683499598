import React from "react";
import { Form, Modal, Typography, notification } from "antd";
import axiosInstance from "utilities/axios";
import CredentialEditComponent from "./EditCredForm";

const { Title } = Typography;

const CredentialAddComponent = ({
  visible,
  setVisible,
  clinicNo,
  onCreate,
  defaultCategory,
}) => {
  const [form] = Form.useForm();
  const createCredential = (values) => {
    //add clinicNo to values
    values.clinicNo = clinicNo;
    axiosInstance
      .post("/credentials", values)
      .then((response) => {
        onCreate();
        form.resetFields();
        setVisible(false);
        notification.success({
          message: "Success!",
          description: "Credential has been added.",
        });
      })
      .catch((error) => {
        console.log(error.response);
        notification.error({
          message: "Error!",
          description: "There was an error adding the credential.",
        });
      });
  };
  return (
    <Modal
      open={visible}
      //start at 2.5%
      style={{ maxHeight: "95vh" }}
      title={<Title level={3}>Add Credential</Title>}
      okText="Add"
      centered
      cancelText="Cancel"
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      onOk={() => {
        form.submit();
      }}
    >
      <CredentialEditComponent
        form={form}
        onFinish={createCredential}
        defaultCategory={defaultCategory}
      />
    </Modal>
  );
};

export default CredentialAddComponent;
