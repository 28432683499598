// PropertyInfoCard.js
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  DatePicker,
  Input,
  notification,
  Form,
  Flex,
  Typography,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  UserOutlined,
  FireOutlined,
} from "@ant-design/icons";
import useRoleCheck from "utilities/hooks/roleCheck";
import axiosInstance from "utilities/axios";
import formatDate from "utilities/formats/formatDate";
import dayjs from "dayjs";

const { Text } = Typography;
const PropertyInfoCard = ({ propertyInfo, clinicNo, onChange }) => {
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const componentVisible = hasPermission(
    ["Operations", "Finance", "Sales.Exec"],
    ["Sales.RM"],
    clinicNo
  );

  const updatePropertyInfo = (values) => {
    axiosInstance
      .patch(`/clinics/${clinicNo}`, {
        lastFireInspection: values.lastFireInspection,
        propertyInformation: {
          leaseStart: values.leaseStart,
          leaseEnd: values.leaseEnd,
          pmName: values.pmName,
          pmDesc: values.pmDesc,
        },
      })
      .then(() => {
        notification.success({
          message: "Property Information Updated",
        });
        onChange();
        setIsModalVisible(false);
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Property Information",
          description: err.response.data.message,
        });
      });
  };

  if (!propertyInfo || !componentVisible) return null;
  return (
    <>
      <Card
        title="Property Information"
        style={{ width: "100%", minHeight: 100, height: "fit-content" }}
        bodyStyle={{ marginLeft: 20, padding: 20 }}
        extra={
          hasPermission([], ["Sales.RM"], clinicNo, false) && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          )
        }
      >
        <Flex vertical gap="small">
          {propertyInfo.leaseStart && (
            <Text type="secondary">
              <CalendarOutlined /> Lease Start:{" "}
              {formatDate(propertyInfo.leaseStart)}
            </Text>
          )}
          {propertyInfo.leaseEnd && (
            <Text type="secondary">
              <CalendarOutlined /> Lease End:{" "}
              {formatDate(propertyInfo.leaseEnd)}
            </Text>
          )}
          {propertyInfo.pmName && (
            <Text type="secondary">
              <UserOutlined /> PM Name: {propertyInfo.pmName}
            </Text>
          )}
          {propertyInfo.pmDesc && (
            <Text type="secondary">
              <UserOutlined /> PM Details: {propertyInfo.pmDesc}
            </Text>
          )}
          {propertyInfo.lastFireInspection && (
            <Text type="secondary">
              <FireOutlined /> Last Fire Inspection:{" "}
              {propertyInfo.lastFireInspection}
            </Text>
          )}
        </Flex>
      </Card>
      <Modal
        title="Edit Property Information"
        open={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          setIsModalVisible(false);
        }}
        okText="Save"
      >
        <Form
          form={form}
          onFinish={updatePropertyInfo}
          labelAlign="left"
          labelCol={{ span: 16 }}
          colon={false}
          initialValues={{
            leaseStart:
              propertyInfo.leaseStart && dayjs(propertyInfo.leaseStart),
            leaseEnd: propertyInfo.leaseEnd && dayjs(propertyInfo.leaseEnd),
            lastFireInspection:
              propertyInfo.lastFireInspection &&
              dayjs(propertyInfo.lastFireInspection),
            pmName: propertyInfo.pmName,
            pmDesc: propertyInfo.pmDesc,
          }}
        >
          <Form.Item name="leaseStart" label="Lease Start">
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="leaseEnd"
            label="Lease End"
            dependencies={["leaseStart"]}
            rules={[
              {
                validator(_, value) {
                  if (
                    !value ||
                    dayjs(value).isSameOrAfter(form.getFieldValue("leaseStart"))
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Lease end date must be after or the same as lease start date."
                    )
                  );
                },
                message: "Lease end date must be after the lease start date.",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item name="lastFireInspection" label="Last Fire Inspection">
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="pmName"
            label="Property Manager Name"
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pmDesc"
            label="Property Manager Details"
            labelCol={{ span: 24 }}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PropertyInfoCard;
