import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Carousel,
  Typography,
  Image,
  Modal,
  Upload,
  Input,
  message,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";

const frameStyle = {
  height: "400px",
};

const { Paragraph } = Typography;

function PhotosCard({ clinicNo }) {
  const { hasPermission } = useRoleCheck();

  const [photos, setPhotos] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileDescription, setFileDescription] = useState("");

  const fetchPhotos = useCallback(() => {
    axiosInstance
      .get(`/clinics/${clinicNo}/photos`)
      .then((response) => {
        setPhotos(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [clinicNo]);

  useEffect(() => {
    fetchPhotos();
  }, [clinicNo, fetchPhotos]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      try {
        await axiosInstance.delete(`/clinics/${clinicNo}/photos/${file.id}`);
        message.success("Photo deleted successfully");
        setPhotos(photos.filter((p) => p.id !== file.id));
      } catch (error) {
        message.error("Delete failed");
        setPhotos([...photos]);
      }
    }
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("photo", currentFile);
    formData.append("fileName", fileName);
    formData.append("fileDescription", fileDescription);

    axiosInstance
      .post(`/clinics/${clinicNo}/photos`, formData)
      .then((response) => {
        message.success("Photo uploaded successfully");
        fetchPhotos();
      })
      .catch((err) => {
        console.log(err);
        message.error("Upload failed");
      });
    setUploadModalVisible(false);
  };

  const uploadProps = {
    listType: "picture-card",
    fileList: photos,
    onChange: handleFileChange,
    beforeUpload: (file) => {
      setCurrentFile(file);
      setUploadModalVisible(true);
      // Return false to stop automatic upload
      return false;
    },
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const photoDisplay = (photo) => {
    return (
      <div className="relative">
        <div
          style={frameStyle}
          className="flex justify-center items-center bg-[#F1F1F1]"
        >
          <Image
            src={photo.url}
            alt={photo.photoName}
            style={{
              maxHeight: "400px",
              maxWidth: "100%",
              objectFit: "contain",
            }}
            preview={false}
          />
        </div>
        <Paragraph className="absolute bottom-10 w-full text-center bg-black bg-opacity-50 text-white p-2 opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out">
          {photo.photoName}: {photo.photoDescription}
        </Paragraph>
      </div>
    );
  };
  return (
    <Card
      title="Photos"
      extra={
        hasPermission(
          ["Marketing.Write", "Admin.Basic"],
          ["Sales.RM", "Sales.Staff"],
          clinicNo,
          false
        ) && <EditOutlined onClick={showModal} />
      }
    >
      {!photos || photos.length === 0 ? (
        <div className="h-400px text-center">
          <h3>No Photos</h3>
        </div>
      ) : photos.length === 1 ? (
        photoDisplay(photos[0])
      ) : (
        <Carousel autoplay autoplaySpeed={10000}>
          {photos.map((photo, index) => (
            <div key={index}>{photoDisplay(photo)}</div>
          ))}
        </Carousel>
      )}
      <Modal
        title="Manage Photos"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Upload {...uploadProps}>
          {photos.length >= 8 ? null : uploadButton}
        </Upload>
      </Modal>
      <Modal
        title="Upload Photo"
        open={uploadModalVisible}
        onCancel={() => setUploadModalVisible(false)}
        onOk={handleUpload}
      >
        <Input
          placeholder="Enter file name"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
        <Input.TextArea
          placeholder="Enter file description"
          value={fileDescription}
          onChange={(e) => setFileDescription(e.target.value)}
        />
      </Modal>
    </Card>
  );
}
// const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
export default PhotosCard;
