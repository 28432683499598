import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Add custom parsing plugin
dayjs.extend(customParseFormat);

const formatDate = (date, type = "long") => {
  // Define potential input formats

  if (date === null) {
    return null;
  }
  const inputFormats = [
    "MMM DD YYYY h:mmA",
    "YYYY-MM-DD",
    "YYYY-MM-DDTHH:mm:ssZ",
  ];

  let dayjsDate;

  if (typeof date === "string") {
    // Try parsing with each format
    for (const format of inputFormats) {
      const parsedDate = dayjs(date, format);
      if (parsedDate.isValid()) {
        dayjsDate = parsedDate;
        break;
      }
    }

    // If none of the formats matched, fall back to default parsing
    dayjsDate = dayjsDate || dayjs(date);
  } else {
    dayjsDate = dayjs(date);
  }

  // Format the date based on the type
  if (type === "long") {
    return dayjsDate.format("MMMM DD, YYYY");
  }
  return dayjsDate.format("YYYY-MM-DD");
};

export default formatDate;
