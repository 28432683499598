// src/redux/slices/clinicsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching clinics data
export const fetchClinics = createAsyncThunk(
  "clinics/fetchClinics",
  async (filters) => {
    const response = await axiosInstance.get("/clinics", { params: filters });
    return response.data;
  }
);

const clinicsSlice = createSlice({
  name: "clinics",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClinics.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClinics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // Changed from state.clinics to state.data
      })
      .addCase(fetchClinics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default clinicsSlice.reducer;
