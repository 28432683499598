// src/redux/slices/clinicsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching clinics data
export const fetchTypes = createAsyncThunk(
  "credentials/fetchTypes",
  async () => {
    const response = await axiosInstance.get("/credentials/types");
    return response.data;
  }
);

const credentialTypeSlice = createSlice({
  name: "credentialCategories",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default credentialTypeSlice.reducer;
