import React, { useState, useEffect, useCallback } from "react";

import { Modal, Typography, Button, Card, Avatar, Skeleton } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  MinusOutlined,
  UserOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { BiFont, BiPaint, BiWorld } from "react-icons/bi";
import axiosInstance from "utilities/axios";
import { useSelector, useDispatch } from "react-redux";
import ClinicList from "./components/ClinicList";
import { fetchEmployeeData } from "utilities/redux/slices/selectedEmployeeSlice";
import useRoleCheck from "utilities/hooks/roleCheck";

const { Title, Text, Link, Paragraph } = Typography;
const EmployeeDetails = ({ visible, onClose, user_id }) => {
  const { hasPermission } = useRoleCheck();

  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee.employeeData);
  const employeeStatus = useSelector((state) => state.employee.status);
  // const [employee, setEmployee] = useState(null);
  const [photo, setPhoto] = useState(null);

  const getEmployee = useCallback(() => {
    if (user_id) {
      dispatch(fetchEmployeeData(user_id));
    }
  }, [user_id, dispatch]);

  useEffect(() => {
    if (!user_id) {
      setPhoto(null);
      return;
    } else {
      axiosInstance
        .get(
          `employees/${user_id}/photo`,
          //it gets sent as an image.jpg file
          { responseType: "blob" }
        )
        .then((response) => {
          if (response.status === 204) {
            setPhoto(null);
          } else {
            const url = URL.createObjectURL(response.data);
            setPhoto(url);
          }
        });
    }
    getEmployee();
  }, [user_id, getEmployee]);

  if (!user_id) {
    return null;
  }

  if (!employee || employeeStatus === "loading") {
    return (
      <Modal open={visible} onCancel={onClose} footer={null} width={1000}>
        <Skeleton active />
      </Modal>
    );
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case "Active":
        return <CheckCircleOutlined style={{ color: "green" }} />;
      case "Inactive":
        return <CloseCircleOutlined style={{ color: "red" }} />;
      case "On Leave":
        return <MinusOutlined style={{ color: "orange" }} />;
      default:
        return null;
    }
  };
  const getLanguageIcon = (language) => {
    switch (language) {
      case "English":
        return <BiFont />;
      case "Chinese":
        return <BiPaint />;
      case "French":
        return <BiFont />;
      // Add more cases as required
      default:
        return <BiWorld />;
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={
        hasPermission(["Admin.Basic", "Sales.Exec", "Sales.RM", "HR"]) && (
          <Link
            type="secondary"
            underline
            target="_blank"
            rel="noopener noreferrer"
            href="https://demant.sharepoint.com/sites/HLCanada/Home/rms/Shared%20Documents/Human%20Resources%20and%20Training/Employee%20Change%20Form.pdf"
          >
            Employee Change Request Form
          </Link>
        )
      }
      width={1000}
      style={{ top: 20 }}
    >
      <div className="flex space-x-4">
        <div className="w-1/4 space-y-4">
          <Avatar size={128} src={photo} icon={!photo && <UserOutlined />} />
          {employee.phone && (
            <p>
              <Text
                copyable={{
                  text: employee.phone,
                }}
              >
                <PhoneOutlined /> {employee.phone}
              </Text>
            </p>
          )}
          {employee.email && (
            <p>
              <Text
                copyable={{
                  text: employee.email,
                }}
              >
                <MailOutlined /> {employee.email}
              </Text>
            </p>
          )}
          {employee.hrProfileLink && (
            <Button href={employee.hrProfileLink}>HR Profile</Button>
          )}
          {employee.languages && employee.languages.length > 0 && (
            <div>
              Languages: <br />{" "}
              {employee.languages.map((lang, index) => (
                <div key={index}>
                  {getLanguageIcon(lang.language)} {lang.language}:{" "}
                  {lang.proficiency}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-3/4 space-y-4">
          <Title level={2}>
            {employee.firstName} {employee.lastName}{" "}
            {getStatusIcon(employee.status)}
          </Title>
          <div className="flex space-x-4">
            <div className="w-1/2 space-y-2">
              {employee.jobTitle && <div>Job Title: {employee.jobTitle}</div>}
              {employee.department && (
                <div>Department: {employee.department}</div>
              )}
              {employee.managerName && (
                <div>
                  Manager: {employee.managerName} ({employee.managerInitials})
                </div>
              )}
              {employee.jobDescription && (
                <div>Job Description: {employee.jobDescription}</div>
              )}
              {employee.biography && <div>Biography: {employee.biography}</div>}
              {employee.subjectMatterExpert && (
                <div>Subject Matter Expert: {employee.subjectMatterExpert}</div>
              )}
            </div>
            <div className="w-1/2 space-y-2">
              {employee.manager_name && (
                <div>Manager: {employee.manager_name}</div>
              )}
              {employee.location && <div>Location: {employee.location}</div>}
              {employee.openToRelocating && (
                <div>Open to relocating: {employee.relocationNotes}</div>
              )}
            </div>
          </div>
          <ClinicList
            clinics={employee.clinics}
            initials={user_id}
            onChange={getEmployee}
          />
          {employee.hpInfo && (
            <Card title="HP Information" className="mt-4">
              {Object.entries(employee.hpInfo).map(([key, value]) => (
                <Paragraph key={key}>
                  <FileTextOutlined /> {key}: {value}
                </Paragraph>
              ))}
            </Card>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeDetails;
