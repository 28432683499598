import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, notification, Button } from "antd";
import { useDispatch } from "react-redux";

import { fetchBrands } from "utilities/redux/slices/brandsSlice";
import axiosInstance from "utilities/axios";

import BrandEdit from "./BrandEdit";

const BrandAddModal = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const addBrand = (values) => {
    const formData = new FormData();
    if (values.brandName != null) {
      formData.append("brandName", values.brandName);
    }

    if (values.brandDescription != null) {
      formData.append("brandDescription", values.brandDescription);
    }

    // Assuming brandLogo is a file, check if it's not null and is a File object
    if (values.brandLogo && values.brandLogo instanceof File) {
      formData.append("brandLogo", values.brandLogo);
    }
    axiosInstance
      .post(`/brands`, formData)
      .then((response) => {
        dispatch(fetchBrands());
        form.resetFields();
        notification.success({
          message: "Success",
          description: "Brand added successfully",
        });
        onClose();
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  };
  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      size="large"
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
              form.resetFields();
            }}
          >
            Cancel
          </Button>

          <Button onClick={form.submit}>Create</Button>
        </>
      }
    >
      <BrandEdit form={form} onSubmit={addBrand} />
    </Modal>
  );
};

export default BrandAddModal;
