import React from "react";
import { Space, Typography, Divider } from "antd";

const { Text } = Typography;

const InsurerDisplay = ({ insurer }) => {
  return (
    <>
      <Space direction="vertical" size="small">
        {insurer.insurerType && (
          <Text>
            <Text strong>Type:</Text> {insurer.insurerType}
          </Text>
        )}
        {insurer.insurerGroup && (
          <Text>
            <Text strong>Group:</Text> {insurer.insurerGroup}
          </Text>
        )}
        {insurer.insurerSimpleType && (
          <Text>
            <Text strong>Simple Type:</Text> {insurer.insurerSimpleType}
          </Text>
        )}
      </Space>
    </>
  );
};

export default InsurerDisplay;
