import React, { useEffect, useState } from "react";
import {
  Card,
  Popover,
  Image,
  Typography,
  Modal,
  Button,
  Select,
  notification,
  message,
  Popconfirm,
  Skeleton,
  DatePicker,
} from "antd";
import { useSelector } from "react-redux";
import axiosInstance from "utilities/axios";
import { EditOutlined } from "@ant-design/icons";
import { fetchBrands } from "utilities/redux/slices/brandsSlice";
import { useDispatch } from "react-redux";
import useRoleCheck from "utilities/hooks/roleCheck";
import dayjs from "dayjs";
const { Text } = Typography;

function BrandCard({ brand, clinicNo, onChange }) {
  const dispatch = useDispatch();
  const { hasPermission } = useRoleCheck();
  const brands = useSelector((state) => state.brands.data);
  const fullBrand = (id) => brands.find((b) => b.brandId === id);

  const sortedBrands = [...brand].sort((a, b) =>
    dayjs(b.rebrandDate).diff(dayjs(a.rebrandDate))
  );

  // const sortedBrands = [];
  // Find the latest brand that has a rebrandDate less than or equal to today or if there is only one brand
  const currentBrand = sortedBrands.find((brand, index, array) =>
    brand.rebrandDate === null
      ? array.length === 1 // If there's only one brand and the rebrand date is null, it's the current brand
      : dayjs(brand.rebrandDate).isSameOrBefore(dayjs())
  );

  const [brandDetails, setBrandDetails] = useState(null);
  const [brandImage, setBrandImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(currentBrand?.id);
  const [selectedDate, setSelectedDate] = useState(null);

  const showModal = () => {
    dispatch(fetchBrands({}));
    setIsModalVisible(true);
  };

  const handleSubmit = () => {
    if (!selectedDate) {
      message.error("Please select a date");
      return;
    }
    if (!selectedBrand || selectedBrand === currentBrand?.id) {
      message.error("Please select a brand different from the current brand");
      return;
    }

    axiosInstance
      .put(`/clinics/${clinicNo}/brand`, {
        brandId: selectedBrand,
        rebrandDate: selectedDate,
      })
      .then((res) => {
        notification.success({
          message: "Clinic Brand Update Added",
        });
        onChange();
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Clinic Brand",
          description: err.response.data.message,
        });
        console.log(err);
      });
    setIsModalVisible(false); // Close modal after submission
  };

  useEffect(() => {
    if (!brand) return;
    const fetchBrandDetails = async () => {
      try {
        const brandResponse = await axiosInstance.get(
          `/brands/${currentBrand?.id}`
        );
        const logoResponse = await axiosInstance.get(
          `/brands/${currentBrand?.id}/logo`
        );

        setBrandDetails(brandResponse.data);
        setBrandImage(logoResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBrandDetails();
  }, [currentBrand?.id, brand]);

  const renderPreviousBrands = () => {
    const previousBrands = sortedBrands.filter(
      (brand) => brand.id !== currentBrand.id
    );
    return (
      <div>
        {previousBrands.map((pBrand, index) => (
          <Text key={index} className="block">
            {fullBrand(pBrand.id)?.brandName}
            {pBrand.rebrandDate
              ? ` - ${dayjs(pBrand.rebrandDate).format("YYYY-MM-DD")}`
              : ""}
          </Text>
        ))}
      </div>
    );
  };

  if (!brandDetails) {
    return null;
  }

  return (
    <>
      <Card
        title="Brand"
        className="w-fill min-h-200 max-h-fit min-w-300"
        bodyStyle={{ paddingTop: 4 }}
        extra={
          <Button
            type="link"
            icon={
              hasPermission(
                ["Marketing.Write", "Admin.Basic"],
                ["Sales.RM"],
                clinicNo,
                false
              ) && <EditOutlined />
            }
            onClick={showModal}
          />
        }
      >
        <Text>{brandDetails.brandName}</Text>
        <React.Suspense
          fallback={
            <div className="pt-4">
              <Skeleton.Image style={{ width: "100px", height: "100px" }} />
            </div>
          }
        >
          {brandImage.url ? (
            <Image
              className="pt-4"
              alt={brandDetails.brandName}
              src={brandImage.url}
            />
          ) : null}
        </React.Suspense>
        <div className="pt-2">
          <Popover
            content={renderPreviousBrands}
            title="Previous Brands"
            trigger="hover"
          >
            <Text
              style={{
                textDecoration: "underline",
                color: "grey",
                cursor: "pointer",
              }}
            >
              Previous Brands
            </Text>
          </Popover>
        </div>
      </Card>
      <Modal
        title="Rebrand Clinic"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>,
          <Popconfirm
            title="Are you sure to rebrand the clinic? This cannot be undone"
            onConfirm={handleSubmit}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Ok</Button>
          </Popconfirm>,
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Select a brand"
          onChange={(value) => setSelectedBrand(value)}
          defaultValue={brand.currentBrand}
        >
          {brands.map((brand) => (
            <Select.Option key={brand.brandId} value={brand.brandId}>
              {brand.brandName}
            </Select.Option>
          ))}
        </Select>
        <DatePicker
          style={{ width: "100%", marginTop: "10px" }}
          onChange={(date, dateString) => setSelectedDate(dateString)}
        />
      </Modal>
    </>
  );
}

export default BrandCard;
