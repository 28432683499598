import React, { useEffect } from "react";
import { Form, Input, Radio, Space, Select, message } from "antd";
import { useSelector } from "react-redux";

import useRoleCheck from "utilities/hooks/roleCheck";

const { Option } = Select;

const InsurerEdit = ({ value, onChange }) => {
  // Fallback for null value
  const safeValue = value || {
    insurerType: "",
    insurerGroup: "",
    insurerSimpleType: "",
  };

  // Function to handle changes in the input fields
  const handleInputChange = (field) => (e) => {
    const newValue = e.target ? e.target.value : e; // e will be value for Select, event object for Input
    onChange({ ...safeValue, [field]: newValue });
  };

  return (
    <>
      <Form.Item
        label="Insurer Type"
        rules={[{ required: true, message: "Insurer type is required" }]}
      >
        <Select
          value={safeValue.insurerType}
          onChange={handleInputChange("insurerType")}
        >
          <Option value="External Financing">External Financing</Option>
          <Option value="Industrial">Industrial</Option>
          <Option value="Private Insurance">Private Insurance</Option>
          <Option value="Public Insurance">Public Insurance</Option>
          <Option value="Subsidized">Subsidized</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Insurer Group">
        <Input
          value={safeValue.insurerGroup}
          onChange={handleInputChange("insurerGroup")}
        />
      </Form.Item>
      <Form.Item label="Insurer Simple Type">
        <Input
          value={safeValue.insurerSimpleType}
          onChange={handleInputChange("insurerSimpleType")}
        />
      </Form.Item>
    </>
  );
};

const AddressEdit = ({ value, onChange }) => {
  if (!value) {
    value = {
      streetNo: "",
      streetName: "",
      unitNo: "",
      city: "",
      province: "",
      postalCode: "",
    };
  }
  // Function to handle changes in the input fields
  const handleInputChange = (field) => (e) => {
    onChange({ ...value, [field]: e.target.value });
  };

  return (
    <>
      <Space size="compact">
        <Input
          placeholder="Street No."
          value={value.streetNo}
          onChange={handleInputChange("streetNo")}
        />
        <Input
          placeholder="Street Name"
          value={value.streetName}
          onChange={handleInputChange("streetName")}
        />
        <Input
          placeholder="Unit No."
          value={value.unitNo}
          onChange={handleInputChange("unitNo")}
        />
      </Space>
      <Space size="compact">
        <Input
          placeholder="City"
          value={value.city}
          onChange={handleInputChange("city")}
        />
        <Input
          placeholder="Province"
          value={value.province}
          onChange={handleInputChange("province")}
        />
        <Input
          placeholder="Postal Code"
          value={value.postalCode}
          onChange={handleInputChange("postalCode")}
        />
      </Space>
    </>
  );
};

const VendorEdit = ({ vendor = {}, form, onSubmit }) => {
  //a form to edit the vendor with optional default vendor

  const { hasPermission } = useRoleCheck();
  const categoryOptions = useSelector(
    (state) => state.credentialCategories.data
  );

  const filteredCategories = categoryOptions.filter((cat) => {
    return (
      (hasPermission(["IT.Write"], [], null, false) &&
        cat.categoryName === "IT") ||
      (hasPermission(["Operations.Write"], [], null, false) &&
        cat.categoryName === "Insurer") ||
      (hasPermission(
        ["Marketing.Write", "Operations.Write", "Sales.RM", "Admin.Basic"],
        [],
        null,
        false
      ) &&
        cat.categoryName === "Other")
    );
  });

  const selectedCategoryName = categoryOptions.find(
    (category) => category.categoryId === form.getFieldValue("categoryId")
  )?.categoryName;

  useEffect(() => {
    if (Object.keys(vendor).length > 0) {
      form.setFieldsValue({
        vendorName: vendor.vendorName,
        categoryId: vendor.categoryId,
        address: vendor.address,
        phone: vendor.phone,
        fax: vendor.fax,
        email: vendor.email,
        portalUrl: vendor.portalUrl,
        contact: vendor.contact,
        insurerDetails: vendor.insurerDetails,
      });
    }
  }, [vendor, form]);

  return (
    <Form
      requiredMark={false}
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFinishFailed={(error) => {
        message.error(
          "There is an error in the form. Please correct before submitting."
        );
      }}
    >
      <Form.Item
        label="Vendor Name"
        name="vendorName"
        rules={[
          {
            required: true,
            message: "This field cannot be blank or only whitespace",
            whitespace: true,
          },
        ]}
        validateTrigger="onSubmit"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Address" name="address">
        <AddressEdit />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        validateFirst
        validatedeounce={500}
        rules={[
          {
            pattern: /^\d{10}$/,
            message: "Please enter a valid phone number",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Fax"
        name="fax"
        validateFirst
        validatedeounce={500}
        rules={[
          {
            pattern: /^\d{10}$/,
            message: "Please enter a valid fax number",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        validateFirst
        validatedeounce={500}
        rules={[
          {
            type: "email",
            message: "Please enter a valid email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Portal URL"
        name="portalUrl"
        validateFirst
        validatedeounce={500}
        rules={[
          {
            type: "url",
            message: "Please enter a valid URL",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Contact" name="contact">
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name="categoryId"
        rules={[
          {
            required: true,
            message: "Category is required",
          },
        ]}
      >
        <Radio.Group>
          {filteredCategories.map((category) => (
            <Radio.Button key={category.categoryId} value={category.categoryId}>
              {category.categoryName}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.categoryId !== currentValues.categoryId
        }
      >
        {({ getFieldValue }) => {
          const currentCategoryName = filteredCategories.find(
            (category) => category.categoryId === getFieldValue("categoryId")
          )?.categoryName;

          return currentCategoryName === "Insurer" ? (
            <Form.Item label="Insurer Details" name="insurerDetails">
              <InsurerEdit />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>
    </Form>
  );
};

export default VendorEdit;
