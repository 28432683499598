import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, TimePicker, Typography } from "antd";
import dayjs from "dayjs";

const { Text } = Typography;

const EmployeeHoursControl = ({
  value,
  onChange,
  clinicHours,
  compact = false,
}) => {
  const formatClinicHours = (clinicHours) =>
    clinicHours.map((hour) => ({
      dayOfWeek: hour.dayOfWeek.toString(),
      startTime: hour.openTime,
      endTime: hour.closeTime,
      isOpen: hour.isOpen,
    }));

  const addMissingDays = (hours) => {
    const days = new Set(hours.map((hour) => hour.dayOfWeek));
    for (let i = 1; i <= 7; i++) {
      if (!days.has(i.toString())) {
        hours.push({
          dayOfWeek: i.toString(),
          startTime: null,
          endTime: null,
          isOpen: false,
        });
      }
    }
    return hours;
  };

  const initialHours = useMemo(() => {
    return value
      ? addMissingDays(value.map((item) => ({ ...item, isOpen: true })))
      : clinicHours
      ? formatClinicHours(clinicHours)
      : [];
  }, [value, clinicHours]);

  const [hours, setHours] = useState(initialHours);

  //component which reorders hours by day of week
  const orderedHours = useMemo(() => {
    return hours.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
  }, [hours]);

  useEffect(() => {
    setHours(initialHours);
  }, [setHours, clinicHours, initialHours]);

  const updateHours = (newHours) => {
    //remove any instances where isOpen is false before setting value
    const newHoursFiltered = newHours.filter((hour) => hour.isOpen);
    //remove the isOpen property from the hours before setting value
    const newHoursCleaned = newHoursFiltered.map((hour) => {
      const { isOpen, ...rest } = hour;
      return rest;
    });
    onChange && onChange(newHoursCleaned);
  };

  const handleCheckboxChange = (index) => {
    const newHours = [...hours];
    newHours[index].isOpen = !newHours[index].isOpen;
    setHours(newHours);

    updateHours(newHours);
  };

  const handleTimeChange = (times, index) => {
    const newHours = [...hours];
    newHours[index].startTime = times[0].format("HH:mm");
    newHours[index].endTime = times[1].format("HH:mm");
    setHours(newHours);
    updateHours(newHours);
  };

  return (
    <div>
      {orderedHours.map((hour, index) => (
        <div key={index} className="flex mb-4">
          <Text className="w-28 pr-4 text-right">
            {dayjs().weekday(hour.dayOfWeek).format("dddd")}
          </Text>
          <Checkbox
            className="w-16"
            checked={hour.isOpen}
            onChange={() => handleCheckboxChange(index)}
          />
          {hour.isOpen ? (
            <TimePicker.RangePicker
              format="h:mm a"
              value={[
                hour.startTime ? dayjs(hour.startTime, "HH:mm") : null,
                hour.endTime ? dayjs(hour.endTime, "HH:mm") : null,
              ]}
              allowClear={false}
              showNow={false}
              minuteStep={15}
              use12Hours={true}
              placeholder={["Start Time", "End Time"]}
              onChange={(times) => handleTimeChange(times, index)}
              order={true}
              className="flex-grow"
            />
          ) : (
            <span className="flex-grow text-center">Not Working</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default EmployeeHoursControl;
