import React from "react";
import { Modal, Button, Typography } from "antd";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import axiosInstance from "utilities/axios";

const { Text } = Typography;
const PrintModal = ({ isVisible, onClose, searchParams }) => {
  const handlePrint = (type) => {
    const params = {
      type,
      search: searchParams.search,
      region: searchParams.region,
      division: searchParams.division,
      status: searchParams.status,
    };

    axiosInstance
      .get("/clinics/print", { params, responseType: "blob" }) // Ensure server sends a blob
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        //get file name from response headers
        console.log(response.headers);
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Clean up
        window.URL.revokeObjectURL(url); // Free up resources
      })
      .catch((error) => {
        console.error("Error printing:", error);
      });
  };

  return (
    <Modal
      title="Print Options"
      open={isVisible}
      width={400}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <Button
          icon={<FileExcelOutlined />}
          type="primary"
          onClick={() => handlePrint("excel")}
          style={{ height: "50px" }} // Increase button height
        >
          Print to Excel
        </Button>
        <Button
          icon={<FilePdfOutlined />}
          type="primary"
          onClick={() => handlePrint("pdf")}
          style={{ height: "50px" }} // Increase button height
        >
          Print to PDF
        </Button>
      </div>
      {/* add disclaimer at the bottom in italics*/}
      <Text type="secondary" style={{ fontStyle: "italic" }}>
        *The print content will match your current search and filter criteria
      </Text>
    </Modal>
  );
};

export default PrintModal;
