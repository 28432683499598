// src/redux/slices/selectedClinicSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

// Async thunk for fetching clinic data
export const fetchEmployeeData = createAsyncThunk(
  "selectedEmployee/fetchEmployeeData",
  (initials, thunkAPI) => {
    if (!initials) {
      const state = thunkAPI.getState();
      initials = state.selectedClinic.clinicData?.clinicNo;
      if (!initials) {
        return thunkAPI.rejectWithValue(
          "No initials provided and none found in state."
        );
      }
    }

    return axiosInstance
      .get(`/employees/${initials}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Construct an error object with message and code
        let errorMessage = "An error occurred";
        let errorCode = "Unknown";

        if (error.response) {
          // Axios error response structure
          errorMessage = error.response.data.message || error.message;
          errorCode = error.response.status;
        } else if (error.request) {
          // Request was made but no response received
          errorMessage = "No response received";
        } else {
          // Error setting up the request
          errorMessage = error.message;
        }

        return thunkAPI.rejectWithValue({
          message: errorMessage,
          code: errorCode,
        });
      });
  }
);

const selectedEmployeeSlice = createSlice({
  name: "selectedEmployee",
  initialState: {
    employeeData: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchEmployeeData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeData = action.payload;
        state.error = null;
      })
      .addCase(fetchEmployeeData.rejected, (state, action) => {
        state.status = "failed";

        state.error = {
          message:
            action.payload?.message ||
            action.error.message ||
            "An error occurred",
          code: action.payload?.code || "Unknown", // Use status code from response or a default
        };
        console.log(state.error);
      });
  },
});

export default selectedEmployeeSlice.reducer;
