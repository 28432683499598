import React, { useEffect, useState } from "react";
import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utilities/axios";

function DivisionCard({ division }) {
  const { Meta } = Card;
  const navigate = useNavigate();
  const [dsdPhotoUrl, setDSDPhotoUrl] = useState(null);
  useEffect(() => {
    if (division && division.dsd) {
      axiosInstance
        .get(`employees/${division.dsd.initials}/photo`, {
          responseType: "blob",
        })
        .then((photoResponse) => {
          if (photoResponse.status !== 204) {
            const photoUrl = URL.createObjectURL(photoResponse.data);
            setDSDPhotoUrl(photoUrl);
          }
        });
    }
  }, [division]);

  return (
    <>
      {division && (
        <Card
          size={"small"}
          className="rounded-lg shadow-lg"
          title={
            <div
            //   onClick={
            //   () => navigate(`/divisions/${division.code}`)
            // }
            >
              <small>Division</small>
              <h2 className="my-1">{division.description}</h2>
            </div>
          }
          style={{
            minWidth: 350,
            width: "100%",
          }}
          bodyStyle={{ padding: 0 }}
        >
          <Card
            onClick={() => navigate(`/employees/${division.dsd.initials}`)}
            bordered={false}
            size={"small"}
            className="rounded-t-none"
            bodyStyle={{
              width: "300px",
              padding: "2px",
            }}
          >
            <Meta
              avatar={
                <Avatar
                  size={72}
                  src={dsdPhotoUrl}
                  icon={!dsdPhotoUrl && <UserOutlined />}
                  alt={division.dsd.name}
                />
              }
              title={division.dsd.name}
              description={
                //Title on one line then email on the next
                <div className="flex flex-col space-y-1">
                  <span>{"Divisional Sales Director"}</span>
                  <small>{division.dsd.email}</small>
                </div>
              }
              style={{
                margin: "0px",
                padding: "0px",
                lineHeight: "1",
              }}
            ></Meta>
          </Card>
        </Card>
      )}
    </>
  );
}

export default DivisionCard;
