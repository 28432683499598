import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Input,
  DatePicker,
  Flex,
  Button,
  Form,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import EmployeeHoursControl from "components/EmployeeHoursControl";
import { DeleteOutlined } from "@ant-design/icons";

import axiosInstance from "utilities/axios";

const { Item } = Form;
const { Text } = Typography;
const EditClinicEntry = ({ clinic, field, remove }) => {
  const { clinicNo, clinicName } = clinic;
  const { key, name, ...restField } = field;

  const [clinicHours, setClinicHours] = useState([]);

  useEffect(() => {
    if (clinic) {
      axiosInstance.get(`/clinics/${clinic.clinicNo}/hours`).then((res) => {
        setClinicHours(res.data);
      });
    }
  }, [clinic]);

  return (
    <>
      <Row>
        <Col span={8}>{`${clinicNo} - ${clinicName} `}</Col>
        <Col span={5}>
          <Item
            {...restField}
            name={[name, "isPrimary"]}
            valuePropName="checked"
          >
            <Checkbox disabled>Primary Clinic</Checkbox>
          </Item>
        </Col>
        <Col span={5}>
          <Item name={[name, "isPermanent"]} valuePropName="checked">
            <Checkbox>Permanent</Checkbox>
          </Item>
        </Col>
        <Col span={5}>
          <Item name={[name, "isFullTime"]} valuePropName="checked">
            <Checkbox>Full Time</Checkbox>
          </Item>
        </Col>
        <Col span={1}>
          <Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.clinics[name] !== currentValues.clinics[name]
            }
          >
            {({ getFieldValue }) => {
              const isPrimary = getFieldValue(["clinics", name, "isPrimary"]);
              return (
                <Tooltip
                  title={isPrimary ? "Cannot delete primary clinics" : ""}
                >
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => remove()}
                    disabled={isPrimary}
                  />
                </Tooltip>
              );
            }}
          </Item>
        </Col>
      </Row>
      <Row>
        {/* add a "notes" item which spans the entire row below and has a short height*/}
        <Col span={24}>
          <Item
            name={[name, "notes"]}
            label="Notes"
            layout="horizontal"
            labelCol={{ span: 2 }}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row gutter="sm">
        <Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues.clinics[name] !== currentValues.clinics[name];
          }}
        >
          {({ getFieldValue }) => {
            const isFullTime = getFieldValue(["clinics", name, "isFullTime"]);
            const isPermanent = getFieldValue(["clinics", name, "isPermanent"]);

            const hoursItem = (
              <Col span={!isPermanent ? 16 : 24}>
                <Flex vertical justify="center" align="center">
                  <Text strong>Part-Time Hours</Text>
                  <Item name={[name, "hours"]}>
                    <EmployeeHoursControl clinicHours={clinicHours} />
                  </Item>
                </Flex>
              </Col>
            );
            const tempItem = (
              <Col span={!isFullTime ? 8 : 24}>
                <Flex vertical justify="center" align="center">
                  <Text strong>Temporary Assignment</Text>
                  <Item name={[name, "startDate"]} label="Start Date">
                    <DatePicker />
                  </Item>
                  <Item name={[name, "endDate"]} label="End Date">
                    <DatePicker />
                  </Item>
                </Flex>
              </Col>
            );
            return (
              <>
                {!isPermanent && tempItem}
                {!isFullTime && hoursItem}
              </>
            );
          }}
        </Item>
      </Row>
    </>
  );
};

export default EditClinicEntry;
