import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import { useSelector } from "react-redux";
import FieldItem from "./FieldItem"; // Assuming FieldItem is in the same directory

const FieldsFormList = ({ typeId }) => {
  const types = useSelector((state) => state.credentialTypes.data);
  const [defaultFields, setDefaultFields] = useState([]);

  useEffect(() => {
    if (typeId) {
      const typeData = types.find((type) => type.id === typeId);
      if (typeData) {
        setDefaultFields(typeData.fields);
      }
    }
  }, [typeId, types]);

  return (
    <div className="flex flex-col max-h-[60vh] flex-grow overflow-y-auto">
      <Form.List name="fields">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <FieldItem key={field.key} field={field} remove={remove} />
            ))}
            <Button
              type="primary"
              onClick={() =>
                add({
                  fieldDescription: "",
                  fieldId: null,
                  fieldName: "",
                  fieldValue: "",
                  isPrimary: false,
                  fieldType: "text",
                  regexValidation: "",
                })
              }
            >
              Add Field
            </Button>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default FieldsFormList;
