import React, { useEffect, useState } from "react";
import { Form, Input, Upload, message } from "antd";
import { useSelector } from "react-redux";

const BrandEdit = ({ brand = {}, form, onSubmit }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (Object.keys(brand).length > 0) {
      form.setFieldsValue({
        brandName: brand.brandName,
        brandDescription: brand.brandDescription,
      });

      // Convert brand.url to the format expected by Upload component
      if (brand.url) {
        setFileList([
          {
            uid: "-1",
            name: "Current Logo", // You might want to extract the actual name
            status: "done",
            url: brand.url,
          },
        ]);
      }
    }
  }, [brand, form]);

  const handleFileChange = ({ file, fileList: newFileList }) => {
    const latestFileList = file ? [file] : [];
    setFileList(latestFileList);
  };

  const uploadText =
    fileList.length > 0
      ? "Drag and drop to replace logo"
      : "Drag & drop logo or click to upload";
  return (
    <Form
      requiredMark={false}
      form={form}
      layout="vertical"
      onFinish={(values) => {
        return onSubmit({
          brandDescription: values.brandDescription,
          brandName: values.brandName,
          brandLogo: fileList[0],
        });
      }}
      onFinishFailed={(error) => {
        message.error(
          "There is an error in the form. Please correct before submitting."
        );
      }}
    >
      <Form.Item
        label="Brand Name"
        name="brandName"
        rules={[
          {
            required: true,
            message: "This field cannot be blank or only whitespace",
            whitespace: true,
          },
        ]}
        validateTrigger="onSubmit"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="brandDescription">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Upload Logo" name="brandLogo">
        <Upload.Dragger
          multiple={false}
          fileList={fileList}
          beforeUpload={() => false}
          onChange={handleFileChange}
        >
          <p className="ant-upload-text">{uploadText}</p>
        </Upload.Dragger>
      </Form.Item>
    </Form>
  );
};

export default BrandEdit;
