// ClinicalInfoCard.js
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  notification,
  InputNumber,
  Radio,
  Typography,
  Flex,
} from "antd";
import axiosInstance from "utilities/axios";
import useRoleCheck from "utilities/hooks/roleCheck";
import { EditOutlined } from "@ant-design/icons";

const { Text } = Typography;
const ClinicalInfoCard = ({ clinicInfo, clinicNo, onChange }) => {
  const { hasPermission } = useRoleCheck();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  useEffect(() => {
    setModalInfo(clinicInfo);
  }, [clinicInfo]);

  const updateClinicalInfo = () => {
    axiosInstance
      .patch(`/clinics/${clinicNo}`, { clinicalInformation: modalInfo })
      .then((response) => {
        notification.success({
          message: "Success!",
          description: "Clinical information updated successfully.",
        });
        setIsModalVisible(false);
        onChange();
      })
      .catch((error) => {
        notification.error({
          message: "Error!",
          description: "Clinical information update failed.",
        });
      });
  };

  if (!clinicInfo) {
    return null;
  }
  return (
    <>
      <Card
        title="Clinical Information"
        style={{
          minWidth: 280,
          width: "100%",
          minHeight: 150,
          height: "fit-content",
        }}
        bodyStyle={{ marginLeft: 20, padding: 20 }}
        extra={
          hasPermission(["Admin.Basic"], ["Sales.RM"], clinicNo, false) && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          )
        }
        className="mt-4"
      >
        <Flex vertical gap="small">
          <Text type="secondary">
            Minimum Testing Age: {clinicInfo.testingChildren}
          </Text>
          <Text type="secondary">
            Number of Booths: {clinicInfo.numberOfBooths}
          </Text>
          {/* include wheelchair accessible, parking type (free or paid) and ent status (yes or no) */}
          <Text type="secondary">
            Wheelchair Accessible: {clinicInfo.wheelchairStatus}
          </Text>
          <Text type="secondary">Parking Type: {clinicInfo.parkingType}</Text>
        </Flex>
      </Card>
      <Modal
        title="Edit Clinical Information"
        open={isModalVisible}
        onOk={updateClinicalInfo}
        okText="Save"
        onCancel={() => {
          setIsModalVisible(false);
          setModalInfo(clinicInfo);
        }}
      >
        <div className="flex flex-col">
          <label className="text-sm text-gray-500">Minimum Testing Age</label>
          <InputNumber
            className="w-full"
            min={0}
            value={modalInfo?.testingChildren}
            onChange={(value) =>
              setModalInfo({ ...modalInfo, testingChildren: value })
            }
          />
        </div>
        <div className="flex flex-col mt-4">
          <label className="text-sm text-gray-500">Number of Booths</label>
          <InputNumber
            className="w-full"
            min={0}
            value={modalInfo?.numberOfBooths}
            onChange={(value) =>
              setModalInfo({ ...modalInfo, numberOfBooths: value })
            }
          />
        </div>
        <div className="flex flex-col mt-4">
          <label className="text-sm text-gray-500">Wheelchair Accessible</label>
          <Radio.Group
            className="w-full"
            value={modalInfo?.wheelchairStatus}
            onChange={(e) =>
              setModalInfo({
                ...modalInfo,
                wheelchairStatus: e.target.value,
              })
            }
          >
            <Radio.Button value={"Yes"}>Yes</Radio.Button>
            <Radio.Button value={"No"}>No</Radio.Button>
          </Radio.Group>
        </div>
        {/* parking type is either free, both, none or paid */}
        <div className="flex flex-col mt-4">
          <label className="text-sm text-gray-500">Parking Type</label>
          <Radio.Group
            className="w-full"
            value={modalInfo?.parkingType}
            onChange={(e) =>
              setModalInfo({ ...modalInfo, parkingType: e.target.value })
            }
          >
            <Radio.Button value={"Free"}>Free</Radio.Button>
            <Radio.Button value={"Paid"}>Paid</Radio.Button>
            <Radio.Button value={"Both"}>Both Free and Paid</Radio.Button>
            <Radio.Button value={"None"}>None</Radio.Button>
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
};

export default ClinicalInfoCard;
