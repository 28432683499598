import { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Grid,
  Affix,
  Modal,
  Button,
  Typography,
  Watermark,
  Spin,
} from "antd";
import {
  UserOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  ApartmentOutlined,
  SettingOutlined,
  TrademarkOutlined,
  LogoutOutlined,
  HomeOutlined,
  TeamOutlined,
  ShopOutlined,
  BugOutlined,
  RiseOutlined,
  BarChartOutlined,
  SmileOutlined,
  CalendarOutlined,
  OneToOneOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { useTheme } from "../useTheme";
import { useSelector } from "react-redux";
import useRoleCheck from "utilities/hooks/roleCheck";
import axiosInstance from "utilities/axios";
import RoleDataRefresher from "utilities/roleRefresher";
import RolesAndClinicsComponent from "../pages/RoleChange";

const { Title } = Typography;
const { Header, Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const AppLayout = () => {
  const { hasPermission } = useRoleCheck();
  const rolesStatus = useSelector((state) => state.roles.status);

  const showVendors = hasPermission([
    "Operations",
    "IT",
    "Marketing",
    "Sales.RM",
    "Admin.Basic",
  ]);

  const showBrands = hasPermission(["Marketing", "Admin.Basic"]);
  const showEquipment = false; //hasPermission(["Audiology", "IT", "Sales.RM"]);

  const { isDarkMode } = useTheme();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const headerBackgroundColor = isDarkMode ? "#49443D" : "#0F2B54"; //: '#005DA9'; // Dark Blue / Warm Wood
  const textColor = "#fff";
  const location = useLocation();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    // Collapse the sider when the screen size is smaller than 'md'
    if (screens.lg) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [screens]);

  const handleLogout = () => {
    axiosInstance
      .get("/logout") // replace '/logout' with your logout route
      .then((response) => {
        navigate("/login"); // redirect to login page
      })
      .catch((error) => {
        console.error("Error during logout", error);
      });
  };
  useEffect(() => {
    document.title = "Clinic Central";
  }, []);
  const baseLocation = "/" + (location.pathname.split("/")[1] || "");
  const userMenuClick = ({ key }) => {
    if (key === "3") {
      handleLogout();
    } else if (key === "2") {
      navigate("/settings");
    } else if (key === "1") {
      window.location.href =
        "https://apps.powerapps.com/play/55b2e78f-20d5-4408-bf41-b8a831b3caf9?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8";
      //navigate("/profile");
    }
  };
  const userMenu = [
    {
      key: "1",
      label: "Profile",
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: "(Disabled) Settings",
      icon: <SettingOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: "Logout",
      icon: <LogoutOutlined />,
    },
  ];
  const [openKeys, setOpenKeys] = useState([
    "other-apps",
    // "analytics",
    // "admin",
  ]);

  const handleMenuClick = ({ key, domEvent }) => {
    domEvent.preventDefault();
    if (domEvent.button === 1 || domEvent.ctrlKey || domEvent.metaKey) {
      // Middle click or Ctrl/Command + click
      window.open(domEvent.currentTarget.href, "_blank");
    } else {
      switch (key) {
        case "morning-huddle":
          window.location.href =
            "https://apps.powerapps.com/play/e/default-9bf8c7a8-e008-49a7-9e43-ab76976c4bf8/a/ace6d3ad-82b1-4227-80d1-b55f8664acd1?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8";
          break;
        case "product-comparison":
          window.location.href =
            "https://apps.powerapps.com/play/e/default-9bf8c7a8-e008-49a7-9e43-ab76976c4bf8/a/bf805b9b-f9b8-4f2b-a7ba-ae5c8a6a6fe2";
          break;
        case "po-tracker":
          window.location.href =
            "https://apps.powerapps.com/play/ef782631-34eb-400a-95ed-c852df20094d?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8";
          break;
        case "equipment-tracker":
          window.location.href =
            "https://apps.powerapps.com/play/e/default-9bf8c7a8-e008-49a7-9e43-ab76976c4bf8/a/29b138ff-dbfd-44d8-9f7d-6138b7450efa?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8&hint=f05e346b-15b7-4110-8a03-306a6c394345";
          break;
        case "sales-funnel":
          window.location.href =
            "https://app.powerbi.com/groups/me/apps/c0b213f0-da90-4dd7-9d02-34248f34d10f/reports/7837128e-2075-4423-824b-00412f5a6735/ReportSectioncd43de4752a20d5b5dc0?experience=power-bi";
          break;
        case "client-excellence":
          window.location.href =
            "https://app.powerbi.com/Redirect?action=OpenReport&appId=c0b213f0-da90-4dd7-9d02-34248f34d10f&reportObjectId=7a301718-6491-4e69-bbe1-24f3be4a412e&ctid=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8&pbi_source=appShareLink&portalSessionId=536f452f-1cff-43b7-b9da-d8f1c4c01853";
          break;
        default:
          navigate(key);
      }
    }
  };

  if (rolesStatus === "loading") {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <RoleDataRefresher />

      <Modal
        title="Manage Roles & Clinics"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="20%"
      >
        <RolesAndClinicsComponent />
      </Modal>

      <Layout className="h-screen flex flex-col">
        <Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "64px",
            backgroundColor: headerBackgroundColor,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/")}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/HLBubbleWhite.svg"
                alt="logo"
                style={{ maxHeight: "60px", marginRight: "1rem" }}
              />
            </div>
          </div>
          <div>
            <Title
              level={screens.lg ? 1 : screens.md ? 2 : screens.sm ? 3 : 4}
              style={{ color: textColor, margin: "0" }}
            >
              Clinic Central
              {process.env.REACT_APP_ENV === "Staging" && " - TEST"}
              {process.env.REACT_APP_ENV === "Dev" && " - DEV"}
            </Title>
          </div>
          <div>
            <Dropdown
              trigger={["click"]}
              menu={{ items: userMenu, onClick: userMenuClick }}
            >
              <Avatar
                style={{ backgroundColor: "#0066b0" }}
                icon={<UserOutlined />}
              />
            </Dropdown>
          </div>
        </Header>
        <Layout hasSider>
          <Sider
            width={250}
            collapsible={!screens.lg}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            style={{ zIndex: 1 }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["/"]}
              selectedKeys={[baseLocation]}
              style={{ height: "100%", borderRight: 0 }}
              openKeys={openKeys}
              onOpenChange={setOpenKeys}
              items={[
                {
                  key: "/clinics",
                  label: <Link to="/clinics">Clinic Directory</Link>,
                  icon: <HomeOutlined />,
                },
                {
                  key: "/employees",
                  label: <Link to="/employees">Employees</Link>,
                  icon: <TeamOutlined />,
                },
                {
                  key: "/regions",
                  label: <Link to="/regions">Regions</Link>,
                  icon: <EnvironmentOutlined />,
                },
                {
                  key: "/divisions",
                  label: <Link to="/divisions">Divisions</Link>,
                  icon: <ApartmentOutlined />,
                },
                {
                  key: "other-apps",
                  label: "Other Apps",
                  icon: <AppstoreOutlined />,
                  children: [
                    {
                      key: "morning-huddle",
                      label: (
                        <Typography.Link
                          style={{ whiteSpace: "normal" }}
                          href="https://apps.powerapps.com/play/e/default-9bf8c7a8-e008-49a7-9e43-ab76976c4bf8/a/ace6d3ad-82b1-4227-80d1-b55f8664acd1?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8"
                        >
                          Morning Huddle
                        </Typography.Link>
                      ),
                      icon: <CalendarOutlined />,
                    },
                    {
                      key: "product-comparison",
                      label: (
                        <Typography.Link href="https://apps.powerapps.com/play/e/default-9bf8c7a8-e008-49a7-9e43-ab76976c4bf8/a/bf805b9b-f9b8-4f2b-a7ba-ae5c8a6a6fe2">
                          Product Comparison
                        </Typography.Link>
                      ),
                      icon: <OneToOneOutlined />,
                    },
                    {
                      key: "po-tracker",
                      label: (
                        <Typography.Link href="https://apps.powerapps.com/play/ef782631-34eb-400a-95ed-c852df20094d?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8">
                          PO Tracker
                        </Typography.Link>
                      ),
                      icon: <ContainerOutlined />,
                    },
                    {
                      key: "equipment-tracker",
                      label: (
                        <Typography.Link href="https://apps.powerapps.com/play/e/default-9bf8c7a8-e008-49a7-9e43-ab76976c4bf8/a/29b138ff-dbfd-44d8-9f7d-6138b7450efa?tenantId=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8&hint=f05e346b-15b7-4110-8a03-306a6c394345">
                          Equipment Tracker
                        </Typography.Link>
                      ),
                      icon: <ContainerOutlined />,
                    },
                  ],
                },
                {
                  key: "analytics",
                  label: "Analytics",
                  icon: <BarChartOutlined />,
                  children: [
                    {
                      key: "sales-funnel",
                      label: (
                        <Typography.Link href="https://app.powerbi.com/groups/me/apps/c0b213f0-da90-4dd7-9d02-34248f34d10f/reports/7837128e-2075-4423-824b-00412f5a6735/ReportSectioncd43de4752a20d5b5dc0?experience=power-bi">
                          Sales Funnel
                        </Typography.Link>
                      ),

                      icon: <RiseOutlined />,
                    },
                    {
                      key: "client-excellence",
                      label: (
                        <Typography.Link href="https://app.powerbi.com/Redirect?action=OpenReport&appId=c0b213f0-da90-4dd7-9d02-34248f34d10f&reportObjectId=7a301718-6491-4e69-bbe1-24f3be4a412e&ctid=9bf8c7a8-e008-49a7-9e43-ab76976c4bf8&pbi_source=appShareTypography.Link&portalSessionId=536f452f-1cff-43b7-b9da-d8f1c4c01853">
                          Client Excellence
                        </Typography.Link>
                      ),
                      icon: <SmileOutlined />,
                    },
                  ],
                },
                ...(showVendors || showBrands || showEquipment
                  ? [
                      {
                        key: "admin",
                        label: "Admin",
                        icon: <SettingOutlined />,
                        children: [
                          ...(showVendors
                            ? [
                                {
                                  key: "/vendors",
                                  label: <Link to="/vendors">Vendors</Link>,
                                  icon: <ShopOutlined />,
                                },
                              ]
                            : []),
                          ...(showBrands
                            ? [
                                {
                                  key: "/brands",
                                  label: <Link to="/brands">Brands</Link>,
                                  icon: <TrademarkOutlined />,
                                },
                              ]
                            : []),
                          ...(showEquipment
                            ? [
                                {
                                  key: "/equipment",
                                  label: <Link to="/equipment">Equipment</Link>,
                                  icon: <ContainerOutlined />,
                                },
                              ]
                            : []),
                        ],
                      },
                    ]
                  : []),
              ]}
              onClick={handleMenuClick}
            />
          </Sider>
          <Content className="flex-grow overflow-y-auto">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      {hasPermission(["Admin.Dev"]) && (
        <Affix
          style={{ position: "absolute", bottom: 20, left: 30, zIndex: 1 }}
        >
          <Button
            type="primary"
            shape="circle"
            size="large"
            onClick={() => setIsModalVisible(true)}
          >
            <BugOutlined />
          </Button>
        </Affix>
      )}
    </>
  );
};

const DefaultLayout = () => {
  return (
    <>
      {process.env.REACT_APP_ENV === "Staging" ? (
        <Watermark
          content={["Testing Version", "Not Live Data"]}
          offset={[20, 20]}
          gap={[200, 200]}
          fontSize={100}
        >
          <AppLayout />
        </Watermark>
      ) : (
        <AppLayout />
      )}
    </>
  );
};
export default DefaultLayout;
