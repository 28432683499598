import React, { useEffect, useState } from "react";
import {
  Layout,
  Anchor,
  Form,
  Button,
  Progress,
  notification,
  Select,
  Tooltip,
  Radio,
  Input,
  Affix,
  Modal,
  Steps,
  Checkbox,
} from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import ClinicsSection from "./UserProfileClinics";
import axiosInstance from "utilities/axios";

const { Link } = Anchor;

const Profile = () => {
  const [formData, setFormData] = useState({
    id: "thhk",
    fullName: "John Doe",
    photoUrl: "https://example.com/path-to-image.jpg",
    roles: ["Corporate", "CSA", "HP"],
    airMilesNumber: 12345678,
    cellPhoneNumber: "555-555-5555",
    relocationStatus: "undecided",
    preferredLocation: null,
    biography: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    languages: [
      {
        language: "English",
        proficiency: "Advanced",
      },
      {
        language: "French",
        proficiency: "Conversational",
      },
    ],
    jobDescription: "Lorem ipsum...",
    clinics: [
      {
        clinicId: "a1b2",
        name: "Main Street Clinic",
        isPrimary: true,
      },
      {
        clinicId: "c3d4",
        name: "Downtown Clinic",
        isPrimary: false,
      },
    ],
    subjectMatterExpert: true,
    digitalSignature: "https://example.com/path-to-signature-image.jpg",
    marketingConsent: true,
    hpCredential: "Audiologist",
    uploadedCredentials: [
      {
        documentType: "Diploma",
        certificateNumber: "1234",
        province: "Ontario",
        issueDate: "2023-01-01",
        expiryDate: "2025-01-01",
      },
    ],
    hpCollege: "CASLPO",
    hpAssociationRegistrationNumber: "5678",
    certifiedForIndustrialTesting: true,
    certifiedForWaxRemoval: false,
    qualifiedAge: 18,
    comfortableAge: 18,
    authorizationNumber: "9876",
  });
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  const userId = "thhk"; // Placeholder until you have the actual id from redux
  const userRoles = ["Corporate", "CSA", "HP"]; // Placeholder list of roles

  const allLanguages = ["English", "Spanish", "French", "German"]; // List of all languages
  const [availableLanguages, setAvailableLanguages] = useState(allLanguages);
  const [languages, setLanguages] = useState([{ lang: "", proficiency: "" }]);

  const handleAddLanguage = () => {
    setLanguages([...languages, { lang: "", proficiency: "" }]);
  };

  const handleRemoveLanguage = (index, lang) => {
    const newLanguages = [...languages];
    newLanguages.splice(index, 1);
    setLanguages(newLanguages);

    // Restore the language to the availableLanguages list
    if (lang) setAvailableLanguages((prev) => [...prev, lang]);
  };

  const handleLanguageSelect = (value) => {
    setAvailableLanguages((prev) => prev.filter((lang) => lang !== value));
  };

  useEffect(() => {
    //axiosInstance
    //   .get(`/employees/${userId}/profile`)
    //   .then((response) => {
    //     setFormData(response.data);
    //     // Placeholder logic for setting progress based on form data completeness
    //     // This needs more logic based on the data's actual structure and required fields
    //     setProgress(50); // For now, just a hardcoded value
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     notification.error({
    //       message: "Error",
    //       description: "Failed to fetch profile data.",
    //     });
    //     setLoading(false);
    //   });
  }, []);

  const handleSave = () => {
    axiosInstance
      .post(`/employees/${userId}/profile`, formData)
      .then((response) => {
        if (response.status === 200) {
          notification.success({
            message: "Success",
            description: "Profile updated successfully.",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Failed to update profile.",
        });
      });
  };

  const handleCancel = () => {
    // Redirect to the previous page or some other desired behavior
  };

  return (
    <Layout.Content
      style={{
        padding: "24px",
        background: "#fff",
        marginTop: "24px",
        position: "relative",
      }}
    >
      <div style={{ position: "fixed", top: "20px", left: "20px" }}>
        <Anchor affix={true} offsetTop={20}>
          <Link href="#general" title="General" />
          {userRoles.includes("Corporate") && (
            <Link href="#corporate" title="Corporate" />
          )}
          {(userRoles.includes("CSA") || userRoles.includes("HP")) && (
            <Link href="#clinics" title="Clinics" />
          )}
          {userRoles.includes("CSA") && <Link href="#csa" title="CSA" />}
          {userRoles.includes("HP") && <Link href="#hp" title="HP" />}
        </Anchor>
      </div>

      <Form
        layout="vertical"
        onValuesChange={(changedValues, allValues) => {
          setFormData(allValues);
          // TODO: Update progress based on changed data, will be a more complex logic than this
          setProgress(75); // Placeholder logic
        }}
      >
        <div id="general" className="space-y-4">
          <Form.Item
            label="Air Miles Number"
            name="airMilesNumber"
            tooltip="Enter your Air Miles number."
          >
            <Input
              type="number"
              className="border rounded-md p-2"
              placeholder="Air Miles Number"
            />
          </Form.Item>

          <Form.Item
            label="Personal Cell Phone Number"
            name="cellPhoneNumber"
            tooltip="Enter your NA formatted cell phone number."
          >
            <Input
              className="border rounded-md p-2"
              placeholder="Cell Phone Number"
            />
          </Form.Item>

          <Form.Item name="relocationStatus" label="Open to Relocating">
            <Radio.Group
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
                { label: "Undecided", value: "undecided" },
              ]}
              className="space-x-4"
            />
          </Form.Item>

          {formData?.relocationStatus === "yes" && (
            <Form.Item
              label="Preferred Location for Relocation"
              name="relocationLocation"
            >
              <Input
                className="border rounded-md p-2"
                placeholder="Enter location"
              />
            </Form.Item>
          )}

          <Form.Item
            label="Biography"
            name="biography"
            tooltip="Share a brief about yourself. (Max 2000 characters)"
          >
            <Input.TextArea
              maxLength={2000}
              className="border rounded-md p-2"
              placeholder="Write your biography here..."
              autoSize={{ minRows: 4, maxRows: 6 }}
              showCount
            />
          </Form.Item>
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Languages & Proficiency</h2>
            {languages.map((langObj, index) => (
              <div key={index} className="flex items-center space-x-4">
                <Form.Item name={`language${index}`} noStyle>
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select Language"
                    onSelect={handleLanguageSelect}
                  >
                    {availableLanguages.map((lang) => (
                      <Select.Option key={lang} value={lang}>
                        {lang}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name={`proficiency${index}`} noStyle>
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select Proficiency"
                  >
                    <Select.Option value="beginner">Beginner</Select.Option>
                    <Select.Option value="intermediate">
                      Intermediate
                    </Select.Option>
                    <Select.Option value="conversational">
                      Conversational
                    </Select.Option>
                    <Select.Option value="advanced">Advanced</Select.Option>
                  </Select>
                </Form.Item>

                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => handleRemoveLanguage(index, langObj.lang)}
                  className="text-red-500 hover:text-red-600"
                />
              </div>
            ))}

            <Button type="dashed" onClick={handleAddLanguage}>
              Add Language
            </Button>
          </div>
        </div>

        {userRoles.includes("Corporate") && (
          <div id="corporate" className="mt-6">
            <Form.Item
              label={
                <span>
                  Job Description&nbsp;
                  <Tooltip title="Provide a brief description of the job.">
                    <i className="anticon anticon-question-circle-o text-gray-400"></i>
                  </Tooltip>
                </span>
              }
              name="jobDescription"
              tooltip="Enter a description for your job to allow others to know what you do at the company."
              rules={[
                {
                  max: 2000,
                  message: "Description cannot exceed 2000 characters.",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Enter job description..."
                className="rounded-md resize-none"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>
          </div>
        )}

        {(userRoles.includes("CSA") || userRoles.includes("HP")) && (
          <ClinicsSection clinics={formData?.clinics} />
        )}

        {userRoles.includes("CSA") && (
          <div id="csa" className="my-6">
            <h2 className="text-lg font-bold mb-4">CSA Information</h2>

            <Form.Item
              label={<span>Subject Matter Expert&nbsp;</span>}
              name="subjectMatterExpert"
              initialValue={formData?.subjectMatterExpert || "No"}
              tooltip="Are you a subject matter expert?"
            >
              <Radio.Group className="space-x-4">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}

        {userRoles.includes("HP") && (
          <div id="hp">{/* Placeholder for HP Information */}</div>
        )}
      </Form>

      <Affix target={() => window}>
        <Progress percent={progress} />
        <div style={{ marginTop: "10px" }}>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: "10px" }} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Affix>
    </Layout.Content>
  );
};

export default Profile;
