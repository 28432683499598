// src/redux/rootReducer.js

import { combineReducers } from "redux";
import rolesReducer from "./slices/rolesSlice";
import employeeReducer from "./slices/selectedEmployeeSlice";
import clinicsReducer from "./slices/clinicsSlice";
import selectedClinicReducer from "./slices/clinicSlice";
import credentialCategorySlice from "./slices/credentialCategorySlice";
import credentialTypeSlice from "./slices/credentialTypeSlice";
import vendorsSlice from "./slices/vendorsSlice";
import brandsSlice from "./slices/brandsSlice";

const rootReducer = combineReducers({
  employee: employeeReducer,
  clinics: clinicsReducer,
  selectedClinic: selectedClinicReducer,
  credentialCategories: credentialCategorySlice,
  credentialTypes: credentialTypeSlice,
  vendors: vendorsSlice,
  brands: brandsSlice,
  roles: rolesReducer,
});

export default rootReducer;
