import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  notification,
  Input,
  Form,
  Flex,
  Typography,
} from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  FileTextOutlined,
  EditOutlined,
} from "@ant-design/icons";
import useRoleCheck from "utilities/hooks/roleCheck";
import axiosInstance from "utilities/axios";
import CopyableIcon from "components/copyable";

const { Text, Link } = Typography;
function formatPhoneNumber(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

const ContactInfoCard = ({ contactInfo, clinicNo, onChange }) => {
  const { hasPermission } = useRoleCheck();
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    form.setFieldsValue(contactInfo);
  }, [contactInfo, form]);

  const updateContactInfo = (contactInfo) => {
    axiosInstance
      .patch(`/clinics/${clinicNo}`, {
        contact: contactInfo,
      })
      .then((res) => {
        notification.success({
          message: "Contact Information Updated",
        });
        onChange();
        setIsModalVisible(false);
      })
      .catch((err) => {
        notification.error({
          message: "Error Updating Contact Information",
          description: err.response.data.message,
        });
      });
  };

  if (!contactInfo) {
    return null;
  }
  return (
    <>
      <Card
        title="Contact Information"
        style={{
          minWidth: 200,
          height: "fit-content",
          width: "100%",
        }}
        bodyStyle={{ marginLeft: 20, padding: 20 }}
        extra={
          hasPermission(["Admin.Basic"], ["Sales.RM"], clinicNo, false) && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          )
        }
      >
        <Flex
          vertical
          gap="small"
          // flex={{ flex-direction: "column" }}
        >
          {contactInfo.phone && (
            <Text
              type="secondary"
              copyable={{
                icon: <CopyableIcon textTheme="secondary" />,
                text: contactInfo.phone,
              }}
            >
              <PhoneOutlined /> Phone: {formatPhoneNumber(contactInfo.phone)}
            </Text>
          )}
          {contactInfo.email && (
            <Text
              type="secondary"
              copyable={{
                icon: <CopyableIcon textTheme="secondary" />,
                text: contactInfo.email,
              }}
            >
              <MailOutlined /> Email: {contactInfo.email}
            </Text>
          )}
          {contactInfo.fax && (
            <Text
              type="secondary"
              copyable={{
                icon: <CopyableIcon textTheme="secondary" />,
                text: contactInfo.fax,
              }}
            >
              <FileTextOutlined /> Fax: {formatPhoneNumber(contactInfo.fax)}
            </Text>
          )}
          {contactInfo.website && (
            <Link href={contactInfo.website}>
              <GlobalOutlined /> Website
            </Link>
          )}
        </Flex>
      </Card>
      <Modal
        title="Edit Contact Information"
        open={isModalVisible}
        onOk={form.submit}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          onFinish={updateContactInfo}
          layout="vertical"
          initialValues={contactInfo}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                message: "Please enter a valid phone number",
                pattern: /^\d{10}$/,
              },
            ]}
            validationtrigger="onBlur"
          >
            <Input addonBefore={<PhoneOutlined />} />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                message: "Please enter a valid email address",
                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
              },
            ]}
            validationtrigger="onBlur"
          >
            <Input addonBefore={<MailOutlined />} />
          </Form.Item>

          <Form.Item
            name="fax"
            rules={[
              {
                message: "Please enter a valid fax number",
                pattern: /^\d{10}$/,
              },
            ]}
            validationtrigger="onBlur"
          >
            <Input addonBefore={<FileTextOutlined />} />
          </Form.Item>

          <Form.Item
            name="website"
            rules={[
              {
                message: "Please enter a valid website URL",
                pattern: /^(ftp|http|https):\/\/[^ "]+$/,
              },
            ]}
            validationtrigger="onBlur"
          >
            <Input addonBefore={<GlobalOutlined />} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ContactInfoCard;
