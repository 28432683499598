import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { List, Button, Select, Layout } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import axiosInstance from "utilities/axios";
import { fetchRoles } from "utilities/redux/slices/rolesSlice"; // Import your fetchRoles action

const allRoles = [
  { value: "Marketing.Write", displayName: "Marketing Update" },
  { value: "Marketing.Read", displayName: "Marketing" },
  { value: "NAC.Read", displayName: "NAC" },
  { value: "Finance.Write", displayName: "Finance Update" },
  { value: "Finance.Read", displayName: "Finance" },
  {
    value: "Operations.Write",
    displayName: "Administrative Operations Update",
  },
  { value: "Operations.Read", displayName: "Administrative Operations" },
  { value: "IT.Write", displayName: "IT Write" },
  { value: "IT.Read", displayName: "IT Read" },
  { value: "Sales.Staff", displayName: "Clinic Staff" },
  { value: "Sales.CSA", displayName: "CSA" },
  { value: "Sales.HP", displayName: "Hearing Professional" },
  { value: "Sales.RM", displayName: "Regional Manager" },
  { value: "Sales.Exec", displayName: "Sales Executive" },
  { value: "Audiology.Admin", displayName: "Audiology Admin" },
  { value: "Audiology.Write", displayName: "Audiology Write" },
  { value: "ENT.Write", displayName: "ENT Management" },
  { value: "HR.Write", displayName: "HR Update" },
  { value: "BP.Admin", displayName: "Blueprint Administrator" },
  { value: "Admin.Basic", displayName: "Clinic Administrator" },
  { value: "Admin.Manage", displayName: "Administrator" },
  { value: "App.ReadAll", displayName: "Privileged" },
  { value: "App.Read", displayName: "Base Access" },
  { value: "Admin.Dev", displayName: "Developer" },
  { value: "Test", displayName: "Tester" },
];

const RolesAndClinicsComponent = () => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinics.data);
  const userClinics = useSelector((state) => state.roles.data.clinics);
  const userRoles = useSelector((state) => state.roles.data.roles);

  const [selectedClinics, setSelectedClinics] = useState(userClinics);
  const [selectedRoles, setSelectedRoles] = useState(userRoles);

  const handleClinicChange = (value) => {
    setSelectedClinics(value);
  };

  const isRoleSelected = (roleValue) => selectedRoles.includes(roleValue);

  const handleRoleClick = (roleValue) => {
    const updatedRoles = isRoleSelected(roleValue)
      ? selectedRoles.filter((role) => role !== roleValue)
      : [...selectedRoles, roleValue];
    setSelectedRoles(updatedRoles);
  };

  const saveChanges = () => {
    axiosInstance
      .put("/roles", { clinics: selectedClinics, roles: selectedRoles })
      .then((response) => {
        // Refetch roles data
        dispatch(fetchRoles());
      })
      .catch((error) => {
        console.error("Error updating roles:", error);
      });
  };

  return (
    <>
      <h2>Manage Roles</h2>

      <List
        dataSource={allRoles}
        renderItem={(role) => (
          <List.Item
            onClick={() => handleRoleClick(role.value)}
            style={{
              background: isRoleSelected(role.value) ? "#e6f7ff" : "#fff",
              padding: "10px", // Add padding
              borderBottom: "1px solid #f0f0f0", // Add border to bottom
              display: "flex", // Use flexbox for horizontal layout
              alignItems: "center", // Align items vertically
            }}
          >
            {role.displayName}
            <div style={{ marginLeft: "auto" }}>
              {isRoleSelected(role.value) ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )}
            </div>
          </List.Item>
        )}
        style={{ maxHeight: "300px", overflowY: "auto" }} // Add max-height and overflowY properties
      />

      <h2>Manage Clinics</h2>
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Select clinics"
        value={selectedClinics}
        onChange={handleClinicChange}
      >
        {clinics.map((clinic) => (
          <Select.Option key={clinic.clinic} value={clinic.clinic}>
            {clinic.clinic} - {clinic.clinicName}
          </Select.Option>
        ))}
      </Select>

      <Button type="primary" onClick={saveChanges}>
        Save Changes
      </Button>
    </>
  );
};

export default RolesAndClinicsComponent;
