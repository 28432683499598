import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles } from "utilities/redux/slices/rolesSlice";
import { useNavigate } from "react-router-dom"; // Import for navigation

const RoleDataRefresher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook for navigation
  const rolesData = useSelector((state) => state.roles.data);
  const rolesStatus = useSelector((state) => state.roles.status);
  const stableDispatchRef = useRef();

  stableDispatchRef.current = () => {
    if (
      (!rolesData.roles.length || !rolesData.clinics.length) &&
      rolesStatus !== "succeeded"
    ) {
      console.log("Refreshing roles");
      dispatch(fetchRoles());
    }
  };

  useEffect(() => {
    stableDispatchRef.current?.();
    // Only create the interval once
    const interval = setInterval(() => {
      // Call the current version of the stable function
      stableDispatchRef.current?.();
    }, 3600000); // 3600000 ms = 1 hour

    return () => clearInterval(interval);
  }, []); // Empty dependency array to ensure this effect runs only once

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === "Staging" &&
      !rolesData.roles.includes("Test") &&
      rolesStatus !== "idle" &&
      rolesStatus !== "loading"
    ) {
      console.log(rolesData);
      navigate("/403");
    }
  }, [navigate, rolesData, rolesStatus]);

  return null; // This component does not render anything
};

export default RoleDataRefresher;
