import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ENTCard from "./ENTCard";
import ENTAddModal from "./ENTAddModal";
import ENTEditModal from "./ENTEditModal";

import useRoleCheck from "utilities/hooks/roleCheck";

const ENTsCard = ({ clinicData }) => {
  const { hasPermission } = useRoleCheck();

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedEnt, setSelectedEnt] = useState(null);

  const openAddModal = () => {
    setIsAddModalVisible(true);
  };

  const closeAddModal = () => {
    setIsAddModalVisible(false);
  };

  const openEditModal = (ent) => {
    setSelectedEnt(ent);
    setIsEditModalVisible(true);
  };

  const closeEditModal = () => {
    setSelectedEnt(null);
    setIsEditModalVisible(false);
  };

  useEffect(() => {}, [clinicData]);

  return (
    <>
      <div className="flex flex-wrap w-full min-w-fit">
        {clinicData.ents &&
          clinicData.ents.map((ent) => (
            <ENTCard
              key={ent.id}
              ent={ent}
              editable={hasPermission(
                ["ENT.Write", "Admin.Basic"],
                ["Sales.RM"],
                clinicData.clinicNo,
                false
              )}
              onEdit={openEditModal}
            />
          ))}
        {hasPermission(
          ["ENT.Write", "Admin.Basic"],
          ["Sales.RM"],
          clinicData.clinicNo,
          false
        ) && (
          <Button
            className="w-full mt-2"
            type="dashed"
            icon={<PlusOutlined />}
            onClick={openAddModal}
          >
            Add ENT Practice
          </Button>
        )}
      </div>
      <ENTAddModal
        clinicData={clinicData}
        visible={isAddModalVisible}
        closeModal={closeAddModal}
      />
      <ENTEditModal
        visible={isEditModalVisible}
        closeModal={closeEditModal}
        ent={selectedEnt}
        clinicDetails={clinicData}
      />
    </>
  );
};

export default ENTsCard;
