import React, { useEffect, useState } from "react";
import { Input, List, Typography, Image } from "antd";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const BrandDisplay = ({ brand }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const filteredClinics = brand.clinics.filter(
    (clinic) =>
      clinic.clinicName &&
      clinic.clinicName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="brand-display">
      <Title level={4}>Brand: {brand.brandName}</Title>

      {brand.url && (
        <React.Suspense>
          <Image src={brand.url} alt={`${brand.name} logo`} />
        </React.Suspense>
      )}
      {brand.brandDescription && (
        <div className="mt-4">
          <Text>{brand.brandDescription}</Text>
        </div>
      )}

      <div className="clinics-list">
        {brand.clinicCount === 0 ? (
          <Text>No clinics are using this brand.</Text>
        ) : (
          <>
            <Title level={5}>Clinics Using Brand ({brand.clinicCount}):</Title>
            <Input
              placeholder="Search clinics"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <List
              size="small"
              bordered
              dataSource={filteredClinics}
              renderItem={(clinic) => (
                <List.Item
                  onClick={() => navigate(`/clinics/${clinic.clinicNo}`)}
                  style={{ cursor: "pointer" }}
                >
                  {clinic.clinicNo} - {clinic.clinicName}
                </List.Item>
              )}
              style={{ maxHeight: "200px", overflow: "auto" }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BrandDisplay;
