// EmployeesCard.js
import React from "react";
import EmployeeCard from "./EmployeeCard";

const EmployeesCard = ({ employees, clinic_no }) => {
  if (!employees) return null;

  return (
    <div className="flex flex-wrap w-full min-w-fit">
      {employees.map((employee) => (
        <EmployeeCard key={employee.initials} employee={employee} />
      ))}
    </div>
  );
};

export default EmployeesCard;
